import Client from "../client";
import { ProjectResource, VcsBranchResource } from "client/resources";
import { DeploymentProcessRepository } from "./deploymentProcessRepository";
import { VcsRunbookRepository } from "./vcsRunbookRepository";
import { BranchesRepository } from "./branchesRepository";

class ProjectContextRepository {
    DeploymentProcesses: DeploymentProcessRepository;
    Runbooks: VcsRunbookRepository;
    Branches: BranchesRepository;

    constructor(client: Client, project: ProjectResource, branch: VcsBranchResource | undefined) {
        this.DeploymentProcesses = new DeploymentProcessRepository(client, project, branch);
        this.Runbooks = new VcsRunbookRepository(client, project, branch);
        this.Branches = new BranchesRepository(client);
    }
}

export default ProjectContextRepository;
