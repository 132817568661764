import * as React from "react";
import { ActionEditProps, ActionPlugin, AdditionalActions } from "../Actions/pluginRegistry";
import ActionProperties from "client/resources/actionProperties";
import { ActionTemplateParameterResource, IProcessResource } from "client/resources";
import { PackageReference } from "client/resources/packageReference";
import { RunOn } from "areas/projects/components/Process/types";
import { Errors } from "../DataBaseComponent";
import { useIsStepUIFrameworkEnabled } from "areas/configuration/hooks/useIsStepUIFrameworkEnabled";
import { stepPackageRepository } from "components/StepPackageEditor/StepPackage/StepPackageRepository";
import { StepPackageEditor } from "components/StepPackageEditor/StepPackageEditor";

type ActionPropertiesEditorProps = ProjectScopedActionPropertiesEditorProps | ActionTemplateScopedActionPropertiesEditorProps;

interface ProjectScopedActionPropertiesEditorProps {
    projectId: string;
    plugin: ActionPlugin;
    properties: ActionProperties;
    packages: Array<PackageReference>;
    runOn: RunOn;
    additionalActions: AdditionalActions;
    errors: Errors | undefined;
    busy: Promise<unknown> | boolean | undefined;
    expandedByDefault: boolean;
    getFieldError(field: string): string;
    setProperties(properties: Partial<ActionProperties>, initialise?: boolean, callback?: () => void): void;
    setPackages(packages: Array<PackageReference>, initialise?: boolean): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    refreshRunOn(): void;
    getProcessResource(): Readonly<IProcessResource>;
}

interface ActionTemplateScopedActionPropertiesEditorProps {
    localNames: string[];
    plugin: ActionPlugin;
    properties: ActionProperties;
    packages: Array<PackageReference>;
    errors: Errors | undefined;
    busy: Promise<unknown> | boolean | undefined;
    expandedByDefault: boolean;
    parameters: ActionTemplateParameterResource[];
    getFieldError(field: string): string;
    setProperties(properties: Partial<ActionProperties>, initialise?: boolean, callback?: () => void): void;
    setPackages(packages: Array<PackageReference>, initialise?: boolean): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}

export function ActionPropertiesEditor(props: ActionPropertiesEditorProps) {
    // We want to move away from using the name "Actions" and move towards using the name "Steps" instead.
    // We have used the name Steps within our new step UI Framework, which begins here.
    // We continue to use the name "Actions" in our old implementation.
    // This class forms the boundary between the two,
    // hence the naming is inconsistent here and both Step and Action refer to the same thing
    const useStepUIFramework = useIsStepUIFrameworkEnabled();
    const stepPackage = stepPackageRepository.getStepPackage(props.plugin.actionType);
    if (useStepUIFramework && stepPackage) {
        return <StepPackageEditor stepPackage={stepPackage} setProperties={props.setProperties} properties={props.properties} isExpandedByDefault={props.expandedByDefault} />;
    }

    const Edit: React.ComponentType<ActionEditProps> = props.plugin.edit;

    if (isProjectScopedProps(props)) {
        return (
            <Edit
                plugin={props.plugin}
                projectId={props.projectId}
                properties={props.properties}
                packages={props.packages}
                doBusyTask={props.doBusyTask}
                busy={props.busy}
                runOn={props.runOn}
                setProperties={props.setProperties}
                setPackages={props.setPackages}
                additionalActions={props.additionalActions}
                getFieldError={props.getFieldError}
                errors={props.errors}
                expandedByDefault={props.expandedByDefault}
                refreshRunOn={props.refreshRunOn}
                getProcessResource={props.getProcessResource}
            />
        );
    }

    return (
        <Edit
            plugin={props.plugin}
            localNames={props.localNames}
            properties={props.properties}
            packages={props.packages}
            doBusyTask={props.doBusyTask}
            busy={props.busy}
            setProperties={props.setProperties}
            setPackages={props.setPackages}
            getFieldError={props.getFieldError}
            errors={props.errors}
            expandedByDefault={props.expandedByDefault}
            parameters={props.parameters}
        />
    );
}

function isProjectScopedProps(props: ActionPropertiesEditorProps): props is ProjectScopedActionPropertiesEditorProps {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return !!(props as ProjectScopedActionPropertiesEditorProps).projectId;
}
