/* eslint-disable no-restricted-imports */
import * as React from "react";
import ToolTip from "../../primitiveComponents/dataDisplay/ToolTip";
import type { CommonChipProps } from "./types";
import { StyledMaterialChip } from "./StyledMaterialChip";

interface ChipProps extends CommonChipProps {
    onClick?: (event: object) => void;
}

export const Chip: React.FC<ChipProps> = (props) => {
    return (
        <StyledMaterialChip
            backgroundColor={props.backgroundColor}
            labelColor={props.labelColor}
            noMargin={props.noMargin}
            markAsRemoved={props.markAsRemoved}
            borderColor={props.borderColor}
            fullWidth={props.fullWidth}
            icon={props.icon}
            label={<ToolTip content={<span>{props.description || props.children}</span>}>{props.children}</ToolTip>}
            onClick={props.onClick}
            tabIndex={props.tabIndex}
            variant={props.variant}
            accessibilityRole={props.accessibilityRole}
        />
    );
};

export default Chip;
