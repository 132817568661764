/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { RunRunbookActionResource, EnvironmentResource, NonVcsRunbookResource } from "client/resources";
import { EnvironmentMultiSelect } from "components/MultiSelect";
import { ExpandableFormSection, Select, Summary, Note } from "components/form";
import LookupResourceChipComponent from "components/LookupResourceChip/LookupResourceChip";
import { ChipIcon, EnvironmentChip } from "components/Chips";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout";
import ScheduledTriggerDescriptionHelper from "utils/ScheduledTriggerDescriptionHelper/ScheduledTriggerDescriptionHelper";
import { repository } from "clientInstance";

interface DeployNewReleaseActionEditorProps {
    projectSlug: string;
    runbooks: NonVcsRunbookResource[];
    environments: EnvironmentResource[];
    action: RunRunbookActionResource;
    onActionChange(action: RunRunbookActionResource): void;
}

interface DeployNewReleaseActionEditorState {
    selectedEnvironments: string[];
    selectedRunbookId: string;
    environments: EnvironmentResource[];
}

export class RunRunbookActionEditor extends React.Component<DeployNewReleaseActionEditorProps, DeployNewReleaseActionEditorState> {
    constructor(props: DeployNewReleaseActionEditorProps) {
        super(props);

        this.state = {
            environments: this.props.environments,
            ...this.initState(this.props.action),
        };
    }

    componentWillReceiveProps(newProps: DeployNewReleaseActionEditorProps) {
        const runbookIdChanged = newProps.action.RunbookId !== this.props.action.RunbookId;

        const environmentsChanged = this.checkEnvironmentIdsChanged(newProps);

        if (runbookIdChanged || environmentsChanged) {
            this.setState(this.initState(newProps.action));
        }
    }

    render() {
        const selectedRunbook = this.getSelectedRunbook(this.state.selectedRunbookId);
        return (
            <div>
                <ExpandableFormSection errorKey="RunbookToRun" title="Runbook" focusOnExpandAll summary={this.buildRunbookSummary()} help="The Runbook to run on selected environments.">
                    <Select
                        items={this.props.runbooks.map((e) => {
                            return { value: e.Id, text: e.Name };
                        })}
                        placeholder="Select a runbook"
                        allowClear={false}
                        value={this.state.selectedRunbookId}
                        onChange={this.onRunbookSelected}
                    />
                    {selectedRunbook && !selectedRunbook.PublishedRunbookSnapshotId && (
                        <Callout type={CalloutType.Warning} title="This runbook has not been published">
                            Triggers for Runbooks will only run when a published snapshot is available.
                        </Callout>
                    )}
                    {selectedRunbook && selectedRunbook.PublishedRunbookSnapshotId && (
                        <Callout type={CalloutType.Information} title="Trigger will use published snapshot">
                            Triggers will always run the published snapshot of the runbook.
                        </Callout>
                    )}
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="TargetEnvironments" title="Target environments" focusOnExpandAll summary={this.buildTargetEnvironmentSummary()} help="The environments to run the selected Runbook.">
                    <EnvironmentMultiSelect environments={this.state.environments} onChange={this.onChangeEnvironments} value={this.state.selectedEnvironments} />
                </ExpandableFormSection>
            </div>
        );
    }

    private getSelectedRunbook = (runbookId: string) => {
        const filtetedRunbooks = this.props.runbooks.filter((runbook) => runbook.Id === runbookId);
        return filtetedRunbooks.length > 0 ? filtetedRunbooks[0] : null;
    };

    private checkEnvironmentIdsChanged = (newProps: DeployNewReleaseActionEditorProps) => {
        if (newProps.action && this.props.action && newProps.action.EnvironmentIds && this.props.action.EnvironmentIds && newProps.action.EnvironmentIds.length !== this.props.action.EnvironmentIds.length) {
            return true;
        } else if (newProps.action && newProps.action.EnvironmentIds) {
            newProps.action.EnvironmentIds.forEach((environmentId) => {
                if (!this.props.action.EnvironmentIds || !this.props.action.EnvironmentIds.includes(environmentId)) {
                    return true;
                }
            });
        }

        return false;
    };

    private initState = (action: RunRunbookActionResource) => {
        return {
            selectedEnvironments: [...(action.EnvironmentIds ? action.EnvironmentIds : [])],
            selectedRunbookId: action.RunbookId,
        };
    };

    private onChangeEnvironments = (selectedEnvironments: string[]) => {
        this.setState(
            {
                selectedEnvironments,
            },
            () => this.raiseChange()
        );
    };

    private onRunbookSelected = async (selectedRunbookId: string | undefined) => {
        const runbook = this.getSelectedRunbook(selectedRunbookId!);

        if (runbook) {
            const environments = await repository.Runbooks.getRunbookEnvironments(runbook);
            this.setState(
                {
                    selectedRunbookId: selectedRunbookId!,
                    environments,
                    selectedEnvironments: [],
                },
                () => this.raiseChange()
            );
        }
    };

    private raiseChange = () => {
        this.props.onActionChange({
            ...this.props.action,
            EnvironmentIds: this.state.selectedEnvironments,
            RunbookId: this.state.selectedRunbookId,
        });
    };

    private buildRunbookSummary = () => {
        return this.state.selectedRunbookId ? Summary.summary(<span>{ScheduledTriggerDescriptionHelper.lookupRunbookChip(this.state.selectedRunbookId, this.props.runbooks)}</span>) : Summary.placeholder("No runbook selected");
    };

    private buildTargetEnvironmentSummary = () => {
        return this.state.selectedEnvironments && this.state.selectedEnvironments.length > 0
            ? Summary.summary(
                  <span>
                      Selected Runbook will be run on {this.state.selectedEnvironments.map((selectedEnvironment) => this.environmentChip(selectedEnvironment))} environment{this.state.selectedEnvironments.length > 1 ? "s" : ""}
                  </span>
              )
            : Summary.placeholder("No target environment selected");
    };

    private environmentChip = (id: string) => {
        const LookupEnvironmentChip = LookupResourceChipComponent<EnvironmentResource>();

        return <LookupEnvironmentChip lookupCollection={this.props.environments} key={id} lookupId={id} type={ChipIcon.Environment} chipRender={(item) => <EnvironmentChip environmentName={item.Name} />} />;
    };
}
