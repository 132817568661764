import * as React from "react";

import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { OctopusIcon, OctopusIconType } from "primitiveComponents/dataDisplay/Icon";
import { WorkerPoolSummaryResource, WorkerPoolsSummaryResource } from "client/resources";

import BusyIndicator from "components/BusyIndicator";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import InternalLink from "components/Navigation/InternalLink";
import { Section } from "components/Section/Section";
import routeLinks from "../../../../routeLinks";
import { withTheme } from "components/Theme";
import ActionButton, { ActionButtonType } from "components/Button";

const styles = require("./style.less");

interface WorkersPoolsCardProps {
    workerSummary: WorkerPoolsSummaryResource | undefined;
}

interface WorkerPoolsCardState extends DataBaseComponentState {
    filterText: string;
    numberOfWorkerPoolsToRender: number;
}

const pageSize: number = 20;

export default class WorkerPoolsCard extends DataBaseComponent<WorkersPoolsCardProps, WorkerPoolsCardState> {
    constructor(props: WorkersPoolsCardProps) {
        super(props);
        this.state = {
            filterText: "",
            numberOfWorkerPoolsToRender: pageSize,
        };
    }

    render() {
        return withTheme((theme) => {
            if (!this.props.workerSummary) {
                return this.props.workerSummary === undefined ? null : (
                    <div className={styles.card}>
                        <div className={styles.cardTitleContainer}>
                            <div className={styles.cardTitle}>
                                <InternalLink to={routeLinks.infrastructure.workerPools.root} color={theme.darkText}>
                                    Worker Pools
                                </InternalLink>
                            </div>
                        </div>
                        <Section>
                            <BusyIndicator inline={true} show={true} />
                        </Section>
                    </div>
                );
            }
            let workerSummaries = this.props.workerSummary && this.props.workerSummary.WorkerPoolSummaries;
            workerSummaries = workerSummaries.filter((item) => this.onFilter(this.state.filterText, item));
            const workerpoolRows =
                workerSummaries &&
                workerSummaries.slice(0, this.state.numberOfWorkerPoolsToRender).map((workerSummary, index) => {
                    const totalMachines = workerSummary.TotalMachines || 0;
                    return (
                        <div className={styles.workerpoolRowsContainer} key={workerSummary.WorkerPool.Id}>
                            <div className={styles.workerpoolIcon}>
                                <OctopusIcon iconType={OctopusIconType.WorkerPool} color={theme.iconDark} />
                            </div>
                            <div className={styles.workerpoolName}>
                                <InternalLink to={routeLinks.infrastructure.workerPools.filtered({ workerPoolIds: workerSummary.WorkerPool.Id })}>{workerSummary.WorkerPool.Name}</InternalLink>
                            </div>
                            <div className={styles.workerpoolMachinesCount}>{totalMachines.toLocaleString()}</div>
                        </div>
                    );
                });
            return (
                <div className={styles.card}>
                    <div className={styles.cardTitleContainer}>
                        <div className={styles.cardTitle}>
                            <InternalLink to={routeLinks.infrastructure.workerPools.root} color={theme.whiteConstant}>
                                Worker Pools <span className={styles.cardCount}>({workerSummaries && workerSummaries.length.toLocaleString()})</span>
                            </InternalLink>
                        </div>
                    </div>
                    <div className={styles.cardContentContainer}>
                        {(this.props.workerSummary.WorkerPoolSummaries.length > 1 || this.state.filterText) && (
                            <div>
                                <FilterSearchBox
                                    placeholder="Filter..."
                                    onChange={(value) => this.setState({ filterText: value.toLowerCase(), numberOfWorkerPoolsToRender: pageSize })}
                                    inputClassName={styles.dashboardFilterInputClassName}
                                    iconColor={theme.secondaryText}
                                    iconStyle={{ width: "20px", height: "20px", top: "14px" }}
                                />
                            </div>
                        )}
                        {workerpoolRows}
                        {workerSummaries && this.state.numberOfWorkerPoolsToRender <= workerSummaries.length && (
                            <ActionButton type={ActionButtonType.Ternary} onClick={() => this.setState({ ...this.state, numberOfWorkerPoolsToRender: this.state.numberOfWorkerPoolsToRender + pageSize })} label="Load more" />
                        )}
                    </div>
                </div>
            );
        });
    }

    private onFilter(filter: string, resource: WorkerPoolSummaryResource) {
        return !filter || filter.length === 0 || !resource || resource.WorkerPool.Name.toLowerCase().includes(filter.toLowerCase());
    }
}
