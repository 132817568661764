import React from "react";
import { AvailableEditPropertiesFormComponents, StepUI } from "components/Actions/StepUIAPI/StepUI";
import { ExpandableFormSection, FormSectionHeading } from "components/form/Sections";
import { AvailableSectionContentComponents, SectionContent } from "components/Actions/StepUIAPI/FormSection";
import { BoundSensitive } from "components/form/Sensitive/Sensitive";
import { getSectionSummary } from "./GetSectionSummary";
import { toResourceSensitiveValue, toStepUISensitiveValue } from "components/StepPackageEditor/Sensitive/SensitiveValueConverters";
import { exhaustiveCheck } from "utils/exhaustiveCheck";

interface TypedStepPackageEditorProps<Properties> {
    stepUI: StepUI<Properties>;
    properties: Properties;
    setProperties(properties: Properties): void;
    isExpandedByDefault: boolean;
}

export function TypedStepPackageEditor<Properties>(props: TypedStepPackageEditorProps<Properties>) {
    const definition = props.stepUI();
    return (
        <>
            <FormSectionHeading title={definition.stepName} />
            {definition.editPropertiesForm(prepareAvailableFormSectionComponents()).map((section) => {
                const summary = getSectionSummary(props.properties, section);

                return (
                    <ExpandableFormSection key={section.title} title={section.title} help={section.helpText} errorKey={"todo-step-UI" + section.title} summary={summary} isExpandedByDefault={props.isExpandedByDefault}>
                        {section.content.map((content, i) => (
                            <FormSectionContent<Properties> key={getSectionContentReactKey(content, i)} content={content} properties={props.properties} setProperties={props.setProperties} />
                        ))}
                    </ExpandableFormSection>
                );
            })}
        </>
    );
}

interface FormSectionContentProps<Properties> {
    content: SectionContent<Properties>;
    properties: Properties;
    setProperties(properties: Properties): void;
}

function getSectionContentReactKey<Properties>(content: SectionContent<Properties>, index: number) {
    switch (content.type) {
        case "sensitive":
            return content.label;
        default:
            return exhaustiveCheck(content.type, "Not all section content have been given a react key.");
    }
}

function FormSectionContent<Properties>({ content, properties, setProperties }: FormSectionContentProps<Properties>) {
    switch (content.type) {
        case "sensitive":
            return (
                <BoundSensitive
                    value={toResourceSensitiveValue(content.getValue(properties))}
                    onChange={(newValue) => {
                        if (typeof newValue === "string") {
                            // todo-step-ui: Variable binding support
                            throw new Error("Variable binding not yet supported in the Step UI Framework");
                        }
                        const updatesToProperties = content.onChange(toStepUISensitiveValue(newValue), properties);
                        setProperties({
                            ...properties,
                            ...updatesToProperties,
                        });
                    }}
                    label={content.label}
                />
            );
        default:
            return exhaustiveCheck(content.type, `Section content type not yet implemented: ${content.type}`);
    }
}

function prepareAvailableFormSectionComponents<Properties>(): AvailableEditPropertiesFormComponents<Properties> {
    return {
        section: (props) => {
            return {
                type: "section",
                title: props.title,
                content: props.content(prepareSectionContentComponents()),
                helpText: props.helpText,
            };
        },
    };
}

function prepareSectionContentComponents<Properties>(): AvailableSectionContentComponents<Properties> {
    return {
        sensitiveText: (props) => {
            return {
                type: "sensitive",
                getValue: props.getValue,
                label: props.label,
                onChange: props.onChange,
                summary: props.summary,
            };
        },
    };
}
