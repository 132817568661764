import { InputProperty, InputPropertyValue } from "components/StepPackageEditor/StepPackage/StepPackageInputSchema";
import { AsStepResourceProperties } from "components/StepPackageEditor/Properties/stepPropertiesMappedTypes";
import { SensitiveValue } from "client/resources";
import { toResourceSensitiveValue, toStepUISensitiveValue } from "components/StepPackageEditor/Sensitive/SensitiveValueConverters";
import { SensitiveValue as StepUISensitiveValue } from "components/Actions/StepUIAPI/SensitiveText";

// Some of our well-known types (like for Sensitive Values, or Package References)
// have a different structure for our Step UIs compared to our resources.
// Our Step UIs change with a different cadence to our resources, so we reduce coupling between resources and our Step UIS by using different types.
// These functions ensure that any well known types are mapped from the resource represention to the step UI representation, and back again.

export function asStepUIProperties<StepUIProperties>(inputSchemaProperties: InputProperty<StepUIProperties>[], stepResourceProperties: AsStepResourceProperties<StepUIProperties>): StepUIProperties {
    const result = inputSchemaProperties.reduce<Partial<StepUIProperties>>((prev, p) => {
        return {
            ...prev,
            [p.propertyName]: mapPropertyValueToStepUIProperty<StepUIProperties>(p.propertyName, p, stepResourceProperties[p.propertyName]),
        };
    }, {});

    // We assume that we inputSchemaProperties forms a complete set of properties for StepUIProperties
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return result as StepUIProperties;
}

export function asStepResourceProperties<StepUIProperties>(inputSchemaProperties: InputProperty<StepUIProperties>[], stepUIProperties: StepUIProperties): AsStepResourceProperties<StepUIProperties> {
    const result = inputSchemaProperties.reduce<Partial<AsStepResourceProperties<StepUIProperties>>>((prev, p) => {
        return {
            ...prev,
            [p.propertyName]: mapStepUIPropertyValueToResourceProperty(p.propertyName, p, stepUIProperties[p.propertyName]),
        };
    }, {});

    // We assume that we inputSchemaProperties forms a complete set of properties for StepUIProperties
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return result as AsStepResourceProperties<StepUIProperties>;
}

function typeNeedsConverting(possibleTypes: InputPropertyValue): boolean {
    return possibleTypes === "sensitive";
}

function mapStepUIPropertyValueToResourceProperty<StepUIProperties>(
    property: keyof StepUIProperties,
    inputPropertySchema: InputProperty<StepUIProperties>,
    value: StepUIProperties[typeof property]
): AsStepResourceProperties<StepUIProperties>[typeof property] {
    const possibleTypesThatNeedConverting = inputPropertySchema.possibleValues.filter(typeNeedsConverting);
    if (possibleTypesThatNeedConverting.length > 1) {
        throw new Error(`Only a single type for each property that needs converting is supported, but found multiple possible types for property ${inputPropertySchema.propertyName}: ${inputPropertySchema.possibleValues.join(", ")}`);
    }

    if (possibleTypesThatNeedConverting.length === 1) {
        const typeToConvert = possibleTypesThatNeedConverting[0];
        if (typeToConvert === "sensitive") {
            // We have constrained our valid types such we you cannot have a union type that consists of multiple object types (eg `PackageReference | SensitiveValue | GenericObject` is invalid)
            const valueIsASensitiveValue = typeof value === "object";
            if (valueIsASensitiveValue) {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                const stepUISensitiveValue = (value as unknown) as StepUISensitiveValue;
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                return toResourceSensitiveValue(stepUISensitiveValue) as AsStepResourceProperties<StepUIProperties>[typeof property];
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return value as AsStepResourceProperties<StepUIProperties>[typeof property];
}

function mapPropertyValueToStepUIProperty<StepUIProperties>(
    property: keyof StepUIProperties,
    inputPropertySchema: InputProperty<StepUIProperties>,
    value: AsStepResourceProperties<StepUIProperties>[typeof property]
): StepUIProperties[typeof property] {
    const possibleTypesThatNeedConverting = inputPropertySchema.possibleValues.filter(typeNeedsConverting);
    if (possibleTypesThatNeedConverting.length > 1) {
        throw new Error(`Only a single type for each property that needs converting is supported, but found multiple possible types for property ${inputPropertySchema.propertyName}: ${inputPropertySchema.possibleValues.join(", ")}`);
    }

    if (possibleTypesThatNeedConverting.length === 1) {
        const typeToConvert = possibleTypesThatNeedConverting[0];
        if (typeToConvert === "sensitive") {
            // We have constrained our valid types such we you cannot have a union type that consists of multiple object types (eg `PackageReference | SensitiveValue | GenericObject` is invalid)
            const valueIsASensitiveValue = typeof value === "object";
            if (valueIsASensitiveValue) {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                const resourceSensitiveValue = (value as unknown) as SensitiveValue;
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                return (toStepUISensitiveValue(resourceSensitiveValue) as unknown) as StepUIProperties[typeof property];
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return value as StepUIProperties[typeof property];
}
