/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import pluginRegistry from "../pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import Roles from "../Roles";
import { ActionSummaryProps } from "../actionSummaryProps";
import { ActionExecutionLocation } from "client/resources/actionExecutionLocation";
import { TentaclePackageActionEdit } from "components/Actions/tentaclePackage/tentaclePackageAction";
import { DisplayFeedName } from "../DisplayFeedName";
import { GetPrimaryPackageReference } from "../../../client/resources";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout";
import { TargetRoles } from "areas/projects/components/Process/types";

interface WindowsServiceActionSummaryState {
    feedName: string;
}

class WindowsServiceActionSummary extends BaseComponent<ActionSummaryProps, WindowsServiceActionSummaryState> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        return pkg ? (
            <div>
                {"Deploy Windows Service using package"} <strong> {pkg.PackageId} </strong> {"from"} <DisplayFeedName pkg={pkg} />
                {this.props.targetRolesAsCSV && (
                    <span>
                        {" "}
                        to deployment targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} />{" "}
                    </span>
                )}
            </div>
        ) : (
            <Callout type={CalloutType.Warning} title="Misconfigured step">
                Package was not selected or cannot be found. Please review this step and ensure a valid package is selected.
            </Callout>
        );
    }
}

pluginRegistry.registerActionForAllScopes({
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    actionType: "Octopus.WindowsService",
    summary: (properties, targetRolesAsCSV, packages) => <WindowsServiceActionSummary properties={properties} packages={packages} targetRolesAsCSV={targetRolesAsCSV} />,
    edit: TentaclePackageActionEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => true,
    features: {
        permanent: ["Octopus.Features.WindowsService"],
        optional: [
            "Octopus.Features.ConfigurationTransforms",
            "Octopus.Features.ConfigurationVariables",
            "Octopus.Features.CustomDirectory",
            "Octopus.Features.CustomScripts",
            "Octopus.Features.JsonConfigurationVariables",
            "Octopus.Features.SubstituteInFiles",
            "Octopus.Features.SelectPowerShellEditionForWindows",
        ],
        initial: ["Octopus.Features.ConfigurationTransforms", "Octopus.Features.ConfigurationVariables"],
    },
});
