import * as React from "react";
import { CronTriggerScheduleResource, ServerTimezoneResource } from "client/resources";
import { ExpandableFormSection, Note, Text } from "components/form";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import getCronExpressionSummary from "./getCronExpressionSummary";
import ScheduleTimezone from "./ScheduleTimezone";

interface CronExpressionScheduledTriggerEditorProps {
    schedule: CronTriggerScheduleResource;
    timezones: ServerTimezoneResource[];
    onScheduleChange(schedule: CronTriggerScheduleResource): void;
}

interface CronExpressionScheduledTriggerEditorState {
    scheduleCronExpression: string;
    scheduleTimezone: string;
}

export class CronExpressionScheduledTriggerEditor extends React.Component<CronExpressionScheduledTriggerEditorProps, CronExpressionScheduledTriggerEditorState> {
    constructor(props: CronExpressionScheduledTriggerEditorProps) {
        super(props);
        this.state = this.initState(this.props.schedule);
    }

    componentWillReceiveProps(newProps: CronExpressionScheduledTriggerEditorProps) {
        if (newProps.schedule.CronExpression !== this.props.schedule.CronExpression || newProps.schedule.Timezone !== this.props.schedule.Timezone) {
            this.setState(this.initState(newProps.schedule));
        }
    }

    render() {
        return (
            <div>
                <ExpandableFormSection errorKey="ScheduleCronExpression" title="Cron Expression" summary={getCronExpressionSummary(this.state.scheduleCronExpression)} help="Enter the cron expression to configure when the trigger should execute">
                    <Text label="Cron expression" value={this.state.scheduleCronExpression} onChange={this.onScheduleCronExpressionChange} />
                    <Note>{getCronExpressionSummary(this.state.scheduleCronExpression).node}</Note>
                    <Note>
                        For information about cron expressions, see our docs:
                        <ExternalLink href="CronExpressions"> Constructing a cron expression in Octopus</ExternalLink>.
                    </Note>
                </ExpandableFormSection>
                <ScheduleTimezone timezone={this.state.scheduleTimezone} timezones={this.props.timezones} onTimezoneChanged={this.onScheduleTimezoneChange} />
            </div>
        );
    }

    initState(value?: CronTriggerScheduleResource) {
        const schedule = value || new CronTriggerScheduleResource();
        return {
            scheduleCronExpression: schedule.CronExpression,
            scheduleTimezone: schedule.Timezone,
        };
    }

    onScheduleCronExpressionChange = (cronExpression: string) => {
        this.setState(
            {
                scheduleCronExpression: cronExpression,
            },
            () => this.raiseChange()
        );
    };

    onScheduleTimezoneChange = (timezone: string) => {
        this.setState(
            {
                scheduleTimezone: timezone,
            },
            () => this.raiseChange()
        );
    };

    raiseChange() {
        this.props.onScheduleChange({
            ...this.props.schedule,
            CronExpression: this.state.scheduleCronExpression,
            Timezone: this.state.scheduleTimezone,
        });
    }
}
