/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable no-restricted-imports */

import * as React from "react";
import * as History from "history";
import PaperLayout, { PaperLayoutProps } from "../PaperLayout/PaperLayout";
import ExpansionButtons, { toggleExpandos } from "../form/Sections/ExpansionButtons";
import { Prompt, RouteComponentProps, withRouter } from "react-router";
import { isEqual } from "lodash";
import ActionList from "../ActionList";
import { isAllowed, PermissionCheckProps } from "../PermissionCheck/PermissionCheck";
import { default as OverflowMenu, MenuItem } from "components/Menu/OverflowMenu";
import { Errors } from "components/DataBaseComponent/Errors";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import FormComponent from "../FormComponent/FormComponent";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import store from "../../store";
import { createFormPaperLayoutMountedAction } from "./reducers";
import { TrackDirty } from "components/DevTools/DevToolsContext";
import { Snackbar } from "primitiveComponents/feedback/Snackbar";
import { AnalyticLinkProvider } from "analytics/AnalyticLink";

const styles = require("./style.less");

export interface PrimaryActionProps {
    label: string;
    busyLabel: string;
    disabled: boolean | undefined;
    onClick: () => Promise<boolean>;
}

export interface FormProps {
    model: object | undefined;
    cleanModel?: object;
    expandAllOnMount?: boolean;
    hideExpandAll?: boolean;
    errors?: Errors;
    saveText?: string;
    savePermission?: PermissionCheckProps | undefined;
    saveButtonLabel?: string;
    saveButtonBusyLabel?: string;
    overFlowActions?: Array<MenuItem | MenuItem[]>;
    secondaryAction?: React.ReactNode;
    hideSectionControls?: boolean;
    disableDirtyFormChecking?: boolean;
    disableKeyboardFormSubmission?: boolean;
    forceDisableFormSaveButton?: boolean;
    forceDisabledReason?: string;
    isNewRecord?: boolean;
    customPrimaryAction?: React.ReactNode | ((props: PrimaryActionProps) => React.ReactNode); // This allows you to override the primary save button that you get by default.
    dirtyTrackingKey?: string;
    dirtyTrackingDisabled?: boolean;
    onSaveClick(): Promise<{} | void> | void;
    confirmNavigateSaveLabel?: string;
}

interface FormPaperLayoutState {
    dirty: boolean;
    showSnackbar: boolean;
    redirect: string | null;
}

export type FormPaperLayoutProps = FormProps & PaperLayoutProps;
type InternalPaperLayoutProps = FormPaperLayoutProps & RouteComponentProps;

class FormPaperLayout extends BaseComponent<InternalPaperLayoutProps, FormPaperLayoutState> {
    public static defaultProps: Partial<InternalPaperLayoutProps> = {
        expandAllOnMount: false,
        saveText: "Details updated",
        dirtyTrackingKey: "Form",
    };

    constructor(props: InternalPaperLayoutProps) {
        super(props);
        this.state = {
            dirty: false,
            showSnackbar: false,
            redirect: null,
        };
    }

    handleSnackbarClose = () => {
        this.setState({
            showSnackbar: false,
        });
    };

    componentWillReceiveProps(nextProps: InternalPaperLayoutProps) {
        if (!nextProps.disableDirtyFormChecking) {
            const dirty = !isEqual(nextProps.model, nextProps.cleanModel);
            this.setState({ dirty });
        }
    }

    componentDidMount() {
        store.dispatch(createFormPaperLayoutMountedAction(this.save, this.props.confirmNavigateSaveLabel));
    }

    componentWillUnmount() {
        store.dispatch(createFormPaperLayoutMountedAction(undefined, undefined));
    }

    render() {
        const { children, model, errors, ...rest } = this.props;

        const overFlowMenu = this.props.overFlowActions && this.props.overFlowActions.length > 0 && <OverflowMenu menuItems={this.props.overFlowActions} />;

        const primaryAction = this.createPrimaryAction();

        if (this.state.redirect) {
            return <InternalRedirect to={this.state.redirect} push={true} />;
        }

        const sectionControl = this.props.hideSectionControls ? null : (
            <div className={styles.sectionControlContainer}>
                {this.props.sectionControl ?? <div></div>}
                <ActionList actions={[this.props.secondaryAction, primaryAction, overFlowMenu].filter((action) => !!action)} />
            </div>
        );

        return (
            <AnalyticLinkProvider location="Paper Form">
                <FormComponent onFormSubmit={this.onCtrlEnterPressed}>
                    {!this.props.dirtyTrackingDisabled && <TrackDirty name={this.props.dirtyTrackingKey ?? "Form"} cleanModel={this.props.cleanModel} model={this.props.model} />}
                    <PaperLayout {...rest} sectionControl={sectionControl} errors={this.props.errors}>
                        <Prompt when={this.state.dirty && !this.props.disableDirtyFormChecking} message={(location) => this.getPromptMessage(location)} />
                        {!this.props.hideExpandAll && <ExpansionButtons errors={errors} expandAllOnMount={this.props.expandAllOnMount} />}
                        <div className={styles.formContainer}>{children}</div>
                        {this.props.saveText && <Snackbar open={this.state.showSnackbar} content={this.props.saveText} autoHideDuration={3500} onClose={this.handleSnackbarClose} textAlign="center" />}
                    </PaperLayout>
                </FormComponent>
            </AnalyticLinkProvider>
        );
    }

    createPrimaryAction(): React.ReactNode {
        const primaryActionProps = this.primaryActionProps();

        if (!this.props.customPrimaryAction) {
            return (
                <ActionButton
                    label={primaryActionProps.label}
                    busyLabel={primaryActionProps.busyLabel}
                    type={ActionButtonType.Save}
                    disabled={primaryActionProps.disabled}
                    onClick={(e: React.MouseEvent | undefined) => this.handleSave(primaryActionProps.onClick, e)}
                />
            );
        }

        if (typeof this.props.customPrimaryAction === "function") {
            return this.props.customPrimaryAction(primaryActionProps);
        }

        return this.props.customPrimaryAction;
    }

    primaryActionLabel(): string {
        const disabledDueToPermission = this.isDisableDueToPermission();
        const permissionLabel = this.getPermissionLabel();

        // prettier-ignore
        return disabledDueToPermission
            ? `${permissionLabel} permission required`
            : this.props.forceDisableFormSaveButton && this.props.forceDisabledReason
                ? this.props.forceDisabledReason
                : this.props.saveButtonLabel! || "Save";
    }

    private isDisableDueToPermission = () => {
        return !!this.props.savePermission ? !isAllowed(this.props.savePermission as PermissionCheckProps) : false;
    };

    private getPermissionLabel(): string {
        if (this.props.savePermission === undefined) {
            return "No";
        }

        if (Array.isArray(this.props.savePermission.permission)) {
            return this.props.savePermission.permission.join(", ");
        }

        return this.props.savePermission.permission;
    }

    private shouldBeDisabled = () => {
        return ((!this.state.dirty || this.props.busy) && !this.props.disableDirtyFormChecking && !this.props.isNewRecord) || this.props.forceDisableFormSaveButton;
    };

    private primaryActionProps(): PrimaryActionProps {
        return {
            label: this.primaryActionLabel(),
            busyLabel: this.props.saveButtonBusyLabel || "Saving",
            disabled: this.isDisableDueToPermission() || this.shouldBeDisabled(),
            onClick: this.save,
        };
    }

    private onCtrlEnterPressed = async () => {
        const disabledDueToPermission = this.isDisableDueToPermission();
        const isSaveDisable = disabledDueToPermission || this.shouldBeDisabled();
        if (!isSaveDisable && !this.props.disableKeyboardFormSubmission) {
            await this.save();
        }
    };

    private handleSave = async (saveAction: () => Promise<boolean>, e: React.MouseEvent | undefined): Promise<boolean> => {
        if (e) {
            e.preventDefault();
        }
        return saveAction();
    };

    private save = async (): Promise<boolean> => {
        await this.props.onSaveClick();
        if (!this.props.errors) {
            if (this.props.saveText) {
                this.setState({ showSnackbar: true });
            }
            toggleExpandos(false);
        }
        return !this.props.errors;
    };

    private getPromptMessage(location: History.Location) {
        // If the pathname hasn't changed, return true which will allow the transition.
        // This is so we can ignore filter changes which only modify the query string.
        if (location.pathname === this.props.location.pathname) {
            return true;
        }
        return "If you leave this page, any changes you have made will be lost. Are you sure you wish to leave this page?";
    }
}

export default withRouter(FormPaperLayout);
