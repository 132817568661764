/* eslint-disable @typescript-eslint/no-non-null-assertion */

import isBound from "../../form/BoundField/isBound";
import * as React from "react";
import pluginRegistry, { ActionEditProps } from "../pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import Roles from "../Roles";
import { ActionSummaryProps } from "../actionSummaryProps";
import { ActionExecutionLocation } from "../../../client/resources/actionExecutionLocation";
import { repository } from "clientInstance";
import Summary, { OverflowSummary, PlaceholderSummary } from "components/form/Sections/Summary";
import FormSectionHeading from "components/form/Sections/FormSectionHeading";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import { withBoundField } from "components/form/BoundField/BoundField";
import { AccountResource, AccountType } from "client/resources";
import { StringRadioButtonGroup, RadioButton, Note } from "components/form";
import AccountSelect from "components/form/AccountSelect/AccountSelect";
import { ScriptActionEdit, ScriptProperties } from "components/Actions/script/scriptAction";
import { ScriptPackageProperties } from "../script/ScriptPackageReferenceDialog";
import { SupportedLanguage } from "../../ScriptingLanguageSelector/ScriptingLanguageSelector";
import { TargetRoles } from "areas/projects/components/Process/types";
import { doesRunOnSupportBundledTools } from "areas/projects/components/Process/Common/CommonProcessHelpers";
import { KeyedItemProps } from "../../KeyAccessProvider/types";
import { useKeyedItemAccess } from "../../KeyAccessProvider/KeyedItemAccessProvider";
import { AccountSelectionSummary } from "../../form/AccountSelect/AccountSelectionSummary";

class AzurePowerShellActionSummary extends BaseComponent<ActionSummaryProps> {
    render() {
        return (
            <div>
                Run a script using an Azure subscription, with Azure modules loaded by default
                {this.props.targetRolesAsCSV && (
                    <span>
                        {", "}
                        on behalf of targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} />
                    </span>
                )}
            </div>
        );
    }
}

interface AzurePowerShellProperties extends ScriptProperties {
    OctopusUseBundledTooling: string;
    "Octopus.Action.Azure.AccountId": string;
}

interface AzurePowerShellActionEditState {
    accounts: AccountResource[];
    account: AccountResource;
}

const BoundAccountSelect = withBoundField(AccountSelect);

type AzurePowershellActionEditProps = ActionEditProps<AzurePowerShellProperties, ScriptPackageProperties>;
type AzurePowershellActionEditInternalProps = AzurePowershellActionEditProps & KeyedItemProps;

const AzurePowerShellActionEdit: React.FC<AzurePowershellActionEditProps> = (props) => {
    const keyedBy = useKeyedItemAccess();
    return <AzurePowerShellActionEditInternal itemKey={keyedBy} {...props} />;
};

class AzurePowerShellActionEditInternal extends BaseComponent<AzurePowershellActionEditInternalProps, AzurePowerShellActionEditState> {
    constructor(props: AzurePowershellActionEditInternalProps) {
        super(props);

        this.state = {
            accounts: [],
            account: null!,
        };
    }

    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            this.setState({
                accounts: await repository.Accounts.all(),
            });
        });
    }

    accountSummary() {
        const accountIdOrName = this.props.properties["Octopus.Action.Azure.AccountId"];

        return (
            <AccountSelectionSummary
                accountIdOrName={accountIdOrName}
                accounts={this.state.accounts}
                renderFound={(account) => <OverflowSummary>{account.Name}</OverflowSummary>}
                renderMissing={() => <PlaceholderSummary>No account has been selected</PlaceholderSummary>}
            />
        );
    }

    toolingSummary() {
        if (this.props.properties.OctopusUseBundledTooling === "False") {
            return Summary.summary("Use Azure tools pre-installed on the worker");
        }
        return Summary.summary("Use Azure tools bundled with Octopus");
    }

    render() {
        return (
            <div>
                {this.renderBundledToolsSection()}

                <FormSectionHeading title="Azure" />

                <ExpandableFormSection errorKey="Octopus.Action.Azure.AccountId" isExpandedByDefault={this.props.expandedByDefault} title="Account" summary={this.accountSummary()} help="Select the Azure account to use to run the script">
                    <BoundAccountSelect
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        resetValue=""
                        value={this.props.properties["Octopus.Action.Azure.AccountId"]}
                        type={[AccountType.AzureSubscription, AccountType.AzureServicePrincipal]}
                        onChange={(x: string) => this.props.setProperties({ ["Octopus.Action.Azure.AccountId"]: x })}
                        error={this.props.getFieldError("Octopus.Action.Azure.AccountId")}
                        items={this.state.accounts}
                        onRequestRefresh={this.refreshAccounts}
                    />
                </ExpandableFormSection>

                <ScriptActionEdit
                    plugin={this.props.plugin}
                    projectId={this.props.projectId}
                    localNames={this.props.localNames}
                    properties={this.props.properties}
                    packages={this.props.packages}
                    setProperties={this.props.setProperties}
                    setPackages={this.props.setPackages}
                    doBusyTask={this.props.doBusyTask}
                    busy={this.props.busy}
                    getFieldError={this.props.getFieldError}
                    errors={this.props.errors}
                    expandedByDefault={this.props.expandedByDefault}
                    supportedLanguages={SupportedLanguage.PowerShellAndBash}
                    parameters={this.props.parameters}
                />
            </div>
        );
    }

    private renderBundledToolsSection = () => {
        const useBundled = this.props.properties.OctopusUseBundledTooling || "True";

        const bundledToolsSection = (
            <ExpandableFormSection
                errorKey="OctopusUseBundledTooling"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Azure Tools"
                summary={this.toolingSummary()}
                help="Select whether to use the bundled Azure tools or using tooling pre-installed on the worker"
            >
                <Note>This step depends on the Azure CLI.</Note>
                <Note>
                    For <strong>Linux workers the Azure CLI must be pre-installed</strong>. The options below do not apply.
                </Note>
                <Note>
                    When executing on a worker running Windows, Octopus server can supply the Azure CLI 2.0.50 (and the Azure Resource Manager (ARM) PowerShell modules 6.13.1). Alternatively, to use a different version these tools should be
                    pre-installed on the workers in the worker pool.
                </Note>
                <Note>
                    For example, to use the Az PowerShell modules rather than the bundled RM modules, they Az modules should be pre-installed and <em>Use Azure tooling pre-installed on the worker</em> should be selected below.
                </Note>
                <StringRadioButtonGroup
                    value={useBundled}
                    onChange={(x: string) => {
                        this.props.setProperties({ OctopusUseBundledTooling: x });
                    }}
                >
                    <RadioButton value="True" label="Use Azure tools bundled with Octopus" />
                    <RadioButton value="False" label="Use Azure tools pre-installed on the worker" />
                </StringRadioButtonGroup>
            </ExpandableFormSection>
        );

        if (doesRunOnSupportBundledTools(this.props.runOn)) {
            return bundledToolsSection;
        }

        return null;
    };

    private refreshAccounts = () => {
        return this.props.doBusyTask(async () => {
            this.setState({ accounts: await repository.Accounts.all() });
        });
    };
}

pluginRegistry.registerActionForAllScopes({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.AzurePowerShell",
    summary: (properties, targetRolesAsCSV) => <AzurePowerShellActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    edit: AzurePowerShellActionEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => false,
    features: {
        optional: ["Octopus.Features.SubstituteInFiles", "Octopus.Features.JsonConfigurationVariables", "Octopus.Features.ConfigurationTransforms", "Octopus.Features.ConfigurationVariables", "Octopus.Features.SelectPowerShellEditionForWindows"],
    },
});
