import React from "react";
import { FormSection } from "../Actions/StepUIAPI/FormSection";
import { SummaryNode } from "../form";
import { availableSummaryComponents } from "./AvailableSummaryComponents";
import compact from "lodash/compact";
import { Summary as StepUiSummary, SummaryTemplateOptions } from "../Actions/StepUIAPI/Summary";
import { Summary } from "../form/Sections";
import { SensitiveText } from "components/Actions/StepUIAPI/SensitiveText";
import { combineSummaries } from "./CombineSummaries";
import { exhaustiveCheck } from "../../utils/exhaustiveCheck";

export function getSectionSummary<Properties>(properties: Properties, { content, title }: FormSection<Properties>): SummaryNode {
    const contentSummaries: Array<ContentSummaryInformation | null> = content.map((component) => {
        return getContentSummaryInformation(component, properties);
    });

    const fieldSummaries = compact(contentSummaries);

    if (allFieldsAreDefaulted(fieldSummaries)) {
        return Summary.default(<SectionSummary sectionSummary={getAggregatedFieldSummaries(fieldSummaries)} />);
    } else if (noFieldsAreConfigured(fieldSummaries)) {
        return Summary.placeholder(<SectionSummary sectionSummary={`No ${title} configured`} />);
    } else {
        return Summary.summary(<SectionSummary sectionSummary={getAggregatedFieldSummaries(fieldSummaries)} />);
    }
}

function allFieldsAreDefaulted(fieldSummaries: ContentSummaryInformation[]): fieldSummaries is NonEmptyContentSummaryInformation[] {
    return fieldSummaries.every(isNotEmptySummaryInformation) && fieldSummaries.every((x) => x.isDefaultValue);
}

function noFieldsAreConfigured(fieldSummaries: ContentSummaryInformation[]) {
    return fieldSummaries.every((x) => x === "empty" || x.isDefaultValue);
}

function getAggregatedFieldSummaries(fieldSummaries: ContentSummaryInformation[]) {
    return compact(fieldSummaries.map((x) => (x !== "empty" ? x.summary : null))).reduce<StepUiSummary>(combineSummaries, []);
}

function isNotEmptySummaryInformation(summary: ContentSummaryInformation): summary is NonEmptyContentSummaryInformation {
    return summary !== "empty";
}

type NonEmptyContentSummaryInformation = { isDefaultValue: boolean; summary: StepUiSummary };
type ContentSummaryInformation = "empty" | NonEmptyContentSummaryInformation;

function getContentSummaryInformation<Properties>(component: SensitiveText<Properties>, properties: Properties) {
    //todo-step-ui we need an exhaustive check here
    switch (component.type) {
        case "sensitive": {
            const value = component.getValue(properties);
            if (value.type === "empty") {
                return "empty";
            } else {
                return {
                    isDefaultValue: false,
                    summary: component.summary(properties, availableSummaryComponents),
                };
            }
        }
        default:
            return null;
    }
}

function SectionSummary({ sectionSummary }: { sectionSummary: StepUiSummary }) {
    if (typeof sectionSummary === "string") {
        return <>{sectionSummary}</>;
    }

    return (
        <>
            {sectionSummary.map((summary) => (
                <SummaryOption value={summary} />
            ))}
        </>
    );
}

function SummaryOption({ value }: { value: SummaryTemplateOptions }) {
    if (typeof value === "string") {
        return <>{value}</>;
    }

    return <SummaryComponentOption value={value} />;
}

function SummaryComponentOption({ value: option }: { value: Exclude<SummaryTemplateOptions, string> }) {
    const { type, value } = option;

    switch (type) {
        case "em": {
            return (
                <em>
                    <SectionSummary sectionSummary={value} />
                </em>
            );
        }
        case "strong": {
            return (
                <strong>
                    <SectionSummary sectionSummary={value} />
                </strong>
            );
        }
        default:
            exhaustiveCheck(type, "Not all summary component types have been handled");
    }
}
