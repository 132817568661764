import * as React from "react";

import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { EnvironmentsSummaryResource, TagResource, TagSetResource } from "client/resources";

import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import Permission from "client/resources/permission";
import Tag from "components/Tag";
import { connect } from "react-redux";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import routeLinks from "../../../../routeLinks";
import { sortBy } from "lodash";
import { withTheme } from "components/Theme";
import { Navigate } from "components/Navigation/Navigate";
import ActionButton, { ActionButtonType } from "components/Button";

const styles = require("./style.less");

interface TagSetsCardProps {
    environmentSummary: EnvironmentsSummaryResource;
    tagSets: TagSetResource[];
}

interface GlobalConnectedProps {
    isMultiTenancyEnabled: boolean;
}

interface TagSetsCardState extends DataBaseComponentState {
    filterText: string;
    numberOfTagsToRender: number;
}

type Props = TagSetsCardProps & GlobalConnectedProps;
const pageSize: number = 20;

class TagSetsCard extends DataBaseComponent<Props, TagSetsCardState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            filterText: "",
            numberOfTagsToRender: pageSize,
        };
    }

    render() {
        return withTheme((theme) => {
            if (
                !this.props.environmentSummary ||
                !this.props.tagSets ||
                !this.props.isMultiTenancyEnabled ||
                !isAllowed({
                    permission: Permission.TenantView,
                    tenant: "*",
                })
            ) {
                return null;
            }

            let totalTagSetTags = 0;
            let totalNumberOfRenderedTagSets = 0;
            const tenantTagSummaries = this.props.environmentSummary.MachineTenantTagSummaries;
            const tagSetComponents = this.props.tagSets
                .map((tagSet) => {
                    const tags = sortBy(
                        tagSet.Tags.filter((item) => this.onFilter(this.state.filterText, item)),
                        (t) => t.SortOrder
                    );

                    totalTagSetTags += tags.length;

                    if (tags.length === 0 || totalNumberOfRenderedTagSets >= this.state.numberOfTagsToRender) {
                        return null;
                    }

                    const numberOfTotalTagsThatWeAreStillAbleToRender = this.state.numberOfTagsToRender - totalNumberOfRenderedTagSets;
                    const numberOfTagsFromThisSetToRender = Math.min(numberOfTotalTagsThatWeAreStillAbleToRender, tags.length);
                    const tagsToRender = tags.slice(0, numberOfTagsFromThisSetToRender);

                    totalNumberOfRenderedTagSets += numberOfTagsFromThisSetToRender;

                    const tagSetRows = tagsToRender.map((tag) => {
                        const totalMachines = (tenantTagSummaries && tenantTagSummaries[tag.Id]) || 0;
                        return (
                            <div className={styles.tenantTagRowsContainer} key={tag.Name}>
                                <div className={styles.tenantTagName}>
                                    <Navigate
                                        render={(history) => (
                                            <Tag
                                                tagName={tag.Name}
                                                tagColor={tag.Color}
                                                key={tag.Name}
                                                description={tag.Description}
                                                onClick={() => history.navigate(routeLinks.infrastructure.machines.filtered({ tenantTags: tag.CanonicalTagName }))}
                                            />
                                        )}
                                    />
                                </div>
                                <div className={styles.tenantTagMachinesCount}>{totalMachines.toLocaleString()}</div>
                            </div>
                        );
                    });
                    return (
                        tags.length > 0 && (
                            <div className={styles.tenantTagRowsOuterContainer} key={tagSet.Id}>
                                <h5>{tagSet.Name}</h5>
                                {tagSetRows}
                            </div>
                        )
                    );
                })
                .filter((set) => set !== null);

            return (
                <div className={styles.card}>
                    <div className={styles.cardTitleContainer}>
                        <div className={styles.cardTitle}>
                            Tenant Tag Sets <span className={styles.cardCount}>({totalTagSetTags.toLocaleString()})</span>
                        </div>
                    </div>
                    <div className={styles.cardContentContainer}>
                        {(totalTagSetTags > 1 || this.state.filterText) && (
                            <div>
                                <FilterSearchBox
                                    placeholder="Filter..."
                                    onChange={(value) => this.setState({ filterText: value.toLowerCase(), numberOfTagsToRender: pageSize })}
                                    inputClassName={styles.dashboardFilterInputClassName}
                                    iconColor={theme.secondaryText}
                                    iconStyle={{ width: "20px", height: "20px", top: "14px" }}
                                />
                            </div>
                        )}
                        <div>{tagSetComponents}</div>
                        {this.state.numberOfTagsToRender <= totalTagSetTags && (
                            <ActionButton type={ActionButtonType.Ternary} onClick={() => this.setState({ ...this.state, numberOfTagsToRender: this.state.numberOfTagsToRender + pageSize })} label="Load more" />
                        )}
                    </div>
                </div>
            );
        });
    }

    private onFilter(filter: string, resource: TagResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }
}

const mapGlobalStateToProps = (state: GlobalState, props: TagSetsCardProps): GlobalConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled,
    };
};

export default connect(mapGlobalStateToProps)(TagSetsCard);
