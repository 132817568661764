import React from "react";

export const RenderDashboardOverviewCard = (resourceDefined: boolean, LoadingComponent: JSX.Element, LoadedComponent: JSX.Element, showIfEmpty: boolean, showBeforeLoad: boolean, cardIsEmpty: boolean | undefined): JSX.Element => {
    if (resourceDefined) {
        if (showIfEmpty) {
            return LoadedComponent;
        } else {
            return cardIsEmpty ? <></> : LoadedComponent;
        }
    } else {
        return showBeforeLoad ? LoadingComponent : <></>;
    }
};
