import { Summary, SummaryTemplateOptions } from "../Actions/StepUIAPI/Summary";

export function combineSummaries(prev: Summary, current: Summary): Summary {
    const separator = isEmptySummary(prev) || isEmptySummary(current) ? [] : [". "];

    return [...asSummaryArray(prev), ...separator, ...asSummaryArray(current)];
}

function isEmptySummary(summary: Summary): boolean {
    if (typeof summary === "string") {
        return summary === "";
    } else if (summary.length === 0) {
        return true;
    }
    return summary.every(isEmptySummaryOption);
}

function isEmptySummaryOption(summary: SummaryTemplateOptions) {
    if (typeof summary === "string") {
        return summary === "";
    }
    return isEmptySummary(summary.value);
}

function asSummaryArray(summary: Summary) {
    return typeof summary == "string" ? [summary] : summary;
}
