import { SensitiveValue as StepUISensitiveValue } from "components/Actions/StepUIAPI/SensitiveText";
import { SensitiveValue } from "client/resources";

export function toStepUISensitiveValue(sensitiveValue: SensitiveValue): StepUISensitiveValue {
    if (sensitiveValue.HasValue) {
        // Empty strings are evaluated as falsey, so make sure we check for null or undefined instead
        if (sensitiveValue.NewValue !== null && sensitiveValue.NewValue !== undefined) {
            return { type: "new", newValue: sensitiveValue.NewValue };
        }
        return { type: "existing" };
    }
    return { type: "empty" };
}

export function toResourceSensitiveValue(sensitiveValue: StepUISensitiveValue): SensitiveValue {
    if (sensitiveValue.type === "existing") {
        return { HasValue: true };
    } else if (sensitiveValue.type === "empty") {
        return { HasValue: false };
    }
    return { HasValue: true, NewValue: sensitiveValue.newValue };
}
