import * as React from "react";
import PaperLayout, { PaperLayoutProps } from "components/PaperLayout";
import { RunbooksPaperLayout } from "../../Runbooks/Layouts";
import { ProcessType } from "client/resources";

interface ProcessPaperLayoutProps extends PaperLayoutProps {
    processType: ProcessType;
}

export const ProcessPaperLayout: React.FC<ProcessPaperLayoutProps> = ({ disableAnimations = true, children, ...rest }) => {
    if (rest.processType === ProcessType.Runbook) {
        return (
            <RunbooksPaperLayout disableAnimations={disableAnimations} fullWidth={true} {...rest}>
                {children}
            </RunbooksPaperLayout>
        );
    } else {
        return (
            <PaperLayout disableAnimations={disableAnimations} fullWidth={true} {...rest}>
                {children}
            </PaperLayout>
        );
    }
};
