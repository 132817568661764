import React from "react";
import { UnstructuredStepResourceProperties } from "components/StepPackageEditor/Properties/UnstructuredStepResourceProperties";
import { StepPackageEditorPropertyAdapter } from "components/StepPackageEditor/StepPackageEditorPropertyAdapter";
import { AsStepUIProperties } from "components/StepPackageEditor/Properties/stepPropertiesMappedTypes";
import { StepPackage } from "components/StepPackageEditor/StepPackage/StepPackage";

export interface StepPackageEditorProps {
    stepPackage: StepPackage<UnstructuredStepPropertiesForStepUI>;
    properties: UnstructuredStepResourceProperties;
    setProperties(properties: UnstructuredStepResourceProperties): void;
    isExpandedByDefault: boolean;
}

export function StepPackageEditor(props: StepPackageEditorProps) {
    return <StepPackageEditorPropertyAdapter<UnstructuredStepPropertiesForStepUI> stepPackage={props.stepPackage} properties={props.properties} setProperties={props.setProperties} isExpandedByDefault={props.isExpandedByDefault} />;
}

type UnstructuredStepPropertiesForStepUI = AsStepUIProperties<UnstructuredStepResourceProperties>;
