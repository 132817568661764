import React from "react";
import ActionButton, { ActionButtonType } from "components/Button";
import { Note } from "components/form";
import BranchSwitcher from "../../BranchSwitcher/BranchSwitcher";
import { GitRef } from "client/resources/versionControlledResource";
import { useProjectContext } from "areas/projects/context";
import { HasVersionControlledPersistenceSettings, VcsBranchResource } from "client/resources";
import { DoBusyTask } from "components/DataBaseComponent";

interface Props {
    value?: GitRef;
    onChange: (branch: VcsBranchResource | undefined) => void;
    doBusyTask: DoBusyTask; // TODO: remove when a hooks implementation for loading indicators has been implemented
}

const GitRefFormSection: React.FC<Props> = ({ value, onChange, doBusyTask }) => {
    const projectContext = useProjectContext();
    const [gitRef, setGitRef] = React.useState(value);
    const [branches, setBranches] = React.useState<VcsBranchResource[]>([]);

    if (!projectContext || !projectContext.state || !projectContext.state.model) return <></>;

    const project = projectContext.state.model;

    const setValue = (value?: string) => {
        setGitRef(value);

        const branch = branches.find((b) => b.Name === value);
        onChange(branch);
    };

    if (!HasVersionControlledPersistenceSettings(project.PersistenceSettings)) throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
    const defaultBranch = project.PersistenceSettings.DefaultBranch;
    return (
        <>
            {/* TODO: This switcher is intended to be changed in the future to support commits/tags */}
            <BranchSwitcher key="gitRef" value={gitRef} onChange={setValue} onBranchItemsChange={setBranches} doBusyTask={doBusyTask} />
            <ActionButton label={`Reset to ${defaultBranch} (Default) Branch`} type={ActionButtonType.Ternary} disabled={gitRef === defaultBranch} onClick={() => setValue(defaultBranch)} />
            <Note>This release will snapshot the deployment process from the head of the branch selected.</Note>
        </>
    );
};

export default GitRefFormSection;
