import * as React from "react";
import { ActionButtonType } from "components/Button";
import TenantTagDesignDialogLayout from "./TenantTagDesignDialogLayout";
import OpenDialogButton from "components/Dialog/OpenDialogButton";

interface TenantFilterButtonProps {
    selectedTags?: string[];
    selectedTenants?: string[];
    availableTenants?: string[];
    showTenants: boolean;
    onUpdate(selectedTenants: string[], selectedTags: string[]): void;
}

class TenantFilterButton extends React.Component<TenantFilterButtonProps> {
    render() {
        return (
            <OpenDialogButton label="Tenant Preview" type={ActionButtonType.Ternary} wideDialog={true}>
                <TenantTagDesignDialogLayout
                    hideTenantsByName={!this.props.showTenants}
                    availableTenants={this.props.availableTenants}
                    selectedTenants={this.props.selectedTenants}
                    onUpdate={this.props.onUpdate}
                    selectedTags={this.props.selectedTags}
                />
            </OpenDialogButton>
        );
    }
}

export default TenantFilterButton;
