/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import ScopeSelector from "../ScopeSelector/ScopeSelector";
import { ScopeSpecification } from "areas/variables/ReadonlyVariableResource";
import { ScopeValues } from "client/resources/variableSetResource";
import VariableScope from "areas/variables/VariableScope/VariableScope";
import ReadonlyText from "components/ReadonlyText/ReadonlyText";
import { TagIndex } from "components/tenantTagsets";
import FocusActionsLayout from "components/FocusActionsLayout/FocusActionsLayout";
import { default as ActionButton, ActionButtonType } from "components/Button/ActionButton";
import VariableCell from "areas/variables/VariableCell/VariableCell";
import { DoBusyTask } from "components/DataBaseComponent/DataBaseComponent";
import { VariableType } from "../../../client/resources/variableResource";
import { FocusableComponent } from "components/VirtualListWithKeyboard/FocusableComponent";
const keycode = require("keycode");
const styles = require("./style.less");

interface VariableScopeCellProps {
    scope: ScopeSpecification;
    onScopeChanged: (scope: ScopeSpecification) => void;
    availableScopes: ScopeValues;
    tagIndex: TagIndex;
    variableType: VariableType;
    deleted?: boolean;
    isEditing: boolean;
    isViewingAll: boolean;
    doBusyTask: DoBusyTask;
    onFocusEdit: () => void;
    onBlurEdit: () => void;
    onFocusViewAll: () => void;
    onBlurViewAll: () => void;
    onOpenEditorClicked: () => void;
    cellHeight: number;
    containerWidth: number | undefined;
}

export default class VariableScopeCell extends React.Component<VariableScopeCellProps, {}> {
    private scopeCell: HTMLElement | null = undefined!;
    private tabbingElement: HTMLElement | null = undefined!;
    private focusInputAfterNextUpdate: boolean = false;
    private focusTabbingDivAfterNextUpdate: boolean = false;
    private readonly onBlurEdit: () => void;
    private firstInput: FocusableComponent | null | undefined;

    constructor(props: VariableScopeCellProps) {
        super(props);
        this.onBlurEdit = () => this.props.onBlurEdit();
    }

    componentDidMount() {
        document.addEventListener("keydown", this.onKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown);
    }

    componentWillReceiveProps(nextProps: VariableScopeCellProps) {
        if (nextProps.isEditing && !this.props.isEditing) {
            this.focusInputAfterNextUpdate = true;
        }
        if (nextProps.isViewingAll && !this.props.isViewingAll) {
            this.focusTabbingDivAfterNextUpdate = true;
        }
    }

    componentDidUpdate() {
        if (this.focusInputAfterNextUpdate) {
            if (this.firstInput) {
                this.firstInput.focus();
            }
            this.focusInputAfterNextUpdate = false;
        }
        if (this.focusTabbingDivAfterNextUpdate) {
            if (this.tabbingElement) {
                this.tabbingElement.focus();
            }
            this.focusTabbingDivAfterNextUpdate = false;
        }
    }

    render() {
        return (
            <div className={styles.scopeCell} ref={(cell) => (this.scopeCell = cell)}>
                {!this.props.isEditing && <div ref={(tabbingElement) => (this.tabbingElement = tabbingElement)} tabIndex={this.props.deleted ? -1 : 0} onFocus={this.props.onFocusViewAll} />}
                {/* Using a Text component here for the empty content to ensure consistent styling with other cells*/}
                {!this.props.isEditing && this.props.containerWidth && (
                    <VariableScope
                        scope={this.props.scope}
                        availableScopes={this.props.availableScopes}
                        tagIndex={this.props.tagIndex}
                        minHeight={this.props.cellHeight}
                        isFocused={this.props.isViewingAll}
                        showClickIndicator={!this.props.deleted}
                        onFocus={() => this.props.onFocusViewAll()}
                        onBlur={() => this.props.onBlurViewAll()}
                        onClick={!this.props.deleted ? this.props.onFocusEdit : undefined}
                        emptyContent={<ReadonlyText text={this.props.deleted ? "" : "Define scope"} className={styles.defineScope} monoSpacedFont={true} />}
                        containerWidth={this.props.containerWidth}
                    />
                )}
                {/* if "Define Scope" <Text /> has disabled=true, click events don't fire in FireFox or Edge*/}
                {this.props.isEditing && (
                    <FocusActionsLayout
                        isFocused={this.props.isEditing}
                        position={{ left: 0, top: 0, right: 0 }}
                        actions={[<ActionButton key="openEditor" tabIndex={-1} type={ActionButtonType.Ternary} label="Open Editor" onClick={this.props.onOpenEditorClicked} />]}
                        onClickOutside={this.props.isEditing ? this.onBlurEdit : undefined}
                    >
                        <VariableCell>
                            {this.props.isEditing && (
                                <ScopeSelector
                                    firstInputRef={(firstInput) => (this.firstInput = firstInput)}
                                    value={this.props.scope}
                                    availableScopes={this.props.availableScopes}
                                    variableType={this.props.variableType}
                                    onScopeSelected={this.props.onScopeChanged}
                                    doBusyTask={this.props.doBusyTask}
                                    allowTenantTagSelection={false}
                                    useCompactControls={true}
                                />
                            )}
                        </VariableCell>
                    </FocusActionsLayout>
                )}
            </div>
        );
    }

    private onKeyDown = (ev: KeyboardEvent) => {
        const code = keycode(ev);
        if (code === "enter" && this.props.isViewingAll) {
            this.props.onFocusEdit();
        }
        if (code === "esc" && !ev.defaultPrevented && this.props.isEditing) {
            this.props.onFocusViewAll();
        }
    };
}
