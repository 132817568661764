/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-eq-null */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import Resolver, { RouteArgs } from "./resolver";
import Logger from "./logger";
import Ajax, { AjaxRequestDetails, AjaxResponseDetails, AjaxErrorResponseDetails } from "./ajax";
import RootResource, { GlobalRootLinks } from "./resources/rootResource";
import { SpaceRootResource, OctopusError } from "./resources";
import Environment from "environment";
import { IWrappedResource } from "./resources/commitResource";
import { isRunningInAFunctionalTest } from "utils/isRunningInAFunctionalTest";
import { SpaceRootLinks } from "./resources/spaceRootResource";

const apiLocation = "~/api";

export interface ClientConfiguration {
    serverEndpoint: string;
}

export interface ClientRequestDetails {
    correlationId: number;
    url: string;
    method: string;
}

export interface ClientResponseDetails {
    correlationId: number;
    url: string;
    method: string;
    statusCode: number;
}

export interface ClientErrorResponseDetails extends ClientResponseDetails {
    errorMessage: string;
    errors: string[];
}

export type GlobalAndSpaceRootLinks = keyof GlobalRootLinks | keyof SpaceRootLinks;

// The Octopus Client implements the low-level semantics of the Octopus Deploy REST API
class Client {
    public static Create(configuration: ClientConfiguration) {
        Logger.log("Creating Octopus client for endpoint: " + configuration.serverEndpoint);

        const resolver = new Resolver(configuration.serverEndpoint);
        return new Client(resolver, null, null, null);
    }

    onRequestCallback: (details: ClientRequestDetails) => void = undefined!;
    onResponseCallback: (details: ClientResponseDetails) => void = undefined!;
    onErrorResponseCallback: (details: ClientErrorResponseDetails) => void = undefined!;

    private constructor(private readonly resolver: Resolver, private rootDocument: RootResource | null, public spaceId: string | null, private spaceRootDocument: SpaceRootResource | null) {
        this.resolver = resolver;
        this.rootDocument = rootDocument;
        this.spaceRootDocument = spaceRootDocument;
    }

    setOnRequestCallback = (callback: (details: ClientRequestDetails) => void) => {
        this.onRequestCallback = callback;
    };

    setOnResponseCallback = (callback: (details: ClientResponseDetails) => void) => {
        this.onResponseCallback = callback;
    };

    setOnErrorResponseCallback = (callback: (details: ClientErrorResponseDetails) => void) => {
        this.onErrorResponseCallback = callback;
    };

    resolve = (path: string, uriTemplateParameters?: RouteArgs) => this.resolver.resolve(path, uriTemplateParameters);

    connect(progressCallback: (message: string, error?: OctopusError) => void): Promise<void> {
        progressCallback("Checking your credentials. Please wait...");

        return new Promise((resolve, reject) => {
            if (this.rootDocument) {
                resolve();
                return;
            }

            const attempt = (success: any, fail: any) => {
                this.get(apiLocation).then((root) => {
                    success(root);
                }, fail);
            };

            const onSuccess = (root: RootResource) => {
                this.rootDocument = root;
                resolve();
            };

            let fails = 0;
            const onFail = (err: any) => {
                if (err.StatusCode !== 503 && fails < 20) {
                    fails++;
                }

                const timeout = fails === 20 ? 5000 : 1000;

                if ((err.StatusCode === 0 || err.StatusCode === 503) && fails < 20) {
                    if (err.StatusCode === 503) {
                        progressCallback("Octopus Server unavailable.", err);
                    } else if (err.StatusCode === 0) {
                        progressCallback("The Octopus Server does not appear to have started, trying again...", err);
                    }
                } else {
                    progressCallback("Unable to connect to the Octopus Server. Is your server online?", err);
                }
                setTimeout(() => {
                    attempt(onSuccess, onFail);
                }, timeout);
            };

            attempt(onSuccess, onFail);
        });
    }

    disconnect() {
        this.rootDocument = null;
        this.spaceId = null;
        this.spaceRootDocument = null;
    }

    async forSpace(spaceId: string): Promise<Client> {
        const spaceRootResource = await this.get<SpaceRootResource>(this.rootDocument!.Links["SpaceHome"], { spaceId });
        return new Client(this.resolver, this.rootDocument, spaceId, spaceRootResource);
    }

    forSystem(): Client {
        return new Client(this.resolver, this.rootDocument, null, null);
    }

    async switchToSpace(spaceId: string): Promise<void> {
        this.spaceId = spaceId;
        this.spaceRootDocument = await this.get<SpaceRootResource>(this.rootDocument!.Links["SpaceHome"], { spaceId: this.spaceId });
    }

    switchToSystem(): void {
        this.spaceId = null;
        this.spaceRootDocument = null;
    }

    get<TResource>(path: string, args?: RouteArgs): Promise<TResource> {
        const url = this.resolveUrlWithSpaceId(path, args);
        return this.dispatchRequest("GET", url) as Promise<TResource>;
    }

    getRaw(path: string, args?: RouteArgs): Promise<string> {
        const url = this.resolve(path, args);

        return new Promise((resolve, reject) => {
            new Ajax({
                method: "GET",
                error: (e) => reject(e),
                url,
                raw: true,
                success: (data) => resolve(data),
                tryGetServerInformation: () => this.tryGetServerInformation()!,
                getAntiForgeryTokenCallback: () => this.getAntiforgeryToken()!,
                onRequestCallback: (r) => this.onAjaxRequest(r),
                onResponseCallback: (r) => this.onAjaxResponse(r),
                onErrorResponseCallback: (r) => this.onAjaxErrorResponse(r),
            }).execute();
        });
    }

    onAjaxRequest(ajaxDetails: AjaxRequestDetails) {
        if (this.onRequestCallback) {
            const details = {
                correlationId: ajaxDetails.correlationId,
                url: ajaxDetails.url,
                method: ajaxDetails.method,
            };
            this.onRequestCallback(details);
        }
    }

    onAjaxResponse(ajaxDetails: AjaxResponseDetails) {
        if (this.onResponseCallback) {
            const details = {
                correlationId: ajaxDetails.correlationId,
                url: ajaxDetails.url,
                method: ajaxDetails.method,
                statusCode: ajaxDetails.statusCode,
            };
            this.onResponseCallback(details);
        }
    }

    onAjaxErrorResponse(ajaxDetails: AjaxErrorResponseDetails) {
        if (this.onErrorResponseCallback) {
            const details = {
                correlationId: ajaxDetails.correlationId,
                url: ajaxDetails.url,
                method: ajaxDetails.method,
                statusCode: ajaxDetails.statusCode,
                errorMessage: ajaxDetails.errorMessage,
                errors: ajaxDetails.errors,
            };
            this.onErrorResponseCallback(details);
        }
    }

    post<TReturn>(path: string, resource?: any, args?: RouteArgs): Promise<TReturn> {
        const url = this.resolveUrlWithSpaceId(path, args);
        return this.dispatchRequest("POST", url, resource) as Promise<TReturn>;
    }

    create<TNewResource, TResource>(path: string, resource: TNewResource, args: RouteArgs): Promise<TResource> {
        const url = this.resolve(path, args);

        return new Promise((resolve, reject) => {
            this.dispatchRequest("POST", url, resource).then((result: any) => {
                this.dispatchRequest("GET", this.resolve(result.Links.Self)).then((result2) => {
                    resolve(result2 as TResource);
                }, reject);
            }, reject);
        });
    }

    update<TResource>(path: string, resource: TResource | IWrappedResource<TResource>, args?: RouteArgs): Promise<TResource> {
        const url = this.resolve(path, args);

        return new Promise((resolve, reject) => {
            this.dispatchRequest("PUT", url, resource).then(() => {
                this.dispatchRequest("GET", url).then((result2) => {
                    resolve(result2 as TResource);
                }, reject);
            }, reject);
        });
    }

    del(path: string, resource?: any, args?: RouteArgs) {
        const url = this.resolve(path, args);
        return this.dispatchRequest("DELETE", url, resource);
    }

    put<TResource>(path: string, resource?: TResource, args?: RouteArgs): Promise<TResource> {
        const url = this.resolveUrlWithSpaceId(path, args);
        return this.dispatchRequest("PUT", url, resource) as Promise<TResource>;
    }

    getAntiforgeryToken() {
        if (!this.isConnected()) {
            return null;
        }

        const installationId = this.getGlobalRootDocument()!.InstallationId;
        if (!installationId) {
            return null;
        }

        // If we have come this far we know we are on a version of Octopus Server which supports anti-forgery tokens
        const antiforgeryCookieName = "Octopus-Csrf-Token_" + installationId;
        const antiforgeryCookies = document.cookie
            .split(";")
            .filter((c) => {
                return c.trim().indexOf(antiforgeryCookieName) === 0;
            })
            .map((c) => {
                return c.trim();
            });

        if (antiforgeryCookies && antiforgeryCookies.length === 1) {
            const antiforgeryToken = antiforgeryCookies[0].split("=")[1];
            return antiforgeryToken;
        } else {
            if (Environment.isInDevelopmentMode()) {
                return "FAKE TOKEN USED FOR DEVELOPMENT";
            }
            return null;
        }
    }

    resolveLinkTemplate(link: GlobalAndSpaceRootLinks, args: any) {
        return this.resolve(this.getLink(link), args);
    }

    getServerInformation() {
        if (!this.isConnected()) {
            throw new Error("The Octopus Client has not connected. THIS SHOULD NOT HAPPEN! Please notify support.");
        }
        return {
            version: this.rootDocument!.Version,
            isEarlyAccessProgram: this.rootDocument!.IsEarlyAccessProgram,
        };
    }

    tryGetServerInformation() {
        return this.rootDocument
            ? {
                  version: this.rootDocument.Version,
                  isEarlyAccessProgram: this.rootDocument.IsEarlyAccessProgram,
                  installationId: this.rootDocument.InstallationId,
              }
            : null;
    }

    throwIfClientNotConnected() {
        if (!this.isConnected()) {
            const extraContextForFunctionalTests =
                " In your Functional Test, use `setupGlobals.connectClient()` to connect the client, or call `setupGlobals.all(...)`." +
                " For more information, see https://github.com/OctopusDeploy/OctopusDeploy/blob/master/newportal/docs/functional_tests.md#globalambient-context";
            const errorMessage = `Can't get the link for ${name} from the client, because the client has not yet been connected.${isRunningInAFunctionalTest() ? extraContextForFunctionalTests : ""}`;
            throw new Error(errorMessage);
        }
    }

    getSystemLink<T>(linkGetter: (links: GlobalRootLinks) => T): T {
        this.throwIfClientNotConnected();
        const link = linkGetter(this.rootDocument!.Links);
        if (link === null) {
            const errorMessage = `Can't get the link for ${name} from the client, because it could not be found in the root document.`;
            throw new Error(errorMessage);
        }
        return link;
    }

    getLink(name: GlobalAndSpaceRootLinks): string {
        this.throwIfClientNotConnected();
        const spaceLinkExists = this.spaceRootDocument && this.spaceRootDocument.Links[name];
        const link = spaceLinkExists ? this.spaceRootDocument!.Links[name] : this.rootDocument!.Links[name];
        if (!link) {
            const extraContextForFunctionalTests =
                " It is likely that you are trying to retrieve a space-scoped link, but no space context has been configured yet." +
                " In your Functional Test, use `setupGlobals.setupSpaceContext(space)` to set up the client's space context, or call `setupGlobals.all(space)`." +
                " For more information, see https://github.com/OctopusDeploy/OctopusDeploy/blob/master/newportal/docs/functional_tests.md#globalambient-context";
            const errorMessage = `Can't get the link for ${name} from the client, because it could not be found in the root document or the space root document.${isRunningInAFunctionalTest() ? extraContextForFunctionalTests : ""}`;
            throw new Error(errorMessage);
        }
        return link;
    }

    private dispatchRequest(method: any, url: string, requestBody?: any) {
        return new Promise((resolve, reject) => {
            new Ajax({
                error: (e) => reject(e),
                method,
                url,
                requestBody,
                success: (data) => resolve(data),
                tryGetServerInformation: () => this.tryGetServerInformation()!,
                getAntiForgeryTokenCallback: () => this.getAntiforgeryToken()!,
                onRequestCallback: (r) => this.onAjaxRequest(r),
                onResponseCallback: (r) => this.onAjaxResponse(r),
                onErrorResponseCallback: (r) => this.onAjaxErrorResponse(r),
            }).execute();
        });
    }

    isConnected() {
        return this.rootDocument !== null;
    }

    private getArgsWithSpaceId(args: RouteArgs) {
        return this.spaceId ? { spaceId: this.spaceId, ...args } : args;
    }

    private getGlobalRootDocument() {
        if (!this.isConnected()) {
            throw new Error("The Octopus Client has not connected.");
        }

        return this.rootDocument;
    }

    resolveUrlWithSpaceId(path: string, args?: RouteArgs): string {
        return this.resolve(path, this.getArgsWithSpaceId(args!));
    }
}

export default Client;
