/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import Onboarding from "./Onboarding";
import * as React from "react";
import { repository } from "clientInstance";
import ProjectDashboard from "../ProjectDashboard";
import DashboardDataSource from "areas/projects/components/DashboardDataSource";
import { ProjectResource } from "client/resources";
import OverviewFilters from "./OverviewFilters";
import { DashboardFilters, DimensionTypes } from "areas/projects/components/DashboardDataSource/DataCube";
import { filtersFromQueryString, queryStringFromFilters } from "./queryStringFilters";
import PaperLayout from "components/PaperLayout";
import TenantResource from "client/resources/tenantResource";
import { DashboardDataSourceState, RenderDashboardProps } from "../DashboardDataSource/DashboardDataSource";
import { TenantedDeploymentMode } from "../../../../client/resources/tenantedDeploymentMode";
import { RouteComponentProps } from "react-router";
import { ProjectRouteParams } from "areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import { Callout, CalloutType } from "primitiveComponents/dataDisplay/Callout/Callout";
import Permission from "client/resources/permission";
import PermissionCheck, { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { DataBaseComponent, DataBaseComponentState } from "../../../../components/DataBaseComponent/DataBaseComponent";
import { NavigationButton, NavigationButtonType } from "components/Button";
import ActionList from "components/ActionList/ActionList";
import routeLinks from "routeLinks";
import { OverflowMenu, OverflowMenuItems } from "components/Menu";
import { PerformanceConfigurationResource, DashboardRenderMode } from "client/resources/performanceConfigurationResource";
import { OverflowMenuGenericItem } from "components/Menu/OverflowMenu";
import { RecentProjects } from "utils/RecentProjects/RecentProjects";
import { WithProjectContextInjectedProps } from "areas/projects/context/withProjectContext";
import { Action, ActionEvent, AnalyticActionDispatcher, AnalyticView, useAnalyticActionDispatch, ViewEvent } from "analytics/Analytics";
import { useProjectContext } from "areas/projects/context/ProjectContext";

interface OverviewState extends DataBaseComponentState {
    project: ProjectResource;
    filters: DashboardFilters;
    tenants: TenantResource[];
    hasSteps: boolean;
    failedChecks: Array<{ permission: Permission; isNotAllowed: boolean }>;
    dashboardRenderMode: DashboardRenderMode;
}

type DeploymentsOverviewProps = RouteComponentProps<ProjectRouteParams>;

interface DeploymentsOverviewPropsInternal extends DeploymentsOverviewProps, WithProjectContextInjectedProps {
    dispatchAction: AnalyticActionDispatcher;
}

class DeploymentsOverviewInternal extends DataBaseComponent<DeploymentsOverviewPropsInternal, OverviewState> {
    constructor(props: DeploymentsOverviewPropsInternal) {
        super(props);
        this.state = {
            hasSteps: false,
            project: null!,
            tenants: [],
            filters: {},
            failedChecks: [],
            dashboardRenderMode: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const tenants = await repository.Tenants.all();
            const { model: project, projectContextRepository } = this.props.projectContext.state;
            const performanceConfiguration = await repository.PerformanceConfiguration.get();
            await RecentProjects.getInstance().UpdateAccessedProjectIntoLocalStorage(project.Id);

            const requiredPermissions = [
                { permission: Permission.ProjectView, project: project.Id, tenant: "*", projectGroup: project.ProjectGroupId },
                { permission: Permission.ReleaseView, project: project.Id, tenant: "*", projectGroup: project.ProjectGroupId },
                { permission: Permission.EnvironmentView, wildcard: true },
            ];

            const failedChecks = requiredPermissions
                .map((check) => ({
                    permission: check.permission,
                    isNotAllowed: !isAllowed(check),
                }))
                .filter((check) => check.isNotAllowed);

            if (failedChecks.length > 0) {
                this.setState({
                    project,
                    failedChecks,
                });

                return;
            }

            const deploymentProcess = await projectContextRepository.DeploymentProcesses.get();

            this.setState({
                project,
                tenants,
                filters: {
                    ...this.createDefaultFilter(project),
                    ...filtersFromQueryString(this.props.location.search),
                },
                hasSteps: deploymentProcess.Steps.length > 0,
                dashboardRenderMode: this.getDashboardRenderMode(project, performanceConfiguration),
            });
        });
    }

    shouldComponentUpdate(nextProps: RouteComponentProps<ProjectRouteParams>, nextState: OverviewState) {
        if (nextState.failedChecks !== this.state.failedChecks) {
            return true;
        }

        if (nextState.hasSteps !== this.state.hasSteps) {
            return true;
        }

        if (nextState.project !== this.state.project) {
            return true;
        }

        if (nextState.tenants !== this.state.tenants) {
            return true;
        }

        if (nextState.filters !== this.state.filters) {
            return true;
        }

        if (nextState.dashboardRenderMode !== this.state.dashboardRenderMode) {
            return true;
        }

        return false;
    }

    render() {
        if (!this.state.project) {
            return <PaperLayout busy={true} errors={this.errors} />;
        }

        if (this.state.failedChecks.length > 0) {
            return (
                <PaperLayout busy={this.state.busy} errors={this.errors} title="Deployments">
                    <Callout type={CalloutType.Information} title={"Permission required"}>
                        The {this.state.failedChecks[0].permission} permission is required to view project overview details
                    </Callout>
                </PaperLayout>
            );
        }

        if (this.state.project && !this.state.hasSteps) {
            const actions: JSX.Element[] = [
                <PermissionCheck permission={Permission.ProcessEdit} project={this.state.project.Id} tenant="*">
                    <NavigationButton
                        label="Define your deployment process"
                        href={routeLinks.project(this.state.project).deployments.process.root}
                        type={NavigationButtonType.Primary}
                        onClick={() => {
                            const ev: ActionEvent = {
                                action: Action.Add,
                                resource: "Deployment Process",
                            };

                            this.props.dispatchAction("Define your Deployment Process", ev);
                        }}
                    />
                </PermissionCheck>,
            ];
            const actionSection = <ActionList actions={actions} />;

            const vw: ViewEvent = {
                resource: "Project",
            };

            return (
                <PaperLayout busy={this.state.busy} errors={this.errors} title="Deployments" sectionControl={actionSection}>
                    <Onboarding project={this.state.project} />
                    <AnalyticView resource="Project" />
                </PaperLayout>
            );
        }

        return <DashboardDataSource project={this.state.project} filters={this.state.filters} render={this.renderDashboard} dashboardRenderMode={this.state.dashboardRenderMode} />;
    }

    private showLoadingDashboard(dataSource: DashboardDataSourceState) {
        return <ProjectDashboard cube={dataSource.cube!} filters={this.state.filters} allowDeployments={true} flatStyle={true} dashboardRenderMode={this.state.dashboardRenderMode} />;
    }

    private localStorageKeyForVirtualDashboard(project: ProjectResource): string {
        return "virtualDashboard" + project.Id;
    }

    private getDashboardRenderMode(project: ProjectResource, performanceConfiguration: PerformanceConfigurationResource): DashboardRenderMode {
        const localRenderMode = localStorage.getItem(this.localStorageKeyForVirtualDashboard(project));
        if (localRenderMode !== null) {
            const typedRenderModeString = localRenderMode as keyof typeof DashboardRenderMode;
            return DashboardRenderMode[typedRenderModeString];
        }

        return performanceConfiguration.DefaultDashboardRenderMode;
    }

    private setDashboardRenderMode(value: DashboardRenderMode) {
        localStorage.setItem(this.localStorageKeyForVirtualDashboard(this.state.project), value.toString());
        this.setState({ dashboardRenderMode: value });
    }

    private filtersUpdated = (filters: DashboardFilters) => {
        const newQS = queryStringFromFilters(filters);
        this.setState({ filters }, () => {
            if (this.props.location.search !== newQS) {
                const location = { ...this.props.history, search: newQS };
                this.props.history.replace(location);
            }
        });
    };

    private renderDashboard = (dataSource: RenderDashboardProps) => {
        const auditTrailButton = OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsForProject(this.state.project.Id), null!, {
            permission: Permission.EventView,
            wildcard: true,
        });

        const overflowMenuItems = [this.getDashboardRenderModeOverflowMenuItem(), auditTrailButton];
        return (
            <PaperLayout
                title="Overview"
                busy={Promise.all([this.state.busy, dataSource.busy])}
                errors={dataSource.errors}
                sectionControl={<OverflowMenu menuItems={overflowMenuItems} />}
                disableHeaderAnimations={true} // Disabled because of the way the ProjectOverview renders multiple PaperLayouts.
                fullWidth={true}
            >
                {dataSource.hasInitialLoaded && dataSource.cube && (
                    <OverviewFilters
                        cube={dataSource.cube}
                        tenants={this.state.tenants}
                        filters={this.state.filters}
                        doBusyTask={this.doBusyTask}
                        defaultFilter={this.createDefaultFilter(this.state.project)}
                        onFiltersUpdated={this.filtersUpdated}
                        project={this.state.project}
                        render={() => this.showLoadingDashboard(dataSource)}
                    />
                )}
            </PaperLayout>
        );
    };

    private getDashboardRenderModeOverflowMenuItem = (): OverflowMenuGenericItem => {
        const text = this.state.dashboardRenderMode === DashboardRenderMode.VirtualizeColumns ? "Switch to fast rendering" : "Switch to full rendering";

        const oppositeSetting = this.state.dashboardRenderMode === DashboardRenderMode.VirtualizeColumns ? DashboardRenderMode.VirtualizeRowsAndColumns : DashboardRenderMode.VirtualizeColumns;

        return OverflowMenuItems.item(text, () => this.setDashboardRenderMode(oppositeSetting), null!, { text: "Full rendering will render all rows, fast rendering will render only visible rows" });
    };

    private createDefaultFilter = (project: ProjectResource): DashboardFilters => {
        return {
            [DimensionTypes.Project]: {
                [project.Id]: true,
            },
            columnDimension: DimensionTypes.Environment,
            groupBy: project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted ? DimensionTypes.Channel : DimensionTypes.None,
            rowDimension: project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted ? DimensionTypes.Release : DimensionTypes.Tenant,
        };
    };
}

export function DeploymentsOverview(props: DeploymentsOverviewProps) {
    const projectContext = useProjectContext();
    const dispatchAction = useAnalyticActionDispatch();

    return <DeploymentsOverviewInternal {...props} projectContext={projectContext} dispatchAction={dispatchAction} />;
}
