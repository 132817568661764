/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { isEqual } from "lodash";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import URI from "urijs";

export interface IQuery {
    [key: string]: string[] | string | undefined;
}

interface QueryStringFiltersOwnProps<TFilter, TQuery> {
    filter: TFilter;
    getQuery: (filter: TFilter) => TQuery;
    getFilter: (query: TQuery) => TFilter;
    onFilterChange: (filter: TFilter) => void;
    onQueryStringChange?: (query: TQuery) => void;
}

export type QueryStringFiltersProps<TFilter, TQuery, TRouteProps = {}> = QueryStringFiltersOwnProps<TFilter, TQuery> & RouteComponentProps<TRouteProps>;

class QueryStringFiltersInternal<TFilter extends object, TQuery extends IQuery> extends React.Component<QueryStringFiltersProps<TFilter, TQuery>> {
    constructor(props: QueryStringFiltersProps<TFilter, TQuery>) {
        super(props);

        if (props.location.search) {
            this.updateFiltersFromQueryString(props.location.search);
        }
    }

    public componentWillReceiveProps(props: QueryStringFiltersProps<TFilter, TQuery>) {
        if (!isEqual(props.filter, this.props.filter)) {
            this.updateQueryStringFromFilters(props.filter);
            return;
        }

        if (props.location.search !== this.props.location.search) {
            this.updateFiltersFromQueryString(props.location.search);
        }
    }

    public render() {
        return null;
    }

    private updateQueryStringFromFilters(filter: TFilter) {
        const query = this.props.getQuery(filter);
        const queryString = convertQueryToQueryString(query);

        if (this.props.location.search !== queryString) {
            const location = { ...this.props.history, search: queryString };
            this.props.history.replace(location);
            if (this.props.onQueryStringChange) {
                this.props.onQueryStringChange(query);
            }
        }
    }

    private updateFiltersFromQueryString(queryString: string) {
        const query = URI(queryString).search(true);
        const filter = this.props.getFilter(query);
        if (!isEqual(filter, this.props.filter)) {
            this.props.onFilterChange(filter);
        }
    }
}

export function convertQueryToQueryString<TQuery extends IQuery>(query: TQuery): string {
    const normalisedQuery = normaliseQuery(query);
    return new URI().search(normalisedQuery as object).search();
}

export function normaliseQuery<TQuery extends IQuery>(query: TQuery): { [key in keyof TQuery]?: string[] | string | undefined } {
    const normalisedQuery: { [key in keyof TQuery]?: string[] | string | undefined } = {};
    for (const key in query) {
        if (!query.hasOwnProperty(key)) {
            continue;
        }

        const value = query[key];

        normalisedQuery[key] = value instanceof Array ? (value.length ? value : undefined) : value || undefined;
    }

    return normalisedQuery;
}

export class QueryStringFilters {
    static For<TFilter extends object, TQuery extends IQuery>() {
        class QueryStringFiltersFor extends QueryStringFiltersInternal<TFilter, TQuery> {}
        return withRouter(QueryStringFiltersFor);
    }
}
