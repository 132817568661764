import * as React from "react";
import pluginRegistry, { ActionEditProps } from "components/Actions/pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { ActionSummaryProps } from "components/Actions/actionSummaryProps";
import { ActionExecutionLocation } from "client/resources";
import Roles from "components/Actions/Roles";
import { TargetRoles } from "areas/projects/components/Process/types";
import TerraformActionEdit, { TerraformProperties } from "./terraformAction";

class TerraformPlanDestroyActionSummary extends BaseComponent<ActionSummaryProps> {
    render() {
        return (
            <div>
                Plan the destruction of Terraform resources
                {this.props.targetRolesAsCSV && (
                    <span>
                        {" "}
                        on behalf of targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} />
                    </span>
                )}
            </div>
        );
    }
}

class TerraformApplyActionEdit extends BaseComponent<ActionEditProps<TerraformProperties>, never> {
    render() {
        return (
            <TerraformActionEdit
                projectId={this.props.projectId}
                properties={this.props.properties}
                packages={this.props.packages}
                setProperties={this.props.setProperties}
                setPackages={this.props.setPackages}
                plugin={this.props.plugin}
                errors={this.props.errors}
                getFieldError={this.props.getFieldError}
                doBusyTask={this.props.doBusyTask}
                busy={this.props.busy}
                actionName={"plan -destroy"}
                expandedByDefault={this.props.expandedByDefault}
                addtionalParametersLink="TerraformPlanDestroy"
            />
        );
    }
}

pluginRegistry.registerActionForAllScopes({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.TerraformPlanDestroy",
    summary: (properties, targetRolesAsCSV) => <TerraformPlanDestroyActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    canHaveChildren: (step) => true,
    canBeChild: true,
    edit: TerraformApplyActionEdit,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => false,
});
