import Permission from "client/resources/permission";
import { client } from "clientInstance";
import { pageId } from "utils/pageId";
import infrastructureRouteLinks from "./infrastructureRouteLinks";

const infrastructureLinks = infrastructureRouteLinks(client.spaceId ?? "");

export default () => {
    function workerMachineLinks() {
        return {
            root: pageId({ area: "Infrastructure", id: "Infrastructure.Worker", name: "Worker", routeLink: null }),
            settings: pageId({ area: "Infrastructure", id: "Infrastructure.WorkerSettings", name: "Worker Settings", routeLink: null }),
            connection: pageId({ area: "Infrastructure", id: "Infrastructure.WorkerConnectivity", name: "Worker Connectivity", routeLink: null }),
            events: pageId({ area: "Infrastructure", id: "Infrastructure.WorkerEvents", name: "Worker Events", routeLink: null }),
        };
    }

    const deploymentTargetLinks = () => ({
        root: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTarget", name: "Deployment Target", routeLink: null }),
        settings: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargetSettings", name: "Deployment Target Settings", routeLink: null }),
        deployments: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargetDeployments", name: "Deployment Target Deployments", routeLink: null }),
        connection: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargetConnectivity", name: "Deployment Target Connectivity", routeLink: null }),
        events: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargetEvents", name: "Deployment Target Events", routeLink: null }),
    });

    return {
        root: pageId({
            area: "Infrastructure",
            id: "Infrastructure",
            name: "Infrastructure",
            routeLink: infrastructureLinks.root,
            routePermission: {
                permission: [Permission.MachineView, Permission.EnvironmentView, Permission.WorkerView],
                wildcard: true,
            },
        }),
        overview: pageId({
            area: "Infrastructure",
            id: "Infrastructure.Overview",
            name: "Overview",
            routeLink: infrastructureLinks.overview,
            routePermission: { permission: [Permission.EnvironmentView, Permission.MachineView, Permission.WorkerView], wildcard: true },
        }),
        environments: {
            root: pageId({
                area: "Infrastructure",
                id: "Infrastructure.Environments",
                name: "Environments",
                routeLink: infrastructureLinks.environments.root,
                routePermission: { permission: [Permission.EnvironmentView, Permission.MachineView], wildcard: true },
            }),
            create: pageId({
                area: "Infrastructure",
                id: "Infrastructure.EnvironmentsNew",
                name: "Add New Environment",
                routeLink: infrastructureLinks.environments.create,
                routePermission: { permission: Permission.EnvironmentCreate, wildcard: true },
            }),
            filtered: pageId({ area: "Infrastructure", id: "Infrastructure.EnvironmentsFiltered", name: "Filtered Environments", routeLink: null }),
        },
        environment: pageId({ area: "Infrastructure", id: "Infrastructure.Environment", name: "Environment", routeLink: null }),
        machines: {
            root: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargets", name: "Deployment Targets", routeLink: infrastructureLinks.machines.root, routePermission: { permission: [Permission.MachineView], wildcard: true } }),
            select: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargetsSelect", name: "Add New Deployment Target Selection", routeLink: null }),
            create: pageId({
                area: "Infrastructure (Windows, Linux, Mac, Azure, Listening Tentacle, Polling Tentacle, SSH Connection, Offline Package Drop, Azure Web App, Azure Cloud Service, Service Fabric Cluster, Kubernetes (K8s) Cluster, Cloud Region)",
                id: "Infrastructure.DeploymentTargetsNew",
                name: "Add New Deployment Target",
                routeLink: infrastructureLinks.machines.new(undefined),
                routePermission: {
                    permission: Permission.MachineCreate,
                    wildcard: true,
                },
            }),
            filtered: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargetsFiltered", name: "Filtered Deployment Targets", routeLink: null }),
            discover: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargetsDiscover", name: "Discover Deployment Target", routeLink: null }),
        },
        machine: {
            root: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTarget", name: "Deployment Target", routeLink: null }),
            settings: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargetSettings", name: "Deployment Target Settings", routeLink: null }),
            deployments: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargetDeployments", name: "Deployment Target Deployments", routeLink: null }),
            connection: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargetConnectivity", name: "Deployment Target Connectivity", routeLink: null }),
            events: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargetEvents", name: "Deployment Target Events", routeLink: null }),
        },
        deploymentTarget: {
            ...deploymentTargetLinks(),
        },
        proxies: {
            root: pageId({ area: "Infrastructure", id: "Infrastructure.Proxies", name: "Proxies", routeLink: infrastructureLinks.proxies.root, routePermission: { permission: Permission.ProxyView, wildcard: true } }),
            create: pageId({ area: "Infrastructure", id: "Infrastructure.ProxiesNew", name: "Add New Proxy", routeLink: infrastructureLinks.proxies.create, routePermission: { permission: Permission.ProxyCreate, wildcard: true } }),
        },
        proxy: pageId({ area: "Infrastructure", id: "Infrastructure.Proxy", name: "Proxy", routeLink: null }),
        machinePolicies: {
            root: pageId({ area: "Infrastructure", id: "Infrastructure.MachinePolicies", name: "Machine Policies", routeLink: infrastructureLinks.machinePolicies.root, routePermission: { permission: Permission.MachinePolicyView, wildcard: true } }),
            create: pageId({
                area: "Infrastructure",
                id: "Infrastructure.MachinePoliciesNew",
                name: "Add New Machine Policy",
                routeLink: infrastructureLinks.machinePolicies.create,
                routePermission: { permission: Permission.MachinePolicyCreate, wildcard: true },
            }),
        },
        machinePolicy: pageId({ area: "Infrastructure", id: "Infrastructure.MachinePolicy", name: "Machine Policy", routeLink: null }),
        accounts: {
            root: pageId({ area: "Infrastructure", id: "Infrastructure.Accounts", name: "Accounts", routeLink: infrastructureLinks.accounts.root, routePermission: { permission: Permission.AccountView, wildcard: true } }),
            create: pageId({ area: "Infrastructure", id: "Infrastructure.AccountsNew", name: "Add New Account", routeLink: infrastructureLinks.accounts.create, routePermission: { permission: Permission.AccountCreate, wildcard: true } }),
        },
        account: pageId({ area: "Infrastructure", id: "Infrastructure.Account", name: "Account", routeLink: null }),
        workerMachines: {
            root: pageId({ area: "Infrastructure", id: "Infrastructure.Workers", name: "Workers", routeLink: infrastructureLinks.workerMachines.root, routePermission: { permission: Permission.WorkerView, wildcard: true } }),
            select: pageId({ area: "Infrastructure", id: "Infrastructure.WorkersSelect", name: "Add New Worker Selection", routeLink: null }),
            create: pageId({
                area: "Infrastructure (Windows, Linux, Mac, Listening Tentacle, Polling Tentacle, SSH Connection)",
                id: "Infrastructure.WorkersNew",
                name: "Add New Worker",
                routeLink: infrastructureLinks.workerMachines.new(undefined),
                routePermission: {
                    permission: Permission.WorkerEdit,
                    wildcard: true,
                },
            }),
            filtered: pageId({ area: "Infrastructure", id: "Infrastructure.WorkersFiltered", name: "Filtered Workers", routeLink: null }),
            discover: pageId({ area: "Infrastructure", id: "Infrastructure.WorkersDiscover", name: "Discover Worker", routeLink: null }),
        },
        workerMachine: {
            ...workerMachineLinks(),
        },
        workerPools: {
            root: pageId({ area: "Infrastructure", id: "Infrastructure.WorkerPools", name: "Worker Pools", routeLink: infrastructureLinks.workerPools.root, routePermission: { permission: Permission.WorkerView, wildcard: true } }),
            create: pageId({ area: "Infrastructure", id: "Infrastructure.WorkerPoolNew", name: "Add New Worker Pool", routeLink: infrastructureLinks.workerPools.create, routePermission: { permission: Permission.WorkerEdit, wildcard: true } }),
            filtered: pageId({ area: "Infrastructure", id: "Infrastructure.WorkerPoolsFiltered", name: "Filtered Worker Pools", routeLink: null }),
        },
        workerPool: pageId({ area: "Infrastructure", id: "Infrastructure.WorkerPool", name: "Worker Pool", routeLink: null }),
    };
};
