import * as React from "react";
import { WorkerEndpointSelector } from "../MachineSettings/Endpoints/EndpointSelector";
import PaperLayout from "components/PaperLayout";
import routeLinks from "routeLinks";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import { repository } from "clientInstance";
import DataBaseComponent, { DataBaseComponentState } from "components/DataBaseComponent";
import { RouteComponentProps } from "react-router";
import { LicenseStatusResource } from "client/resources/licenseStatusResource";
import isWithinLicenceLimit from "areas/configuration/components/License/isWithinLicenceLimit";
import Dialog from "components/Dialog/Dialog";
import { WorkerLimitReachedDialogLayout } from "components/LicenseLimitReachedDialogLayout";

interface WorkerSettingsNewState extends DataBaseComponentState {
    licenseStatus?: LicenseStatusResource;
    openUpgradeDialog: boolean;
    isWithinWorkerLimit: boolean;
}

class WorkerSettingsNew extends DataBaseComponent<RouteComponentProps<{}>, WorkerSettingsNewState> {
    constructor(props: RouteComponentProps<{}>) {
        super(props);
        this.state = {
            openUpgradeDialog: false,
            isWithinWorkerLimit: true,
        };
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const licenseStatus = await repository.Licenses.getCurrentStatus();
            const isWithinWorkerLimit = isWithinLicenceLimit(licenseStatus, "Workers");
            this.setState({
                licenseStatus,
                openUpgradeDialog: !isWithinWorkerLimit,
                isWithinWorkerLimit,
            });
        });
    }

    render() {
        const Heading = (
            <React.Fragment>
                What type of <strong>Worker</strong> do you want to set up? Learn more about <ExternalLink href="Worker">Workers</ExternalLink>
            </React.Fragment>
        );

        return (
            <PaperLayout title="Add Worker" fullWidth={true} flatStyle={true} breadcrumbTitle={"Workers"} breadcrumbPath={routeLinks.infrastructure.workerMachines.root}>
                <WorkerEndpointSelector heading={Heading} />

                <Dialog open={this.state.openUpgradeDialog}>
                    <WorkerLimitReachedDialogLayout
                        onActionClick={() => {
                            this.setState({ openUpgradeDialog: false });
                            window.history.back();
                        }}
                    />
                </Dialog>
            </PaperLayout>
        );
    }
}

export default WorkerSettingsNew;
