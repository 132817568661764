/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import BasicRepository from "./basicRepository";
import Client from "../client";
import ActionTemplateSearchResource from "client/resources/actionTemplateSearchResource";
import { ActionTemplateResource, ActionTemplateUsageResource, ActionTemplateCategoryResource, ActionsUpdateProcessResource, ActionUpdateResource } from "client/resources";
import { CommunityActionTemplateResource } from "client/resources/communityActionTemplateResource";
import ActionProperties from "client/resources/actionProperties";

class ActionTemplateRepository extends BasicRepository<ActionTemplateResource, ActionTemplateResource> {
    constructor(client: Client) {
        super("ActionTemplates", client);
    }
    getUsage(template: Partial<ActionTemplateResource>): Promise<ActionTemplateUsageResource[]> {
        return this.client.get(template.Links!["Usage"]);
    }
    getByCommunityTemplate(communityTemplate: CommunityActionTemplateResource) {
        const allArgs = { ...{}, ...{ id: communityTemplate.Id } };
        return this.client.get<ActionTemplateResource>(communityTemplate.Links["InstalledTemplate"], allArgs);
    }
    search(): Promise<ActionTemplateSearchResource[]> {
        return this.client.get<ActionTemplateSearchResource[]>(this.client.getLink("ActionTemplatesSearch"));
    }
    updateActions(actionTemplate: Partial<ActionTemplateResource>, actionsToUpdate: ActionsUpdateProcessResource[], defaults: ActionProperties = {}, overrides: ActionProperties = {}) {
        const resource: ActionUpdateResource = {
            ActionsToUpdate: actionsToUpdate,
            Overrides: overrides || {},
            DefaultPropertyValues: defaults || {},
            Version: actionTemplate.Version!,
        };
        return this.client.post(actionTemplate.Links!["ActionsUpdate"], resource);
    }
    getVersion(actionTemplate: ActionTemplateResource, version: any): Promise<ActionTemplateResource> {
        return this.client.get(actionTemplate.Links["Versions"], { version });
    }
    categories(): Promise<ActionTemplateCategoryResource[]> {
        return this.client.get<ActionTemplateCategoryResource[]>(this.client.getLink("ActionTemplatesCategories"));
    }
}

export default ActionTemplateRepository;
