/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-imports */

import { HostingEnvironment, Permission } from "client/resources";
import { client, repository, session } from "clientInstance";
import { MenuNode } from "components/LinksMenu/MenuNode";
import EAPBanner from "components/Navbar/EAPBanner";
import ProjectSwitcher from "components/Navbar/ProjectSwitcher";
import UserAccountMenu from "components/Navbar/UserAccountMenu";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import PriorityNavigation from "components/PriorityNavigation/PriorityNavigation";
import Environment from "environment";
import * as React from "react";
import routeLinks from "../../routeLinks";
import LicenceNotificationPoller from "./NotificationPoller/LicenceNotificationPoller";
import UpdateAvailableNotificationPoller from "./NotificationPoller/UpdateAvailableNotificationPoller";
import NotificationsMenu from "./NotificationsMenu";
import { RouteComponentProps, withRouter } from "react-router";
import SpacesMenu from "./SpacesMenu";
import { isSpaceNotFound, isSpecificSpaceContext, SpaceContext } from "../StandardLayout/SpaceLoader";
import Navigate from "components/Navigation/Navigate";
import DevBanner from "./DevBanner";
import SearchAndOpenPopover from "globalSearch/SearchAndOpenPopover";
import ArrowDropDownOutlined from "@material-ui/icons/ArrowDropDownOutlined";
import SimpleIconButton from "primitiveComponents/dataDisplay/IconButton/SimpleIconButton";

const styles = require("./style.less");

interface NavbarState {
    openProjectSwitcher: boolean;
    projectSwitcherAnchorEl: any;
    showEAPBanner: boolean;
    showDevBanner: boolean;
}

interface NavbarProps extends RouteComponentProps<void> {
    spaceContext: SpaceContext;
}

class Navbar extends React.Component<NavbarProps, NavbarState> {
    constructor(props: NavbarProps) {
        super(props);

        this.state = {
            openProjectSwitcher: false,
            projectSwitcherAnchorEl: null,
            showEAPBanner: false,
            showDevBanner: false,
        };
    }

    async componentDidMount() {
        const serverInformation = repository.getServerInformation();
        const licenseStatus = await repository.Licenses.getCurrentStatus();

        this.setState({
            showEAPBanner: serverInformation.isEarlyAccessProgram && licenseStatus.HostingEnvironment === HostingEnvironment.SelfHosted,
            showDevBanner: Environment.isInDevelopmentMode(),
        });
    }

    render() {
        // The topnavbar id is used by E2E UI tests
        return (
            <Navigate
                render={(history) => (
                    <div id="topnavbar" className={styles.topnavbar}>
                        {this.state.showEAPBanner ? <EAPBanner onClose={() => this.setState({ showEAPBanner: false })} /> : <></>}
                        {this.state.showDevBanner ? <DevBanner onClose={() => this.setState({ showDevBanner: false })} /> : <></>}

                        <div className={styles.navbar}>
                            <SpacesMenu spaceContext={this.props.spaceContext} />

                            <PriorityNavigation navigationItems={this.getNavigationItems()} maxNavigationItems={99} activeItemClassName={styles.navItemActive} className={styles.navbarCenter} showHamburgerIcon={true} />

                            {isAllowed({ permission: Permission.ProjectView, wildcard: true }) && (
                                <ProjectSwitcher
                                    open={this.state.openProjectSwitcher}
                                    onRequestClose={(id, event) => {
                                        this.setState({ openProjectSwitcher: false });
                                        if (id && !event!.ctrlKey) {
                                            history.navigate(routeLinks.project(id).root);
                                        } else if (id) {
                                            open(routeLinks.project(id).root);
                                            if (event) {
                                                event.stopPropagation();
                                                event.preventDefault();
                                            }
                                        }
                                    }}
                                    anchorEl={this.state.projectSwitcherAnchorEl}
                                />
                            )}
                            <nav className={styles.navbarRight}>
                                <SearchAndOpenPopover />
                                <div className={styles.groupedRow}>
                                    <UpdateAvailableNotificationPoller />
                                    <LicenceNotificationPoller />
                                    <NotificationsMenu />
                                    <UserAccountMenu currentUser={session.currentUser!} spaceContext={this.props.spaceContext} />
                                </div>
                            </nav>
                        </div>
                    </div>
                )}
            />
        );
    }

    private getNavigationItems() {
        const navigationItems: MenuNode[] = [];

        if (isSpecificSpaceContext(this.props.spaceContext)) {
            navigationItems.push({ url: routeLinks.root, text: "Dashboard", exact: true });

            if (session.currentPermissions!.scopeToSpace(client.spaceId).hasPermissionInAnyScope(Permission.ProjectView)) {
                navigationItems.push({
                    url: routeLinks.projects.root,
                    text: "Projects",
                    accessoryView: (
                        <SimpleIconButton
                            className={styles.projectSwitcherLink}
                            onClick={(e) => {
                                e.preventDefault(); // Don't let this click event bubble up or we also select the Projects menu item.
                                this.toggleProjectSwitcher(e.currentTarget);
                            }}
                            disableRipple={true} // Our nav bar actions do not include ripple effects, so we want to keep this consistent.
                        >
                            <ArrowDropDownOutlined htmlColor="white" />
                        </SimpleIconButton>
                    ),
                    linkClassName: styles.projectsLink,
                });
            }
            if (
                isAllowed({
                    permission: [Permission.MachineView, Permission.EnvironmentView, Permission.WorkerView],
                    wildcard: true,
                })
            ) {
                navigationItems.push({ url: routeLinks.infrastructure.root, text: "Infrastructure" });
            }
            if (session.currentPermissions!.scopeToSpace(client.spaceId).hasPermissionInAnyScope(Permission.TenantView)) {
                navigationItems.push({ url: routeLinks.tenants, text: "Tenants" });
            }
            if (
                isAllowed({
                    permission: [Permission.FeedView, Permission.LifecycleEdit, Permission.VariableView, Permission.ActionTemplateView, Permission.CertificateView],
                    wildcard: true,
                })
            ) {
                navigationItems.push({ url: routeLinks.library.root, text: "Library" });
            }
        }

        if (!isSpaceNotFound(this.props.spaceContext) || this.props.spaceContext.isAlsoInSystemContext) {
            const rootRouteLinks = isSpaceNotFound(this.props.spaceContext) ? routeLinks.withoutSpace() : routeLinks;
            if (session.currentPermissions!.hasPermissionInAnyScope(Permission.TaskView)) {
                navigationItems.push({ url: rootRouteLinks.tasks.rootForSpace(client.spaceId), text: "Tasks" });
            }
            if (
                session.currentPermissions!.scopeToSpace(repository.spaceId).hasAnyPermissions() ||
                isAllowed({
                    permission: [Permission.AdministerSystem, Permission.EventView, Permission.TeamView, Permission.UserView, Permission.UserRoleView, Permission.SpaceView, Permission.SpaceEdit, Permission.SpaceCreate, Permission.SpaceDelete],
                    wildcard: true,
                })
            ) {
                navigationItems.push({ url: rootRouteLinks.configuration.root, text: "Configuration" });
            }
        }

        return navigationItems;
    }

    private toggleProjectSwitcher = (e: HTMLElement) => {
        this.setState({
            openProjectSwitcher: !this.state.openProjectSwitcher,
            projectSwitcherAnchorEl: e,
        });
    };
}

// Unfortunately this component needs to be connected to the router purely so that it updates when the route changes.
// Otherwise, the connect() breaks all route-related updates.  This might be fixed in React 16.3 which should introduce the new context API
export default withRouter(Navbar);
