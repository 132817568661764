import { StepUI } from "components/Actions/StepUIAPI/StepUI";
import { SensitiveValue } from "components/Actions/StepUIAPI/SensitiveText";
import { FormSection, FormSectionProps } from "components/Actions/StepUIAPI/FormSection";
import { StepPackage } from "components/StepPackageEditor/StepPackage/StepPackage";

export interface JiraServiceDeskChangeRequestProperties {
    "Octopus.Action.JiraIntegration.ServiceDesk.ServiceId": SensitiveValue | undefined;
}

export const jiraServiceDeskChangeRequestStepUI: StepUI<JiraServiceDeskChangeRequestProperties> = () => {
    return {
        stepName: "Jira Service Desk",
        editPropertiesForm: ({ section }) => [serviceIdSection(section)],
    };
};

function serviceIdSection(section: (props: FormSectionProps<JiraServiceDeskChangeRequestProperties>) => FormSection<JiraServiceDeskChangeRequestProperties>) {
    return section({
        title: "Service Id",
        helpText: "Provide the Jira Service Desk Service Id.",
        content: ({ sensitiveText }) => [
            sensitiveText({
                label: "Service Id",
                getValue: (p) => p["Octopus.Action.JiraIntegration.ServiceDesk.ServiceId"] ?? { type: "empty" },
                onChange: (newValue: SensitiveValue) => ({
                    ["Octopus.Action.JiraIntegration.ServiceDesk.ServiceId"]: newValue,
                }),
                summary: () => `The service id has been provided`,
            }),
        ],
    });
}

export const jiraStepPackage: StepPackage<JiraServiceDeskChangeRequestProperties> = {
    inputSchema: {
        properties: [
            {
                propertyName: "Octopus.Action.JiraIntegration.ServiceDesk.ServiceId",
                possibleValues: ["undefined", "sensitive"],
            },
        ],
    },
    stepUI: jiraServiceDeskChangeRequestStepUI,
};
