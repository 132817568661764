import * as React from "react";
import DoneIcon from "@material-ui/icons/Done";
import AvPlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ClearIcon from "@material-ui/icons/Clear";
import GroupIcon from "@material-ui/icons/Group";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import GetAppIcon from "@material-ui/icons/GetApp";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import InfoOutlineIcon from "@material-ui/icons/InfoOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import RateReviewIcon from "@material-ui/icons/RateReview";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import HttpsIcon from "@material-ui/icons/Https";
import CancelIcon from "@material-ui/icons/Cancel";
import FilterListIcon from "@material-ui/icons/FilterList";
import FileCopy from "@material-ui/icons/FileCopy";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import SearchIcon from "@material-ui/icons/Search";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import NotificationsIcon from "@material-ui/icons/Notifications";
import FingerPrintIcon from "@material-ui/icons/Fingerprint";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { exhaustiveCheck } from "utils/exhaustiveCheck";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import DirectionsIcon from "@material-ui/icons/Directions";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";

export enum ThirdPartyIconType {
    Done,
    AvPlayCircleFilled,
    CheckCircle,
    Clear,
    SmallClear,
    Group,
    PlayArrow,
    GetApp,
    AccountBox,
    InfoOutline,
    MoreVert,
    RateReview,
    KeyboardArrowUp,
    KeyboardArrowDown,
    Https,
    Cancel,
    FilterList,
    FileCopy,
    HelpOutline,
    Search,
    ArrowRight,
    Notifications,
    Fingerprint,
    CheckBox,
    CheckBoxOutlineBlank,
    RadioButtonChecked,
    ExpandMore,
    ArrowDropDown,
    ArrowForward,
    Directions,
    VideoLibrary,
}

interface ThirdPartyIconProps {
    iconType: ThirdPartyIconType;
    className?: string;
    focusable?: string;
    style?: React.CSSProperties;
    color?: string;
}

export const ThirdPartyIcon: React.StatelessComponent<ThirdPartyIconProps> = (props) => {
    const propsForIcon: SvgIconProps = {
        className: props.className,
        focusable: props.focusable,
        style: props.style,
    };

    if (props.color) {
        // There appears to be a bug in Material-UI's icons, in that you cannot just set the htmlColor like it says in their documentation,
        // you also need to set the fill colour before things will actually kick in, which is why you'll see both the htmlColor and style/fill being set on
        // each change.

        propsForIcon.htmlColor = props.color;
        propsForIcon.style = { ...propsForIcon.style, fill: props.color };
    }

    switch (props.iconType) {
        case ThirdPartyIconType.Done:
            return <DoneIcon {...propsForIcon} />;
        case ThirdPartyIconType.AvPlayCircleFilled:
            return <AvPlayCircleFilledIcon {...propsForIcon} />;
        case ThirdPartyIconType.CheckCircle:
            return <CheckCircleIcon {...propsForIcon} />;
        case ThirdPartyIconType.Clear:
            return <ClearIcon {...propsForIcon} />;
        case ThirdPartyIconType.SmallClear:
            return <ClearIcon viewBox="-5 0 35 25" {...propsForIcon} />;
        case ThirdPartyIconType.Group:
            return <GroupIcon {...propsForIcon} />;
        case ThirdPartyIconType.PlayArrow:
            return <PlayArrowIcon {...propsForIcon} />;
        case ThirdPartyIconType.GetApp:
            return <GetAppIcon {...propsForIcon} />;
        case ThirdPartyIconType.AccountBox:
            return <AccountBoxIcon {...propsForIcon} />;
        case ThirdPartyIconType.InfoOutline:
            return <InfoOutlineIcon {...propsForIcon} />;
        case ThirdPartyIconType.MoreVert:
            return <MoreVertIcon {...propsForIcon} />;
        case ThirdPartyIconType.RateReview:
            return <RateReviewIcon {...propsForIcon} />;
        case ThirdPartyIconType.KeyboardArrowUp:
            return <KeyboardArrowUpIcon {...propsForIcon} />;
        case ThirdPartyIconType.KeyboardArrowDown:
            return <KeyboardArrowDownIcon {...propsForIcon} />;
        case ThirdPartyIconType.Https:
            return <HttpsIcon {...propsForIcon} />;
        case ThirdPartyIconType.Cancel:
            return <CancelIcon {...propsForIcon} />;
        case ThirdPartyIconType.FilterList:
            return <FilterListIcon {...propsForIcon} />;
        case ThirdPartyIconType.FileCopy:
            return <FileCopy {...propsForIcon} />;
        case ThirdPartyIconType.HelpOutline:
            return <HelpOutlineIcon {...propsForIcon} />;
        case ThirdPartyIconType.Search:
            return <SearchIcon {...propsForIcon} />;
        case ThirdPartyIconType.ArrowRight:
            return <ArrowRightIcon {...propsForIcon} />;
        case ThirdPartyIconType.Notifications:
            return <NotificationsIcon {...propsForIcon} />;
        case ThirdPartyIconType.Fingerprint:
            return <FingerPrintIcon {...propsForIcon} />;
        case ThirdPartyIconType.CheckBox:
            return <CheckBoxIcon {...propsForIcon} />;
        case ThirdPartyIconType.CheckBoxOutlineBlank:
            return <CheckBoxOutlineBlankIcon {...propsForIcon} />;
        case ThirdPartyIconType.RadioButtonChecked:
            return <RadioButtonCheckedIcon {...propsForIcon} />;
        case ThirdPartyIconType.ExpandMore:
            return <ExpandMoreIcon {...propsForIcon} />;
        case ThirdPartyIconType.ArrowDropDown:
            return <ArrowDropDownIcon {...propsForIcon} />;
        case ThirdPartyIconType.ArrowForward:
            return <ArrowForwardIcon {...propsForIcon} />;
        case ThirdPartyIconType.Directions:
            return <DirectionsIcon {...propsForIcon} />;
        case ThirdPartyIconType.VideoLibrary:
            return <VideoLibraryIcon {...propsForIcon} />;
        default:
            exhaustiveCheck(props.iconType, "Not all icon types have been handled.");
    }

    return null;
};
