/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { repository } from "clientInstance";
import SimpleExpander from "components/SimpleExpander";
import { EnvironmentSummaryResource, MachineModelHealthStatus, EnvironmentResource } from "client/resources";
import { OctopusIcon, OctopusIconType } from "primitiveComponents/dataDisplay/Icon";
import PaperLayout from "components/PaperLayout/PaperLayout";
import OverflowMenu, { OverflowMenuItems, OverflowMenuNavLink, OverflowMenuGenericItem } from "components/Menu/OverflowMenu";
import { ActionButton, ActionButtonType } from "components/Button";
import { isEqual } from "lodash";
import MachineHealthStatusHelper from "utils/MachineHealthStatusHelper";
import Permission from "client/resources/permission";
import routeLinks from "../../../../routeLinks";
import BaseMachinesSummary from "../BaseMachinesSummary/BaseMachinesSummary";
import { SmallCloseButton, BaseMachinesSummaryProps, BaseMachinesSummaryState } from "../BaseMachinesSummary/BaseMachinesSummary";
import MarkdownDescription from "components/MarkdownDescription";
import { Section } from "components/Section/Section";
import RequestRaceConditioner from "utils/RequestRaceConditioner";
import InternalRedirect from "components/Navigation/InternalRedirect/InternalRedirect";
import { withTheme } from "components/Theme";
import { getIsDisabledFilterAsPerPrecedence, getHealthStatusFiltersAsPerPrecedence } from "../BaseMachinesSummary/BaseMachinesSummaryFilter";
import { orderedHealthStatuses } from "areas/infrastructure/InfrastructureDetails";
import { EnvironmentSummaryFilter, createEnvironmentMachinesArgs } from "./EnvironmentSummaryFilter";
const styles = require("./style.less");

interface EnvironmentSummarySectionProps extends BaseMachinesSummaryProps {
    environmentSummary: EnvironmentSummaryResource;
    filter: EnvironmentSummaryFilter;
}

class EnvironmentSummarySection extends BaseMachinesSummary<EnvironmentSummarySectionProps, BaseMachinesSummaryState> {
    private requestRaceConditioner = new RequestRaceConditioner();

    constructor(props: EnvironmentSummarySectionProps) {
        super(props);
        this.state = {
            machinesResponse: null,
            machineHealthStatusFastLookup: {},
            currentPageIndex: 0,
            expanded: false,
            healthStatusFilter: "",
            isDisabledFilter: false,
        };
    }

    componentWillReceiveProps(nextProps: EnvironmentSummarySectionProps) {
        if (this.state.expanded && !isEqual(this.props.filter, nextProps.filter)) {
            this.reloadDataAndCurrentPageIndex();
        }
    }

    render() {
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={routeLinks.task(this.state.redirectToTaskId).root} push={true} />;
        }
        const environmentSummary = this.props.environmentSummary;
        const machineHealthLinks = orderedHealthStatuses.map((status) => this.renderMachineSummaryLinks(environmentSummary, status));
        const machinesDisabledLinks = this.renderMachineDisabledSummaryLinks(environmentSummary);
        const summaryComponents = [...machineHealthLinks, machinesDisabledLinks];

        const environment = environmentSummary.Environment;
        const overflowMenuItems: Array<OverflowMenuNavLink[] | OverflowMenuGenericItem> = [
            [
                OverflowMenuItems.navItem("Add Deployment Target", routeLinks.infrastructure.machines.new(environment.Id), undefined, {
                    permission: Permission.MachineCreate,
                    environment: environment.Id,
                    tenant: "*",
                }),
                OverflowMenuItems.navItem("Edit", routeLinks.infrastructure.environment(environment), undefined, {
                    permission: Permission.EnvironmentEdit,
                    environment: environment.Id,
                }),
            ],
        ];

        // Only show machine-related actions if they actually have some machines in this environment.
        if (environmentSummary.TotalMachines > 0) {
            if (this.state.machinesResponse) {
                const machineIds = this.state.machinesResponse.Items.map((x) => x.Id);
                overflowMenuItems.push(
                    OverflowMenuItems.item(`Check Health for ${environmentSummary.TotalMachines} Deployment Target${environmentSummary.TotalMachines === 1 ? "" : "s"}`, () => this.performHealthCheck(environment, machineIds), {
                        permission: Permission.MachineEdit,
                        environment: environment.Id,
                        tenant: "*",
                    })
                );
            }

            const tentacleIds = environmentSummary.MachineIdsForTentacleUpgrade;
            if (tentacleIds && tentacleIds.length > 0) {
                overflowMenuItems.push(
                    OverflowMenuItems.confirmUpgrade(`Upgrade ${tentacleIds.length} Tentacle${tentacleIds.length === 1 ? "" : "s"} in this Environment`, () => this.performTentacleUpgrade(environment, tentacleIds), {
                        permission: Permission.MachineEdit,
                        environment: environment.Id,
                        tenant: "*",
                    })
                );
            }
            const calamariIds = environmentSummary.MachineIdsForCalamariUpgrade;
            if (calamariIds && calamariIds.length > 0) {
                overflowMenuItems.push(
                    OverflowMenuItems.confirmUpgrade(`Upgrade Calamari on ${calamariIds.length} Deployment Target${calamariIds.length === 1 ? "" : "s"}`, () => this.performCalamariUpgrade(calamariIds), {
                        permission: Permission.MachineEdit,
                        environment: environment.Id,
                        tenant: "*",
                    })
                );
            }
        }
        const titleContainer = withTheme((theme) => (
            <div className={styles.cardTitleContainer}>
                <div className={styles.environmentIcon}>
                    <OctopusIcon iconType={OctopusIconType.Environment} style={{ fill: theme.iconDark }} />
                </div>
                <div className={styles.environmentName}>{environment.Name}</div>
                <div className={styles.environmentMachinesCount}>{`(${environmentSummary.TotalMachines && environmentSummary.TotalMachines.toLocaleString()} deployment target${environmentSummary.TotalMachines === 1 ? "" : "s"})`}</div>
                <div className={styles.environmentSummaryCounts}>{summaryComponents}</div>
                <div className={styles.environmentOverflowActions}>
                    <OverflowMenu menuItems={overflowMenuItems} />
                </div>
            </div>
        ));

        return (
            <PaperLayout key={environment.Id} busy={this.state.busy} errors={this.errors} className={styles.paperLayoutOverride}>
                <SimpleExpander
                    errorKey={environment.Id}
                    key={environment.Id}
                    title={titleContainer}
                    onDidExpand={(expanded) => {
                        this.setState({ expanded });
                        if (expanded) {
                            this.reloadDataAndCurrentPageIndex();
                        } else {
                            this.setState({
                                machinesResponse: null,
                                currentPageIndex: 0,
                            });
                        }
                    }}
                >
                    {environment.Description && (
                        <Section>
                            <MarkdownDescription markup={environment.Description} />
                        </Section>
                    )}
                    {this.renderMachinesList(this.props.environmentSummary)}
                </SimpleExpander>
            </PaperLayout>
        );
    }

    protected async loadData() {
        const isDisabled = getIsDisabledFilterAsPerPrecedence(this.props.isFiltering, this.props.filter, this.state.isDisabledFilter);
        const applicableHealthStatusFilters = getHealthStatusFiltersAsPerPrecedence(this.props.filter, this.props.isFiltering, this.state.healthStatusFilter);
        const args = createEnvironmentMachinesArgs(this.props.filter, isDisabled, applicableHealthStatusFilters);
        await this.requestRaceConditioner.avoidStaleResponsesForRequest(repository.Environments.machines(this.props.environmentSummary.Environment, args), (machinesResponse) => this.setMachineResponseState(machinesResponse));
    }

    private renderMachineSummaryLinks(environmentSummary: EnvironmentSummaryResource, healthStatus: MachineModelHealthStatus) {
        const healthStatusIcon = this.machineIconHelper.healthStatusIcons[healthStatus];
        const value = environmentSummary.MachineHealthStatusSummaries[healthStatus] as number;
        if (!value || value === 0) {
            // Don't show links if there's nothing to report.
            return null;
        }

        // If filtering health statuses from the sidebar, just show the health statuses that they've chosen to filter (and don't show them as links).
        if (this.props.filter.healthStatuses && this.props.filter.healthStatuses.length > 0) {
            return (
                <div key={healthStatus} className={styles.summaryCount}>
                    {healthStatusIcon && <img key={healthStatus} src={healthStatusIcon} className={styles.healthStatusIcon} alt="Health status" />}
                    {value.toLocaleString() + " " + MachineHealthStatusHelper.getFriendlyName(healthStatus as MachineModelHealthStatus).toLowerCase()}
                </div>
            );
        }

        // Else show environment-specific health status actions.
        if (this.state.healthStatusFilter === healthStatus) {
            return (
                <div key={healthStatus} className={styles.summaryCount}>
                    {healthStatusIcon && <img key={healthStatus} src={healthStatusIcon} className={styles.healthStatusIcon} alt="Health status" />}
                    {value.toLocaleString() + " " + MachineHealthStatusHelper.getFriendlyName(healthStatus as MachineModelHealthStatus).toLowerCase()}
                    <SmallCloseButton
                        onClose={(e: React.MouseEvent<Element, MouseEvent>) => {
                            // The user may click a health status link to open an expander (but it shouldn't ever close it).
                            if (this.state.expanded) {
                                e.preventDefault();
                                e.stopPropagation(); //prevent clicking the link toggling the panel/expander.
                            }

                            this.setState({ healthStatusFilter: "" }, () => {
                                if (this.state.expanded) {
                                    this.reloadDataAndCurrentPageIndex();
                                }
                            });
                        }}
                    />
                </div>
            );
        } else {
            return (
                <ActionButton
                    key={healthStatus}
                    icon={<img key={healthStatus} src={healthStatusIcon} className={styles.healthStatusIcon} alt="Health status" />}
                    className={styles.summaryCount}
                    type={ActionButtonType.Ternary}
                    label={value.toLocaleString() + " " + MachineHealthStatusHelper.getFriendlyName(healthStatus as MachineModelHealthStatus).toLowerCase()}
                    onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
                        // The user may click a health status link to open an expander (but it shouldn't ever close it).
                        if (this.state.expanded) {
                            e.preventDefault();
                            e.stopPropagation(); //prevent clicking the link toggling the panel/expander.
                        }
                        // Clear any disabled filters when a healthStatus filter is clicked. You can't chain inline disabled and healthStatus
                        // filters together because they use different and/or logic at the API and it causes UI confusion.
                        this.setState(
                            {
                                healthStatusFilter: healthStatus,
                                isDisabledFilter: false,
                            },
                            () => {
                                if (this.state.expanded) {
                                    this.reloadDataAndCurrentPageIndex();
                                }
                            }
                        );
                    }}
                />
            );
        }
    }

    private renderMachineDisabledSummaryLinks(environmentSummary: EnvironmentSummaryResource) {
        const disabledComponentKey = "Disabled";
        const disabledIcon = this.machineIconHelper.healthStatusIcons["Disabled"];
        const value = environmentSummary.TotalDisabledMachines;
        if (!value || value === 0) {
            // Don't show links if there's nothing to report.
            return null;
        }

        // If filtering from the sidebar, just show the disabled control (not as a link).
        if (this.props.filter.isDisabled) {
            return (
                <div key={disabledComponentKey} className={styles.summaryCount}>
                    {disabledIcon && <img key={disabledComponentKey} src={disabledIcon} className={styles.healthStatusIcon} alt="Health status" />}
                    {value.toLocaleString() + " disabled"}
                </div>
            );
        }

        // Else show environment-specific disabled action.
        if (this.state.isDisabledFilter) {
            return (
                <div key={disabledComponentKey} className={styles.summaryCount}>
                    {disabledIcon && <img key={disabledComponentKey} src={disabledIcon} className={styles.healthStatusIcon} alt="Health status" />}
                    {value.toLocaleString() + " disabled"}
                    <SmallCloseButton
                        onClose={(e: React.MouseEvent<Element, MouseEvent>) => {
                            // The user may click a health status link to open an expander (but it shouldn't ever close it).
                            if (this.state.expanded) {
                                e.preventDefault();
                                e.stopPropagation(); //prevent clicking the link toggling the panel/expander.
                            }

                            this.setState({ isDisabledFilter: false }, () => {
                                if (this.state.expanded) {
                                    this.reloadDataAndCurrentPageIndex();
                                }
                            });
                        }}
                    />
                </div>
            );
        } else {
            return (
                <ActionButton
                    key={disabledComponentKey}
                    icon={<img key={disabledComponentKey} src={disabledIcon} className={styles.healthStatusIcon} alt="Health status" />}
                    className={styles.summaryCount}
                    type={ActionButtonType.Ternary}
                    label={value.toLocaleString() + " disabled"}
                    onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
                        // The user may click a disabled link to open an expander (but it shouldn't ever close it).
                        if (this.state.expanded) {
                            e.preventDefault();
                            e.stopPropagation(); //prevent clicking the link toggling the panel/expander.
                        }
                        // Clear any healthStatus filters when disabled is clicked. You can't chain inline disabled and healthStatus
                        // filters together because they use different and/or logic at the API and it causes UI confusion.
                        this.setState(
                            {
                                isDisabledFilter: true,
                                healthStatusFilter: "",
                            },
                            () => {
                                if (this.state.expanded) {
                                    this.reloadDataAndCurrentPageIndex();
                                }
                            }
                        );
                    }}
                />
            );
        }
    }

    private async performHealthCheck(environment: EnvironmentResource, machineIds: string[]) {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createPerformHealthCheckTaskForEnvironment(environment, machineIds);
            this.setState({ redirectToTaskId: task.Id });
        });
    }

    private async performTentacleUpgrade(environment: EnvironmentResource, machineIds: string[]): Promise<boolean> {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createUpgradeTentaclesTaskForEnvironment(environment, machineIds);
            this.setState({ redirectToTaskId: task.Id });
        });
    }

    private async performCalamariUpgrade(machineIds: string[]) {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createUpdateCalamariOnTargetsTask(machineIds);
            this.setState({ redirectToTaskId: task.Id });
        });
    }
}

export default EnvironmentSummarySection;
