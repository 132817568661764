import React, { useCallback, useContext, useEffect, useState } from "react";
import { PropsWithChildren } from "react";
import Timeout = NodeJS.Timeout;
import environment from "environment";

export function ConsoleErrorCapturer(props: PropsWithChildren<{}>) {
    return environment.isInDevelopmentMode() ? <DevOnlyConsoleErrorAndWarningCapturer>{props.children}</DevOnlyConsoleErrorAndWarningCapturer> : <>{props.children}</>;
}

function DevOnlyConsoleErrorAndWarningCapturer(props: PropsWithChildren<{}>) {
    const [consoleErrors, setConsoleErrors] = useState<ConsoleEntries>([]);

    const removeAllConsoleErrorsCallback = useCallback(() => {
        setConsoleErrors([]);
    }, []);

    useCaptureConsoleErrors(setConsoleErrors);

    return (
        <consoleErrorsContext.Provider value={consoleErrors}>
            <removeAllConsoleErrorsContext.Provider value={removeAllConsoleErrorsCallback}>{props.children}</removeAllConsoleErrorsContext.Provider>
        </consoleErrorsContext.Provider>
    );
}

function useCaptureConsoleErrors(setConsoleErrors: (value: ((prevState: ConsoleEntry[]) => ConsoleEntry[]) | ConsoleEntry[]) => void) {
    const getConsoleError = useCallback(() => console.error, []);
    const setConsoleError = useCallback((newConsoleError) => (console.error = newConsoleError), []);

    useCaptureConsoleEntries("console.error", getConsoleError, setConsoleError, setConsoleErrors);
}

function useCaptureConsoleEntries(
    consoleFunctionName: "console.error",
    getConsoleFunction: () => typeof console.error,
    assignConsoleFunction: (newConsoleFunction: typeof console.error) => void,
    setConsoleEntries: (value: ((prevState: ConsoleEntry[]) => ConsoleEntry[]) | ConsoleEntry[]) => void
) {
    useEffect(() => {
        const originalConsoleFunction = getConsoleFunction();
        const { dispatchAndCaptureConsoleError, timeouts } = createCustomConsoleFunctionHandler(originalConsoleFunction, setConsoleEntries);
        assignConsoleFunction(dispatchAndCaptureConsoleError);
        return () => {
            // Clear any logging actions that haven't yet fired.
            // This implementation is intentionally simple, and likely to clear many timeouts that have already completed.
            // This hopefully reduces complexity, because we don't have to maintain a list of incomplete timeouts
            timeouts.forEach((t) => clearTimeout(t));

            if (getConsoleFunction() !== dispatchAndCaptureConsoleError) {
                // Imagine there are two instances of ConsoleErrorDisplayer active at the same time
                // This should work, as long as they are unmounted in the reverse order that they are mounted
                // If the order differs, then the behaviour may be unexpected.
                originalConsoleFunction(
                    `The ${consoleFunctionName} function instance was not the same instance that was set in ConsoleErrorCapturer. ` +
                        "Something else has unexpectedly changed this instance without reverting the change, which may cause errors when the instance is reset to its original value."
                );
            }
            assignConsoleFunction(originalConsoleFunction);
        };
    }, [assignConsoleFunction, consoleFunctionName, getConsoleFunction, setConsoleEntries]);
}

function createCustomConsoleFunctionHandler(originalConsoleFunction: typeof console.error, setConsoleEntries: (value: ((prevState: ConsoleEntry[]) => ConsoleEntry[]) | ConsoleEntry[]) => void) {
    const timeouts: Timeout[] = [];
    function dispatchAndCaptureConsoleEntry(...data: ConsoleArguments) {
        const trace = new Error().stack;
        originalConsoleFunction(...data);

        // This implementation is quite a bit more complex than the normal console.error because it involves setting the state of a react component (eg `setConsoleErrors`)
        // We can't synchronously set the console errors here, because there are some situations where `console.error` is called that would cause that to fail
        // For example, if a component errored in its `componentWillMount` function, then calling `console.error` and `setConsoleErrors` in that call chain would result in an error like
        // "Cannot update a component from inside the function body of a different component"
        // Executing the setConsoleErrors call after a timeout ensures that the state will be set, regardless of the call site (as long as this component is still mounted)
        const timeout = setTimeout(() => {
            try {
                setConsoleEntries((prev) => [
                    ...prev,
                    {
                        args: data,
                        trace,
                    },
                ]);
            } catch (error) {
                // If something goes wrong here, then we are probably unlikely to be able to show the error, and have to hope that logging it to the console is enough
                // We don't want to log the error at console.error, as this might result in an endless cycle of logging failures
                // But we can send this error to the original console implementation
                originalConsoleFunction("Error recording console entries in ConsoleErrorDisplayer", error);
            }
        }, 0);
        timeouts.push(timeout);
    }

    return { dispatchAndCaptureConsoleError: dispatchAndCaptureConsoleEntry, timeouts };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ConsoleArguments = any[];

interface ConsoleEntry {
    args: ConsoleArguments;
    trace: string | undefined;
}
export type ConsoleEntries = ConsoleEntry[];
type RemoveAllConsoleEntries = () => void;

const consoleErrorsContext = React.createContext<ConsoleEntries | null>(null);
const removeAllConsoleErrorsContext = React.createContext<RemoveAllConsoleEntries | null>(null);

export function useConsoleErrors() {
    const consoleErrors = useContext(consoleErrorsContext);
    if (consoleErrors === null) {
        throw new Error(`consoleErrorsContext not found. Ensure that ${ConsoleErrorCapturer} has been rendered somewhere above this component`);
    }
    return consoleErrors;
}

export function useRemoveAllConsoleErrors() {
    const removeAllConsoleErrors = useContext(removeAllConsoleErrorsContext);
    if (removeAllConsoleErrors === null) {
        throw new Error(`removeAllConsoleErrorsContext not found. Ensure that ${ConsoleErrorCapturer} has been rendered somewhere above this component`);
    }
    return removeAllConsoleErrors;
}
