import { AvailableSummaryComponents, SummaryTemplateOptions, StrongTextFactory, Summary, EmphasizedTextFactory } from "../Actions/StepUIAPI/Summary";

const strong: StrongTextFactory = (value: Summary) => {
    return { type: "strong", value };
};

const emphasis: EmphasizedTextFactory = (value: Summary) => {
    return { type: "em", value };
};

function summary(strings: TemplateStringsArray, ...expressions: SummaryTemplateOptions[]): SummaryTemplateOptions[] {
    const stringsWithExpressions = strings.map((s, i) => {
        const expression = expressions[i];
        if (expression) return [s, expression];
        return [s];
    });

    return stringsWithExpressions.reduce((acc, current) => {
        return [...acc, ...current];
    }, []);
}

export const availableSummaryComponents: AvailableSummaryComponents = {
    summary,
    emphasis,
    strong,
};
