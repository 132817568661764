export type ProjectScoped = {
    isVersionControlled: boolean;
};

export type VariableEditorScopingOptions = ProjectScoped | "Unscoped";

export const isProjectScopedOptions = (options: VariableEditorScopingOptions): options is ProjectScoped => {
    if (options === "Unscoped") {
        return false;
    }
    return Object.hasOwnProperty.call(options, "isVersionControlled");
};
