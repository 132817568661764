import * as React from "react";
import pluginRegistry, { ActionEditProps } from "../../Actions/pluginRegistry";
import { BaseComponent } from "../../BaseComponent/BaseComponent";
import { KubernetesSecretProperties } from "components/Actions/kubernetes/kubernetesProperties";
import { KubernetesSecretComponent } from "components/Actions/kubernetes/kubernetesSecretComponent";
import { ScriptPackageProperties } from "components/Actions/script/ScriptPackageReferenceDialog";

class KubernetesSecretFeature extends BaseComponent<ActionEditProps<KubernetesSecretProperties, ScriptPackageProperties>, never> {
    render() {
        return (
            <KubernetesSecretComponent
                properties={this.props.properties}
                packages={this.props.packages}
                plugin={this.props.plugin}
                getFieldError={this.props.getFieldError}
                setProperties={this.props.setProperties}
                setPackages={this.props.setPackages}
                doBusyTask={this.props.doBusyTask}
                busy={this.props.busy}
                errors={this.props.errors}
                projectId={this.props.projectId}
                expandedByDefault={this.props.expandedByDefault}
                importLabels={false}
            />
        );
    }
}

pluginRegistry.registerFeatureForAllScopes({
    featureName: "Octopus.Features.KubernetesSecret",
    title: "Secret",
    description: "Defines a Kubernetes Secret resource, which is a collection of key/value settings",
    edit: KubernetesSecretFeature,
    priority: 20,
});
