import React from "react";
import type { VariableEditorScopingOptions } from "./types";

const VariableEditorScopingContext = React.createContext<VariableEditorScopingOptions>("Unscoped");

export const useVariableEditorScoping = () => {
    return React.useContext(VariableEditorScopingContext);
};

type VariableEditorScopingProviderProps = {
    scopingOptions: VariableEditorScopingOptions;
};

export const VariableEditorScopingProvider: React.FC<VariableEditorScopingProviderProps> = (props) => {
    return <VariableEditorScopingContext.Provider value={props.scopingOptions}>{props.children}</VariableEditorScopingContext.Provider>;
};
