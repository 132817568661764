/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-restricted-imports */

import BaseComponent from "../../BaseComponent";
import * as React from "react";
import FilterSearchBox from "../../FilterSearchBox";
import { List, ListItem } from "material-ui/List";
import Popover from "components/Popover/Popover";
import { ScriptingLanguage } from "components/scriptingLanguage";
import IconButton, { Icon } from "components/IconButton/IconButton";
import { codeEditorVariablesList } from "utils/ScriptIntellisense/scriptIntellisense";
import { ScriptActionContext, IScriptActionContext } from "components/Actions/script/ScriptActionContext";
const styles = require("./style.less");

interface InsertVariableButtonProps {
    localNames?: string[];
    syntax?: ScriptingLanguage;
    onSelected(value: string): void;
}

interface InsertVariableButtonState {
    serverNames: string[];
    filter: string;
    isInsertPopupOpen: boolean;
}

export default class InsertVariableButton extends BaseComponent<InsertVariableButtonProps, InsertVariableButtonState> {
    static contextType = ScriptActionContext;
    showInsertPopupButton: HTMLElement = undefined!;
    context: IScriptActionContext | undefined;

    constructor(props: InsertVariableButtonProps) {
        super(props);
        this.state = {
            serverNames: [],
            isInsertPopupOpen: false,
            filter: "",
        };
    }

    async componentDidMount() {
        this.setState({
            serverNames: this.context ? await this.context.loadVariables() : [],
        });
    }

    showInsertPopup = (event: React.MouseEvent) => {
        event.preventDefault();

        this.setState({
            isInsertPopupOpen: true,
        });
    };

    hideInsertPopup = () => {
        this.setState({
            isInsertPopupOpen: false,
        });
    };

    handleSelected = (value: string) => {
        this.props.onSelected(value);
        this.hideInsertPopup();
    };

    render() {
        //TODO Insert variable icon to be added
        const results = codeEditorVariablesList(this.state.serverNames, this.props.localNames ?? [], this.props.syntax, this.state.filter);
        const names = results.map((n, i) => <ListItem key={`item-${i}`} primaryText={n.display} onClick={() => this.handleSelected(n.code)} />);

        return (
            <div ref={this.setRef}>
                <IconButton toolTipContent="Insert a variable" onClick={this.showInsertPopup} icon={Icon.InsertVariable} />
                <Popover open={this.state.isInsertPopupOpen} anchorEl={this.showInsertPopupButton} onClose={this.hideInsertPopup} anchorOrigin={{ horizontal: "center", vertical: "bottom" }} transformOrigin={{ horizontal: "center", vertical: "top" }}>
                    <div className={styles.container} onKeyDown={this.onKeyDown}>
                        <div className={styles.filter}>
                            <FilterSearchBox autoFocus={true} value={this.state.filter} placeholder="Find..." onChange={(filter) => this.setState({ filter })} />
                        </div>
                        {this.state.isInsertPopupOpen && <List className={styles.menuContainer}>{names}</List>}
                    </div>
                </Popover>
            </div>
        );
    }

    private onKeyDown = (event: React.KeyboardEvent) => {
        if (event.keyCode === 27 /*esc*/) {
            this.setState({ isInsertPopupOpen: false });
        }
    };

    private setRef = (el: HTMLDivElement) => {
        this.showInsertPopupButton = el;
    };
}
