/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { repository } from "clientInstance";
import { RouteComponentProps } from "react-router";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent";
import { cloneDeep, sortBy, last } from "lodash";
import { FeaturesConfigurationResource, TaskResource } from "client/resources";
import { ActionButton, ActionButtonType } from "components/Button";
import { ExpandableFormSection, Summary, Note, BooleanRadioButtonGroup, RadioButton, Text, FormSectionHeading, RadioButtonGroup } from "components/form";
import ExternalLink from "components/Navigation/ExternalLink";
import InternalLink from "components/Navigation/InternalLink";
import DateFormatter from "utils/DateFormatter";
import Permission from "client/resources/permission";
import routeLinks from "../../../../routeLinks";
import { connect, MapStateToProps, MapDispatchToProps } from "react-redux";
import { bindActionCreators, Dispatch, Action } from "redux";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import { configurationActions, ConfigurationFeaturesState } from "../../reducers/configurationArea";
import { withTheme, OctopusTheme } from "components/Theme";
import { DynamicExtensionsFeatureMetadata, DynamicExtensionsFeaturesMetadataResource } from "client/resources/dynamicExtensionsFeaturesMetadataResource";
import { DynamicExtensionsFeaturesValuesResource } from "client/resources/dynamicExtensionsFeaturesValuesResource";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import ToolTip, { ToolTipPosition } from "primitiveComponents/dataDisplay/ToolTip";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout";
import Markdown from "components/Markdown";
import { EarlyAccessChip } from "components/Chips";
import Environment from "environment";
import { noOp } from "../../../../utils/noOp";
const styles = require("./style.less");

interface ExposedFeaturesLayoutProps {
    fullWidth?: boolean;
    dirtyTrackingDisabled?: boolean;
}

interface GlobalConnectedProps {
    features: ConfigurationFeaturesState;
}

interface GlobalDispatchProps {
    onFeaturesFetched: (features: FeaturesConfigurationResource) => void;
}

type FeaturesLayoutProps = GlobalConnectedProps & GlobalDispatchProps & ExposedFeaturesLayoutProps;

interface ApiResults {
    featuresConfiguration: FeaturesConfigurationResource;
    dynamicFeaturesMetadata: DynamicExtensionsFeaturesMetadataResource;
    dynamicFeaturesValues: DynamicExtensionsFeaturesValuesResource;
}

interface FeaturesState extends OptionalFormBaseComponentState<ApiResults> {
    lastSyncedTask?: TaskResource<{}>;
    redirectToTaskId?: string;
}

interface StepUiFrameworkFeatureFormSectionProps {
    enabled: boolean;
    onChange: (value: boolean) => void;
}

interface ExperimentalFeatureSection {
    enabled: boolean;
    render: () => React.ReactElement;
}

interface ExperimentalFeaturesGroupProps {
    featureSections: ExperimentalFeatureSection[];
    sectionTitle: string;
}

const experimentalFeaturesKonamiCode = ["ArrowUp", "ArrowUp", "ArrowDown", "ArrowDown", "ArrowLeft", "ArrowRight", "ArrowLeft", "ArrowRight"];

function ExperimentalFeaturesGroup(props: ExperimentalFeaturesGroupProps) {
    const { isKonamiCodeTriggered } = useKeyCombo(experimentalFeaturesKonamiCode);

    React.useEffect(() => {
        if (isKonamiCodeTriggered) {
            console.log("Features unlocked");
        }
    }, [isKonamiCodeTriggered]);

    const sections = props.featureSections.filter((x) => isKonamiCodeTriggered || Environment.isInDevelopmentMode() || x.enabled);

    return (
        <React.Fragment>
            {sections.length > 0 && <FormSectionHeading key="ExperimentalFeaturesGroup" title={props.sectionTitle} />}
            {sections.map((x) => x.render())}
        </React.Fragment>
    );
}

function StepUiFrameworkFeatureFormSection({ enabled, onChange }: StepUiFrameworkFeatureFormSectionProps) {
    return (
        <ExpandableFormSection
            key="IsStepUiFrameworkEnabled"
            errorKey="IsStepUiFrameworkEnabled"
            title="Step UI Framework"
            summary={enabled ? Summary.default("Enabled") : Summary.summary("Disabled")}
            help="Enable step UIs to be defined using step UI framework."
        >
            <BooleanRadioButtonGroup value={enabled} onChange={onChange}>
                <RadioButton value={true} label="Enabled" />
                <RadioButton value={false} label="Disabled" isDefault={true} />
            </BooleanRadioButtonGroup>
        </ExpandableFormSection>
    );
}

function useKeyCombo(konamiCode: string[]) {
    const [keys, setKeys] = React.useState<string[]>([]);
    const [isKonamiCodeTriggered, setKonamiCodeTriggered] = React.useState(false);

    const konamiCodeKeyHandler = React.useCallback((event: KeyboardEvent) => {
        let timeout: ReturnType<typeof setTimeout> | undefined = undefined;
        setKeys((prev) => [...prev, event.key]);
        clearTimeout(timeout);
        timeout = setTimeout(() => setKeys([]), 5000);
    }, []);

    React.useEffect(() => {
        window.document.addEventListener("keydown", konamiCodeKeyHandler);
        return () => window.document.removeEventListener("keydown", konamiCodeKeyHandler);
    });

    const isKonamiCode = isEqual(keys, konamiCode);

    React.useEffect(() => {
        isKonamiCode ? setKonamiCodeTriggered((prev) => true) : noOp();
    }, [isKonamiCode]);

    return { isKonamiCodeTriggered };
}

class FeaturesLayout extends FormBaseComponent<FeaturesLayoutProps, FeaturesState, ApiResults> {
    constructor(props: FeaturesLayoutProps) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const featuresConfiguration = await repository.FeaturesConfiguration.get();
            const dynamicFeaturesMetadata = await repository.DynamicExtensions.getFeaturesMetadata();
            const dynamicFeaturesValues = await repository.DynamicExtensions.getFeaturesValues();
            this.props.onFeaturesFetched(featuresConfiguration);

            if (this.props.features.isCommunityActionTemplatesEnabled) {
                await this.loadLastSyncedTask();
            }

            const configurationData: ApiResults = {
                featuresConfiguration,
                dynamicFeaturesMetadata,
                dynamicFeaturesValues,
            };

            this.setState({
                model: configurationData,
                cleanModel: cloneDeep(configurationData),
            });
        });
    }

    async componentWillReceiveProps(nextProps: FeaturesLayoutProps) {
        if (!this.props.features.isCommunityActionTemplatesEnabled && nextProps.features.isCommunityActionTemplatesEnabled) {
            await this.loadLastSyncedTask();
        }
    }

    renderIsCommunityActionTemplatesEnabled(theme: OctopusTheme) {
        return (
            <ExpandableFormSection
                key="IsCommunityActionTemplatesEnabled"
                errorKey="IsCommunityActionTemplatesEnabled"
                title="Community Step Templates"
                summary={this.state.model!.featuresConfiguration.IsCommunityActionTemplatesEnabled ? Summary.default("Enabled") : Summary.summary("Disabled")}
                help="Enable access to the Community Library."
            >
                <BooleanRadioButtonGroup
                    value={this.state.model!.featuresConfiguration.IsCommunityActionTemplatesEnabled}
                    onChange={(IsCommunityActionTemplatesEnabled) => this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, IsCommunityActionTemplatesEnabled } })}
                >
                    <RadioButton value={true} label="Enabled" isDefault={true} />
                    <RadioButton value={false} label="Disabled" />
                </BooleanRadioButtonGroup>
                <br />
                {this.state.model!.featuresConfiguration.IsCommunityActionTemplatesEnabled && (
                    <div>
                        {this.state.lastSyncedTask ? (
                            <Note>
                                {!this.state.lastSyncedTask.FinishedSuccessfully ? (
                                    <span className={styles.taskFailed}>
                                        <em className="fa fa-exclamation-triangle" style={{ color: theme.danger }} /> Last sync failed
                                    </span>
                                ) : (
                                    <span className={styles.taskSucceeded}>
                                        <em className="fa fa-check" style={{ color: theme.success }} /> Last sync succeeded
                                    </span>
                                )}
                                <InternalLink to={routeLinks.task(this.state.lastSyncedTask).root} className={styles.taskTime}>
                                    {" "}
                                    {DateFormatter.momentAgo(this.state.lastSyncedTask.CompletedTime!)}
                                </InternalLink>
                                <ActionButton label={"Sync now"} disabled={this.state.busy} onClick={() => this.synchronizeLibrarySteps()} />
                            </Note>
                        ) : (
                            <Note>
                                Not run
                                <ActionButton label={"Sync now"} disabled={this.state.busy} onClick={() => this.synchronizeLibrarySteps()} />
                            </Note>
                        )}
                    </div>
                )}
                <Note>
                    This feature requires internet to access the <ExternalLink href="CommunityLibrary">Community Library</ExternalLink>. Octopus will fetch and store the
                    <ExternalLink href="CommunityContributedStepTemplates"> community contributed steps</ExternalLink> locally, to be available when creating a deployment process and step templates.
                </Note>
            </ExpandableFormSection>
        );
    }

    renderIsBuiltInWorkerEnabled() {
        return (
            <ExpandableFormSection
                key="IsBuiltInWorkerEnabled"
                errorKey="IsBuiltInWorkerEnabled"
                title="Run steps on Octopus Server"
                summary={this.state.model!.featuresConfiguration.IsBuiltInWorkerEnabled ? Summary.default("Enabled") : Summary.summary("Disabled")}
                help="Enable steps to execute on the Octopus Server's built-in worker."
            >
                {isAllowed({ permission: Permission.AdministerSystem }) ? (
                    this.getBuiltInWorkerRadioButtons()
                ) : (
                    <ToolTip content="This feature can only be modified by the System Administrator" position={ToolTipPosition.Left}>
                        {this.getBuiltInWorkerRadioButtons()}
                    </ToolTip>
                )}
                <Note style={{ marginTop: "1rem" }}>
                    This feature enables Azure, AWS, Terraform and some scripts steps to use the
                    <ExternalLink href="BuiltinWorker"> built-in worker</ExternalLink> to run Calamari on the Octopus Server. If the built-in worker is disabled, these steps can't run on the Octopus Server and worker pools should be provisioned to
                    allow these steps to run. Learn more about <ExternalLink href="Worker">workers</ExternalLink>.
                </Note>
            </ExpandableFormSection>
        );
    }

    renderHelpSidebarSupportLink() {
        return (
            <ExpandableFormSection
                key="HelpSidebarSupportLink"
                errorKey="HelpSidebarSupportLink"
                title="Customize the Help Sidebar"
                summary={this.state.model!.featuresConfiguration.HelpSidebarSupportLink ? Summary.summary(this.state.model!.featuresConfiguration.HelpSidebarSupportLink) : Summary.default("Octopus support")}
                help="Customize the Help Sidebar support link in the Octopus web-portal UI."
            >
                <Text
                    value={this.state.model!.featuresConfiguration.HelpSidebarSupportLink!}
                    onChange={(x) => this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, HelpSidebarSupportLink: x } })}
                    label="Support Link"
                    error={this.getFieldError("HelpSidebarSupportLink")}
                    autoFocus={true}
                />
                <Note style={{ marginTop: "1rem" }}>
                    The sidebar includes a link to the Octopus support website by default. This feature allows you to customize the link destination. For example, in cases where you'd prefer users contact your own support system. If the sidebar
                    feature is disabled, this support link is located in the top profile menu.
                </Note>
            </ExpandableFormSection>
        );
    }

    renderIsHelpSidebarEnabled() {
        return (
            <ExpandableFormSection
                key="IsHelpSidebarEnabled"
                errorKey="IsHelpSidebarEnabled"
                title="Toggle the Help Sidebar"
                summary={this.state.model!.featuresConfiguration.IsHelpSidebarEnabled ? Summary.default("Enabled") : Summary.summary("Disabled")}
                help="Enable/disable the contextual help sidebar in the Octopus web-portal UI."
            >
                <BooleanRadioButtonGroup
                    value={this.state.model!.featuresConfiguration.IsHelpSidebarEnabled}
                    onChange={(IsHelpSidebarEnabled) => this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, IsHelpSidebarEnabled } })}
                >
                    <RadioButton value={true} label="Enabled" />
                    <RadioButton value={false} label="Disabled" />
                </BooleanRadioButtonGroup>
                <Note style={{ marginTop: "1rem" }}>
                    This feature provides your users with contextual-help to improve their understanding of Octopus' key concepts and includes easy access to the support and documentation resources. Users can dismiss the sidebar themselves.{" "}
                    <em>Disabling this feature is not recommended.</em>
                </Note>
            </ExpandableFormSection>
        );
    }

    private clearGitCache = async () => {
        await this.doBusyTask(async () => {
            await repository.ServerConfiguration.clearCache();
        });
    };

    renderIsConfigurationAsCodeEnabled() {
        return (
            <ExpandableFormSection
                key="isConfigurationAsCodeEnabled"
                errorKey="isConfigurationAsCodeEnabled"
                title="Configuration as Code"
                summary={this.state.model!.featuresConfiguration.IsConfigurationAsCodeEnabled ? Summary.summary("Enabled") : Summary.default("Disabled")}
                help="Enable version control in your projects."
                chip={<EarlyAccessChip />}
            >
                <BooleanRadioButtonGroup
                    value={this.state.model!.featuresConfiguration.IsConfigurationAsCodeEnabled}
                    onChange={(IsConfigurationAsCodeEnabled) => this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, IsConfigurationAsCodeEnabled } })}
                >
                    <RadioButton value={true} label="Enabled" />
                    <RadioButton value={false} label="Disabled" isDefault={true} />
                </BooleanRadioButtonGroup>
                <Note style={{ marginTop: "1rem" }}>This feature allows you to version control your projects with Git.</Note>

                {/* TODO: @configuration-as-code - Git-enabled runbooks are not currently supported. We will experiment with this in a later cycle. */}
                {/* <BooleanRadioButtonGroup
                        value={this.state.model!.featuresConfiguration.IsConfigurationAsCodeForRunbooksEnabled}
                        onChange={(IsConfigurationAsCodeForRunbooksEnabled) => this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, IsConfigurationAsCodeForRunbooksEnabled } })}
                    >
                        <RadioButton value={true} label="Enabled for Runbooks" />
                        <RadioButton value={false} label="Disabled for Runbooks" isDefault={true} />
                    </BooleanRadioButtonGroup>
                    <Note style={{ marginTop: "1rem" }}>This feature allows you to version control your projects' runbooks with Git.</Note> */}

                <ActionButton type={ActionButtonType.Secondary} title="Clear Local Git Cache" label="Clear Local Cache" onClick={this.clearGitCache} />
                <Note style={{ marginTop: "1rem" }}>After clearing the local Git cache, projects that are version controlled will be inaccessible until the refresh job runs on the server (Approximately ~1-2 minutes).</Note>
            </ExpandableFormSection>
        );
    }

    renderDynamicExtensions() {
        return this.state.model!.dynamicFeaturesMetadata.Features.map((metadata) => (
            <ExpandableFormSection key={metadata.Key} errorKey={`DynamicExtensions_${metadata.Key}`} title={metadata.Name} summary={Summary.summary(this.getDynamicFeatureOptionName(metadata))}>
                <RadioButtonGroup value={this.getDynamicFeaturesValue(metadata)} onChange={(value) => this.updateDynamicFeaturesValues(metadata.Key, value)}>
                    {Object.entries(metadata.Options).map(([key, value], i) => (
                        <RadioButton key={key} value={key} label={value} />
                    ))}
                </RadioButtonGroup>
                <Note style={{ marginTop: "1rem" }}>
                    <Markdown markup={metadata.Description} />
                </Note>
            </ExpandableFormSection>
        ));
    }

    render() {
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={routeLinks.task(this.state.redirectToTaskId).root} push={true} />;
        }

        return withTheme((theme) => (
            <FormPaperLayout
                title={"Features"}
                busy={this.state.busy}
                errors={this.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                savePermission={{ permission: Permission.ConfigureServer }}
                onSaveClick={this.handleSaveClick}
                saveText={"Saved"}
                expandAllOnMount={false}
                overFlowActions={[]}
                fullWidth={this.props.fullWidth}
                dirtyTrackingDisabled={this.props.dirtyTrackingDisabled}
            >
                {this.state.model && (
                    <TransitionAnimation>
                        <ExperimentalFeaturesGroup
                            sectionTitle={"Experimental"}
                            featureSections={[
                                {
                                    render: () => this.renderIsConfigurationAsCodeEnabled(),
                                    enabled: this.state.model?.featuresConfiguration?.IsConfigurationAsCodeEnabled === true,
                                },
                                {
                                    render: () => (
                                        <StepUiFrameworkFeatureFormSection
                                            key="StepUiFrameworkFeatureFormSection"
                                            enabled={this.state.model?.featuresConfiguration?.IsStepUiFrameworkEnabled === true}
                                            onChange={(enabled) => {
                                                this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, IsStepUiFrameworkEnabled: enabled } });
                                            }}
                                        />
                                    ),
                                    enabled: this.state.model?.featuresConfiguration?.IsStepUiFrameworkEnabled === true,
                                },
                            ]}
                        />
                        <FormSectionHeading title="Steps" />
                        {this.renderIsCommunityActionTemplatesEnabled(theme)}
                        {this.renderIsBuiltInWorkerEnabled()}
                        <FormSectionHeading title="Help Sidebar" />
                        {this.renderHelpSidebarSupportLink()}
                        {this.renderIsHelpSidebarEnabled()}
                        {this.state.model.dynamicFeaturesMetadata.Features.length > 0 && (
                            <>
                                <FormSectionHeading title="Dynamic Extensions" />
                                {this.renderDynamicExtensions()}
                            </>
                        )}
                    </TransitionAnimation>
                )}
            </FormPaperLayout>
        ));
    }

    private getDynamicFeatureOptionName(metadata: DynamicExtensionsFeatureMetadata): string {
        const optionKey = this.getDynamicFeaturesValue(metadata);
        const optionName = metadata.Options[optionKey];
        return optionName;
    }

    private getDynamicFeaturesValue(metadata: DynamicExtensionsFeatureMetadata): string {
        const key = this.state.model!.dynamicFeaturesValues.Values[metadata.Key];
        return isNil(key) ? metadata.Default : key;
    }

    private updateDynamicFeaturesValues(key: string, value: string) {
        this.setModelState({ dynamicFeaturesValues: { Values: { ...this.state.model!.dynamicFeaturesValues.Values, [key]: value } } });
    }

    private getBuiltInWorkerRadioButtons(): React.ReactNode {
        if (isAllowed({ permission: Permission.AdministerSystem })) {
            return (
                <BooleanRadioButtonGroup value={this.state.model!.featuresConfiguration.IsBuiltInWorkerEnabled} onChange={this.onIsBuiltInWorkerEnabledChanged}>
                    <RadioButton value={true} label="Enabled" />
                    <RadioButton value={false} label="Disabled" />
                </BooleanRadioButtonGroup>
            );
        } else {
            return (
                <Callout type={CalloutType.Information} title={"Permission required"}>
                    The {Permission.AdministerSystem} permission is required to {this.state.model!.featuresConfiguration.IsBuiltInWorkerEnabled ? "disable" : "enable"} the Built-in Worker.
                </Callout>
            );
        }
    }

    private onIsBuiltInWorkerEnabledChanged = (newValue: boolean) => {
        this.setModelState({
            featuresConfiguration: {
                ...this.state.model!.featuresConfiguration,
                IsBuiltInWorkerEnabled: newValue,
            },
        });
    };

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const featuresSaveResult = await repository.FeaturesConfiguration.modify(this.state.model!.featuresConfiguration);
            const valuesSaveResult = await repository.DynamicExtensions.putFeaturesValues(this.state.model!.dynamicFeaturesValues);
            this.props.onFeaturesFetched(featuresSaveResult);

            const configurationData: ApiResults = {
                featuresConfiguration: featuresSaveResult,
                dynamicFeaturesMetadata: this.state.model!.dynamicFeaturesMetadata,
                dynamicFeaturesValues: valuesSaveResult,
            };

            this.setState({
                model: configurationData,
                cleanModel: cloneDeep(configurationData),
            });
        });
    };

    private async synchronizeLibrarySteps() {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createSynchronizeCommunityStepTemplatesTask();
            this.setState({ redirectToTaskId: task.Id });
        });
    }

    private async loadLastSyncedTask() {
        const tasks = await repository.Tasks.filter({ name: "SyncCommunityActionTemplates", take: 1 });
        if (tasks.Items.length > 0) {
            const tasksByCompleted = sortBy(tasks.Items, "CompletedTime");
            const lastSyncedTask = last(tasksByCompleted);
            this.setState({ lastSyncedTask });
        }
    }
}

const mapGlobalStateToProps: MapStateToProps<GlobalConnectedProps, ExposedFeaturesLayoutProps, GlobalState> = (state) => {
    return {
        features: state.configurationArea.features,
    };
};

const mapGlobalActionDispatchersToProps: MapDispatchToProps<GlobalDispatchProps, {}> = (dispatch: Dispatch<Action<GlobalState>>) => bindActionCreators({ onFeaturesFetched: configurationActions.featuresFetched }, dispatch);

export default connect(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(FeaturesLayout);
