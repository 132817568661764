import { jiraStepPackage } from "components/Actions/jira/JiraServiceDeskChangeRequestStepUI";
import { UnstructuredStepResourceProperties } from "components/StepPackageEditor/Properties/UnstructuredStepResourceProperties";
import { AsStepUIProperties } from "components/StepPackageEditor/Properties/stepPropertiesMappedTypes";
import { StepPackage } from "components/StepPackageEditor/StepPackage/StepPackage";

class StepPackageRepository {
    getStepPackage(actionType: string): StepPackage<AsStepUIProperties<UnstructuredStepResourceProperties>> | null {
        switch (actionType) {
            case "Octopus.JiraIntegration.ServiceDeskAction":
                return asUntypedStepPackage(jiraStepPackage);
            default:
                return null;
        }
    }
}

export function asUntypedStepPackage<Properties>(stepPackage: StepPackage<Properties>): StepPackage<AsStepUIProperties<UnstructuredStepResourceProperties>> {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (stepPackage as unknown) as StepPackage<AsStepUIProperties<UnstructuredStepResourceProperties>>;
}

export const stepPackageRepository = new StepPackageRepository();
