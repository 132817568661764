/* eslint-disable no-restricted-imports */

import * as React from "react";
import MuiPopover, { PopoverClassKey } from "@material-ui/core/Popover";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import { makeStyles } from "@material-ui/core/styles";
import { noOp } from "utils/noOp";
import { Theme } from "theme";
import { useRequiredContext } from "hooks";
import { OctopusTheme, OctopusThemeContext } from "components/Theme";
import { useTransitionComponentOverride } from "primitiveComponents/transitions/overrideTransitions";
import { Fade } from "@material-ui/core";

export type Horizontal = "left" | "center" | "right";
export type Vertical = "top" | "center" | "bottom";

export interface Origin {
    horizontal: Horizontal;
    vertical: Vertical;
}

export interface PopoverProps {
    anchorEl?: HTMLElement | null;
    anchorOrigin?: Origin;
    className?: string;
    onClose?: () => void;
    open: boolean;
    style?: React.CSSProperties;
    transformOrigin?: Origin;
    disableAutoFocus?: boolean;
    disableEnforceFocus?: boolean;
    getUpdatePosition?: (callback: () => void) => void;
    TransitionComponent?: React.ComponentType<TransitionProps>;
}

const useStyles = makeStyles<Theme, OctopusTheme, PopoverClassKey>({ root: {}, paper: (theme) => ({ backgroundColor: theme.paper2 }) });

export const Popover: React.FC<PopoverProps> = (props) => {
    const theme = useRequiredContext(OctopusThemeContext, "OctopusThemeContext");
    const transitionComponent = useTransitionComponentOverride() ?? props.TransitionComponent ?? Fade; // Default to the same animation used in our global search.
    const classes = useStyles(theme);

    return (
        <MuiPopover
            classes={classes}
            action={(actions) => (props.getUpdatePosition && actions ? props.getUpdatePosition(actions.updatePosition) : noOp())}
            anchorEl={props.anchorEl}
            anchorOrigin={props.anchorOrigin}
            className={props.className}
            onClose={props.onClose}
            open={props.open}
            style={props.style}
            transformOrigin={props.transformOrigin}
            disableAutoFocus={props.disableAutoFocus}
            disableEnforceFocus={props.disableEnforceFocus}
            TransitionComponent={transitionComponent}
        >
            {props.children}
        </MuiPopover>
    );
};

export default Popover;
