/* eslint-disable @typescript-eslint/consistent-type-assertions */

import InsertVariableButton from "components/form/InsertVariableButton/InsertVariableButton";
import { ScriptingLanguage } from "components/scriptingLanguage";
import * as React from "react";

const styles = require("./style.less");

export interface VariableLookupProps {
    localNames?: string[];
    syntax?: ScriptingLanguage;
    textInputRef?(input: TextInputRef | null): void;
}

export interface TextInputRef {
    focus: () => void;
    insertAtCursor: (val: string) => void;
}

export interface GlobalConnectedProps {
    textInputRef(input: TextInputRef | null): void;
}

export function withVariableLookup() {
    return <TProps extends {}>(Comp: React.ComponentClass<TProps & GlobalConnectedProps> | React.StatelessComponent<TProps & GlobalConnectedProps>) => {
        const WithVariableLookup: React.FC<TProps & VariableLookupProps> = (props) => {
            const myTextInputRef = React.useRef<TextInputRef | null>(null);
            const { localNames, syntax, textInputRef, ...otherProps } = props;
            const insert = React.useCallback(
                (val: string) => {
                    if (myTextInputRef.current) {
                        myTextInputRef.current.insertAtCursor(val);
                        myTextInputRef.current.focus();
                    }
                },
                [myTextInputRef]
            );

            const innerComponentProps = {
                ...otherProps,
                textInputRef: (ref: TextInputRef | null) => {
                    myTextInputRef.current = ref;
                    if (textInputRef) textInputRef(ref);
                },
            };

            return (
                <div className={styles.container}>
                    <div className={styles.wide}>
                        <Comp key="component" {...(innerComponentProps as TProps & GlobalConnectedProps)} />
                    </div>
                    <div key="buttons" className={styles.buttons}>
                        <InsertVariableButton syntax={props.syntax} localNames={props.localNames} onSelected={(v) => insert(v)} />
                    </div>
                </div>
            );
        };
        return WithVariableLookup;
    };
}
