import { ActionedFields, ActionFields, LinkFields, OnboardingFields, StepFields, ViewFields, useAnalyticSession, StatusFields } from "./AnalyticSession";
import { usePage } from "components/Page/Page";
import { useAnalyticLinkLocation } from "./AnalyticLink";
import { OctopusError } from "client/resources";
import { useEffect } from "react";
import React from "react";
import IPageWrapper from "utils/pageId";
import { Errors, isErrors } from "components/DataBaseComponent/Errors";

export interface ViewEvent {
    resource: string;
}

export interface ViewProps extends ViewEvent {
    // Override the name by specifying manually
    name?: string;
}

type ResourceEvent = ViewEvent;

export enum Action {
    Add = "Add",
    Cancel = "Cancel",
    Deploy = "Deploy",
    Save = "Save",
    Select = "Select",
    Test = "Test",
}

export interface ActionData {
    target?: string;
    stepTemplate?: string;
}

export interface ActionEvent extends ResourceEvent {
    action: Action;
    data?: ActionData;
}

export enum ActionStatus {
    Success = "Success",
    Failed = "Failed",
}

interface TrackedActionEvent extends ActionEvent {
    error: string | undefined;
    statusCode: number | undefined;
    status: ActionStatus;
    duration: number;
}

export interface LinkEvent {
    linkLabel: string;
    documentUrl: string | undefined;
}

export interface StepEvent extends ResourceEvent {
    action: Action;
    stepCategory: string;
    stepTemplate: string | undefined;
}

export enum OnboardingType {
    step = "Onboarding Step",
}

export interface OnboardingEvent {
    linkLabel: string;
    onboardingType: OnboardingType;
}

type AnalyticsDispatchEvent<TEvent> = (name: string, event: TEvent) => void;

export type AnalyticErrorCallback = (error: string | OctopusError | Errors) => void;

export type AnalyticTrackedActionDispatcher = <T>(name: string, event: ActionEvent, inner: (cb: AnalyticErrorCallback) => Promise<T>) => Promise<T>;
export type AnalyticActionDispatcher = AnalyticsDispatchEvent<ActionEvent>;
export type AnalyticLinkDispatcher = AnalyticsDispatchEvent<LinkEvent>;
export type AnalyticStepDispatcher = AnalyticsDispatchEvent<StepEvent>;
export type AnalyticOnboardingDispatcher = AnalyticsDispatchEvent<OnboardingEvent>;
export type AnalyticViewDispatcher = AnalyticsDispatchEvent<ViewEvent>;

function getStatusText(code: number | undefined) {
    if (code === undefined) {
        return undefined;
    }

    switch (code) {
        case 400:
            return "Bad Request";
        case 401:
            return "Unauthorised";
        case 403:
            return "Forbidden";
        case 404:
            return "Not Found";
        case 409:
            return "Conflict";
        case 500:
            return "Server Error";
        case 503:
            return "Service Unavailable";
    }

    return "Error";
}

function toErrorCode(code: number | undefined) {
    return code === undefined ? undefined : `${code} - ${getStatusText(code)}`;
}

function DataFields(data?: ActionData) {
    return {
        ...Prop("Target Type", data?.target),
        ...Prop("Step Template", data?.stepTemplate),
    };
}

function PageFields(page: IPageWrapper) {
    // Strip any " (Keywords)" from the Area string.
    return {
        "Page Area": page?.Area.replace(/ *\(.*\)/, ""),
        "Page Name": page?.Name,
    };
}

export function useAnalyticTrackedActionDispatch(): AnalyticTrackedActionDispatcher {
    const session = useAnalyticSession();
    const page = usePage();

    function sendTrackedActionEvent(name: string, event: TrackedActionEvent) {
        if (!page) {
            return;
        }

        const fields: ActionFields = {
            "Action Type": event.action,
            "Duration (ms)": event.duration.toFixed(2),
            ...PageFields(page),
            "Resource Type": event.resource,
            Status: event.status,
            ...DataFields(event.data),
        };

        const status: StatusFields = {
            "Action Type": event.action,
            ...Prop("Error Code", toErrorCode(event.statusCode)),
            ...Prop("Error Messages", event.error),
            Status: event.status,
            "Resource Type": event.resource,
        };

        session.track(name, fields);
        session.track(event.status, status);
    }

    async function dispatch<T>(name: string, event: ActionEvent, inner: (cb: AnalyticErrorCallback) => Promise<T>): Promise<T> {
        const start = performance.now();
        let errorList: Array<string> = [];
        let statusCode: number | undefined = undefined;
        let status: ActionStatus = ActionStatus.Success;

        const addErrors = (errors?: Array<string>, error?: string) => {
            if (errors && errors.length > 0) {
                errorList = [...errorList, ...errors];
            } else if (error) {
                errorList = [...errorList, error];
            }
        };

        const onError = (error: string | OctopusError | Errors) => {
            status = ActionStatus.Failed;
            if (error instanceof OctopusError) {
                statusCode = statusCode ?? error.StatusCode;
                addErrors(error.Errors, error.ErrorMessage);
            } else if (isErrors(error)) {
                statusCode = statusCode ?? error.statusCode;
                addErrors(error.errors, error.message);
            } else {
                addErrors(undefined, error);
            }
        };

        try {
            return await inner(onError);
        } catch (e) {
            if (e instanceof OctopusError) {
                onError(e);
            } else if (e instanceof Error) {
                onError(e.message);
            }

            throw e;
        } finally {
            const error = errorList && errorList.length > 0 ? JSON.stringify(errorList) : undefined;
            sendTrackedActionEvent(name, { ...event, error, status, statusCode, duration: performance.now() - start });
        }
    }

    return dispatch;
}

export function useAnalyticActionDispatch(): AnalyticActionDispatcher {
    const session = useAnalyticSession();
    const page = usePage();

    return (name: string, event: ActionEvent) => {
        if (!page) {
            return;
        }

        const fields: ActionedFields = {
            "Action Type": event.action,
            ...PageFields(page),
            "Resource Type": event.resource,
            ...DataFields(event.data),
        };

        session.track(name, fields);
    };
}

export function useAnalyticLinkDispatch(): AnalyticLinkDispatcher {
    const session = useAnalyticSession();
    const linkLocation = useAnalyticLinkLocation();
    const page = usePage();

    return (name: string, event: LinkEvent) => {
        if (!page) {
            return;
        }

        const fields: LinkFields = {
            ...Prop("Document URL", event.documentUrl),
            "Link Label": event.linkLabel,
            "Link Location": linkLocation ?? "",
            ...PageFields(page),
        };

        session.track(name, fields);
    };
}

export function nameForDocument(href: string) {
    return `Visit doc - ${href}`;
}

export function nameForLink(linkLabel: string) {
    return `Navigate to ${linkLabel} page`;
}

export function nameForView(resource: string) {
    return `View ${resource}`;
}

export function useAnalyticStepDispatch(): AnalyticStepDispatcher {
    const session = useAnalyticSession();
    const page = usePage();

    return (name: string, event: StepEvent) => {
        if (!page) {
            return;
        }

        const fields: StepFields = {
            "Action Type": event.action,
            ...PageFields(page),
            "Resource Type": event.resource,
            "Step Category": event.stepCategory,
            ...Prop("Step Template", event.stepTemplate),
        };

        session.track(name, fields);
    };
}

export function useAnalyticOnboardingDispatch(): AnalyticOnboardingDispatcher {
    const session = useAnalyticSession();
    const linkLocation = useAnalyticLinkLocation();
    const page = usePage();

    return (name: string, event: OnboardingEvent) => {
        if (!page) {
            return;
        }

        const fields: OnboardingFields = {
            "Link Label": event.linkLabel,
            "Link Location": linkLocation ?? "",
            "Onboarding Type": event.onboardingType,
            ...PageFields(page),
        };

        session.track(name, fields);
    };
}

export function useAnalyticViewDispatch(): AnalyticViewDispatcher {
    const session = useAnalyticSession();

    return (name: string, event: ViewEvent) => {
        const fields: ViewFields = {
            "Resource Type": event.resource,
        };

        session.track(name, fields);
    };
}

// <AnalyticView> element allows for the sending of a once-off view event when the element is rendered on the page
export function AnalyticView(props: ViewProps) {
    const dispatch = useAnalyticViewDispatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => dispatch(props.name ?? nameForView(props.resource), { resource: props.resource }), []);

    return <></>;
}

function Prop(name: string, value?: string): object {
    return value !== undefined ? { [name]: value } : {};
}
