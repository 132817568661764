import * as React from "react";
import { WorkerPoolResource, TagSetResource, WorkerPoolsSummaryResource, ProcessType } from "client/resources";
import { repository } from "clientInstance";
import { ScriptModule } from "../Process/Common/SideBar";
import { ProjectResource } from "client/resources/projectResource";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { VariableSetContentType } from "client/resources/libraryVariableSetResource";
import { EnvironmentResource } from "client/resources/environmentResource";
import { LifecycleResource } from "client/resources/lifecycleResource";
import { ResourcesByNameOrId } from "client/repositories/basicRepository";
import { ChannelResource } from "client/resources/channelResource";
import { keyBy } from "lodash";
import * as tenantTagsets from "components/tenantTagsets";
import { RouteComponentProps } from "react-router";
import Permission from "client/resources/permission";
import { useProjectContext } from "../../context";
import { connect } from "react-redux";
import configurationSelectors from "areas/configuration/reducers/selectors";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import ProcessStepsLayout from "./ProcessStepsLayout";
import { ProcessContextFormPage } from "./Contexts/ProcessContextFormPage";
import StringHelper from "utils/StringHelper";
import { useOptionalRunbookContext } from "../Runbooks/RunbookContext";
import { ProcessQueryStringController, createDefaultFilter } from "./Contexts/ProcessQueryString/ProcessQueryStringContext";
import { ProcessPageSupportedActions } from "./types";
import { ProcessController } from "./Contexts/ProcessController";
import { ProjectRouteParams } from "areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import type { KeyedItemProps, NameOrIdKey } from "components/KeyAccessProvider/types";
import useKeyedItemAccessForConfigurationAsCode from "./Contexts/useKeyedItemAccessForConfigurationAsCode";
import KeyedItemAccessProvider from "components/KeyAccessProvider/KeyedItemAccessProvider";

interface GlobalConnectedProps {
    isBuiltInWorkerEnabled?: boolean;
}

interface ProcessState extends DataBaseComponentState {
    lookups: ProcessStepsLayoutLoaderLookupData;
}

interface ProcessProps extends RouteComponentProps<ProjectRouteParams>, GlobalConnectedProps {
    processId: string;
    processType: ProcessType;
}

type ProcessPageProps = ProcessProps;
type Props = ProcessProps & { project: Readonly<ProjectResource> } & KeyedItemProps;

export interface ProcessStepsLayoutLoaderLookupData {
    includedScriptModules: ScriptModule[];
    lifecyclePreview: LifecycleResource | null;
    environmentsByNameOrId: ResourcesByNameOrId<EnvironmentResource>;
    channelsByNameOrId: ResourcesByNameOrId<ChannelResource> | null;
    tagSets: TagSetResource[];
    workerPoolsByNameOrId: ResourcesByNameOrId<WorkerPoolResource>;
    machineRoles: string[];
    tagIndex: tenantTagsets.TagIndex;
    workerPoolsSummary: WorkerPoolsSummaryResource;
}

const loadData = async (project: ProjectResource, processType: ProcessType, accessItemsBy: NameOrIdKey): Promise<ProcessStepsLayoutLoaderLookupData> => {
    const includedScriptModules = isAllowed({ permission: Permission.LibraryVariableSetView, environment: "*", tenant: "*" })
        ? repository.LibraryVariableSets.all({
              contentType: VariableSetContentType.ScriptModule,
          }).then((sm) => sm.filter((x) => project.IncludedLibraryVariableSetIds.includes(x.Id)))
        : Promise.resolve([]);
    const environments = repository.Environments.all();
    const lifecyclePreview = processType === ProcessType.Deployment && isAllowed({ permission: Permission.LifecycleView }) ? repository.Lifecycles.get(project.LifecycleId).then((x) => repository.Lifecycles.preview(x)) : Promise.resolve(null);
    const channelsByNameOrId =
        processType === ProcessType.Deployment &&
        isAllowed({
            permission: Permission.ProcessView,
            project: project.Id,
            tenant: "*",
        })
            ? repository.Projects.getChannels(project).then((c) => keyBy(c.Items, (x) => x[accessItemsBy]))
            : Promise.resolve(null);
    const tagSets = tenantTagsets.getAll();
    const workerPools = repository.WorkerPools.all();
    return {
        environmentsByNameOrId: keyBy(await environments, accessItemsBy),
        includedScriptModules: await includedScriptModules,
        lifecyclePreview: await lifecyclePreview,
        channelsByNameOrId: await channelsByNameOrId,
        tagSets: await tagSets,
        workerPoolsByNameOrId: keyBy(await workerPools, accessItemsBy),
        machineRoles: await repository.MachineRoles.all(),
        tagIndex: await tenantTagsets.getTagIndex(),
        workerPoolsSummary: await repository.WorkerPools.summary(),
    };
};

const PageLoader = ProcessContextFormPage<ProcessStepsLayoutLoaderLookupData>();
const ProcessStepsLayoutDataLoader: React.FC<ProcessPageProps> = (props) => {
    const { state } = useProjectContext();
    const accessItemsBy = useKeyedItemAccessForConfigurationAsCode();
    const project = state.model;

    const runbookContext = useOptionalRunbookContext();
    const runbookName = runbookContext?.state.runbook?.Name ?? StringHelper.ellipsis;
    return (
        <KeyedItemAccessProvider accessItemsBy={accessItemsBy}>
            <PageLoader
                processType={props.processType}
                title={props.processId === ProcessType.Deployment ? "Process" : runbookName}
                load={() => loadData(project, props.processType, accessItemsBy)}
                renderWhenLoaded={(data) => <EnhancedProcessStepsLayoutLoader itemKey={accessItemsBy} initialLookups={data} project={project} {...props} />}
            />
        </KeyedItemAccessProvider>
    );
};

interface InitialLookupData {
    initialLookups: ProcessStepsLayoutLoaderLookupData;
}

class ProcessStepsLayoutLoader extends DataBaseComponent<Props & InitialLookupData, ProcessState> {
    constructor(props: Props & InitialLookupData) {
        super(props);
        this.state = {
            lookups: props.initialLookups,
        };
    }

    //TODO: @Cleanup: This is an artifact of the data-loading pattern we have here. Ideally this could potentially all be moved into context.
    supportedActions(): ProcessPageSupportedActions {
        const refreshLookupData = async () => {
            await this.doBusyTask(async () => this.setState({ lookups: await loadData(this.props.project, this.props.processType, this.props.itemKey) }));
        };

        return {
            refreshLookupData,
        };
    }

    render() {
        return (
            <ProcessController layoutActions={this.supportedActions()} processType={this.props.processType} id={this.props.processId} doBusyTask={this.doBusyTask} project={this.props.project}>
                {() => {
                    return (
                        <ProcessQueryStringController initialQueryFilter={createDefaultFilter()}>
                            {() => {
                                return (
                                    <ProcessStepsLayout
                                        lookups={this.state.lookups}
                                        errors={this.errors}
                                        busy={this.state.busy}
                                        doBusyTask={this.doBusyTask}
                                        history={this.props.history}
                                        location={this.props.location}
                                        match={this.props.match}
                                        isBuiltInWorkerEnabled={this.props.isBuiltInWorkerEnabled ?? false}
                                    />
                                );
                            }}
                        </ProcessQueryStringController>
                    );
                }}
            </ProcessController>
        );
    }
}

const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    return {
        isBuiltInWorkerEnabled: configurationSelectors.createFeatureEnabledSelector((t) => t.isBuiltInWorkerEnabled)(state),
    };
};

const EnhancedProcessStepsLayoutLoader = connect(mapGlobalStateToProps)(ProcessStepsLayoutLoader);

// We export our data-loader wrapper, as that wraps out layout and supplies the necessary data to the layout.
export default ProcessStepsLayoutDataLoader;
