import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useProjectContext, WithProjectContextInjectedProps } from "areas/projects/context";
import useConfigurationAsCodeFeatureFlag from "areas/configuration/hooks/useConfigurationAsCodeFeatureFlag";
import FormPaperLayout from "components/FormPaperLayout";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent";
import { BooleanRadioButtonGroup, ExpandableFormSection, MarkdownEditor, Note, RadioButton, Select, StringRadioButtonGroup, Summary, SummaryNode, Text, UnstructuredFormSection } from "components/form";
import { RoleMultiSelect } from "components/MultiSelect";
import FailureMode from "areas/projects/components/Releases/Deployments/FailureMode";
import { DeploymentModelType } from "areas/projects/components/Runbooks/RunbookRunNowLayout";
import { DeploymentProcessResource, GuidedFailureMode, Permission, ProjectResource } from "client/resources";
import { DeploymentActionPackageResource, deploymentActionPackages, displayName } from "client/resources/deploymentActionPackageResource";
import ExternalLink from "components/Navigation/ExternalLink";
import * as _ from "lodash";
import { RoleChip } from "components/Chips";
import { repository } from "clientInstance";
import { isAllowed, PermissionCheckProps } from "components/PermissionCheck/PermissionCheck";
import { flatten } from "lodash";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout";
import InternalLink from "components/Navigation/InternalLink";
import routeLinks from "routeLinks";
import { OverflowMenuItems } from "components/Menu";
import MoreInfo from "components/MoreInfo/MoreInfo";

interface DeploymentProcessSettingsModel {
    versionFromPackage: boolean;
    skipIfAlreadyInstalled: boolean;
    versioningStrategyTemplate: string;
    versioningStrategyPackage: DeploymentActionPackageResource | undefined;
    skipMachines: string;
    skipMachinesRoles: string[];
    allowDeploymentsToNoTargets: boolean;
    guidedFailureMode: GuidedFailureMode;
    excludeUnhealthyTargets: boolean;
    releaseNotesTemplate: string;
    deploymentChangesTemplate: string;
}

interface DeploymentProcessSettingsState extends OptionalFormBaseComponentState<DeploymentProcessSettingsModel> {
    machineRoles: string[];
    versionPackageActions: DeploymentActionPackageResource[];
    deploymentProcess: DeploymentProcessResource | null;
    project: ProjectResource | null;
}

type MatchProps = { match: NonNullable<ReturnType<typeof useRouteMatch>> | undefined };
type DeploymentProcessSettingsProps = MatchProps & WithProjectContextInjectedProps & { isConfigAsCodeEnabled: boolean };

export const defaultReleaseVersioningTemplate: string = "#{Octopus.Version.LastMajor}.#{Octopus.Version.LastMinor}.#{Octopus.Version.NextPatch}";

class DeploymentProcessSettingsInternal extends FormBaseComponent<DeploymentProcessSettingsProps, DeploymentProcessSettingsState, DeploymentProcessSettingsModel> {
    constructor(props: DeploymentProcessSettingsProps) {
        super(props);

        const { model: project } = this.props.projectContext.state;

        this.state = {
            machineRoles: [],
            versionPackageActions: [],
            deploymentProcess: null,
            project,
            model: this.buildModel(project),
            cleanModel: this.buildModel(project),
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const { model: project, projectContextRepository } = this.props.projectContext.state;

            const hasProcessViewPermissions = isAllowed({
                permission: Permission.ProcessView,
                project: project.Id,
                tenant: "*",
            });

            const [deploymentProcess, machineRoles] = await Promise.all<DeploymentProcessResource | null, string[]>([
                hasProcessViewPermissions ? projectContextRepository.DeploymentProcesses.get() : Promise.resolve(null),
                repository.MachineRoles.all(),
            ]);

            const versionPackageActions = deploymentActionPackages(
                _.chain(deploymentProcess && deploymentProcess.Steps)
                    .flatMap((step) => step.Actions)
                    .filter((action) => action.CanBeUsedForProjectVersioning)
                    .value()
            );

            this.setState({
                versionPackageActions,
                machineRoles,
                deploymentProcess,
            });
        });
    }

    render() {
        if (!this.state.model || !this.state.project) {
            return null;
        }

        return (
            <FormPaperLayout
                title="Deployments Settings"
                busy={this.state.busy}
                errors={this.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                onSaveClick={this.handleSaveClick}
                saveText="Project details updated"
                savePermission={this.editPermission()}
                overFlowActions={[
                    OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsForProjectDeploymentSettings(this.state.project.Id), undefined, {
                        permission: Permission.EventView,
                        wildcard: true,
                    }),
                ]}
            >
                {this.state.deploymentProcess && <InvalidConfigurationCallout deploymentProcess={this.state.deploymentProcess} project={this.state.project} />}
                <ExpandableFormSection errorKey="versionFromPackage" title="Release Versioning" summary={this.releaseVersioningSummary()} help="Select how the next release number is generated when creating a release.">
                    <ReleaseVersioning
                        deploymentProcess={this.state.deploymentProcess}
                        versionFromPackage={this.state.model.versionFromPackage}
                        versioningStrategyPackage={this.state.model.versioningStrategyPackage}
                        versioningStrategyTemplate={this.state.model.versioningStrategyTemplate}
                        versionPackageActions={this.state.versionPackageActions}
                        setModelProp={(m) => this.setModelState(m)}
                    />
                </ExpandableFormSection>

                <ExpandableFormSection
                    errorKey="releaseNotesTemplate"
                    title="Release Notes Template"
                    summary={this.state.model.releaseNotesTemplate ? Summary.summary("A release notes template has been specified") : Summary.placeholder("No release notes template provided")}
                    help={this.buildReleaseNotesTemplateHelpInfo()}
                >
                    <MarkdownEditor value={this.state.model.releaseNotesTemplate} accessibleName="Release Notes Template" onChange={(releaseNotesTemplate) => this.setChildState1("model", { releaseNotesTemplate })} />
                    <Note>
                        A release notes template is a convenient way to keep release notes consistent and avoid entering the same text repeatedly. The template generates default release notes, which can be modified on the create release page if
                        desired. See the <ExternalLink href="ReleaseNotes">documentation</ExternalLink> for more information.
                    </Note>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="allowDeploymentsToNoTargets" title="Deployment Targets Required" summary={this.deploymentTargetsSummary()} help="Choose if deployments are allowed if there are no deployment targets.">
                    <BooleanRadioButtonGroup accessibleName="deployment targets allowed" value={this.state.model.allowDeploymentsToNoTargets} onChange={(allowDeploymentsToNoTargets) => this.setModelState({ allowDeploymentsToNoTargets })}>
                        <RadioButton value={false} label="Deployments with no targets are not allowed" isDefault={true} />
                        <RadioButton value={true} label="Allow deployments to be created when there are no deployment targets" />
                    </BooleanRadioButtonGroup>
                    <Note>
                        If Deployments with no targets are not allowed is selected, creating the deployment will fail if there are steps which require targets and no targets with matching roles are available in the environment being deployed to. In
                        scenarios where <ExternalLink href="DynamicInfrastructure">infrastructure is created dynamically</ExternalLink> as part of the deployment, it may be desirable to allow creating deployments with no targets available.
                    </Note>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="skipMachines" title="Transient Deployment Targets" summary={this.skipMachinesSummary()} help="Choose to skip unavailable, or exclude unhealthy targets from the deployment.">
                    <StringRadioButtonGroup accessibleName="unavailable deployment targets" label="Unavailable Deployment targets" value={this.state.model.skipMachines} onChange={this.handleSkipMachinesChanged}>
                        <RadioButton value="None" label="Fail deployment" isDefault={true} />
                        <RadioButton value="SkipUnavailableMachines" label="Skip and continue" />
                        <Note>Deployment targets that are unavailable at the start of the deployment or become unavailable during the deployment will be skipped and removed from the deployment.</Note>
                    </StringRadioButtonGroup>
                    <Note>
                        <ExternalLink href="DynamicInfrastructure">Read more</ExternalLink> about deploying to elastic and transient environments.
                    </Note>
                    {this.state.model.skipMachines === "SkipUnavailableMachines" && (
                        <>
                            <RoleMultiSelect
                                onChange={(skipMachinesRoles) => this.setModelState({ skipMachinesRoles })}
                                value={this.state.model.skipMachinesRoles}
                                label="Skip unavailable deployment targets only in selected roles"
                                accessibleName="Roles to skip unavailable deployment targets"
                                items={this.state.machineRoles}
                            />
                            <Note>By default, deployment targets will be skipped if they are unavailable in all roles, to limit to certain roles select them here.</Note>
                        </>
                    )}
                    <StringRadioButtonGroup
                        accessibleName="unhealthy deployment targets"
                        value={this.state.model.excludeUnhealthyTargets ? "ExcludeUnhealthy" : "None"}
                        onChange={(skipUnhealthyTargets) => this.setModelState({ excludeUnhealthyTargets: skipUnhealthyTargets === "ExcludeUnhealthy" })}
                        label="Unhealthy Deployment Targets"
                    >
                        <RadioButton value="None" label="Do not exclude" isDefault={true} />
                        <RadioButton value="ExcludeUnhealthy" label="Exclude" />
                        <Note>Deployment targets that are unhealthy at the start of the deployment will be skipped and removed from the deployment.</Note>
                    </StringRadioButtonGroup>
                </ExpandableFormSection>

                <ExpandableFormSection
                    errorKey="deploymentChangesTemplate"
                    title="Deployment Changes Template"
                    summary={this.state.model.deploymentChangesTemplate ? Summary.summary("A deployment changes template has been specified") : Summary.placeholder("No deployment changes template provided")}
                    help={this.buildDeploymentChangesTemplateHelpInfo()}
                >
                    <MarkdownEditor value={this.state.model.deploymentChangesTemplate} label="Deployment changes template" onChange={(deploymentChangesTemplate) => this.setChildState1("model", { deploymentChangesTemplate })} />
                </ExpandableFormSection>

                <FailureMode guidedFailureMode={this.state.model.guidedFailureMode} onModeChanged={(guidedFailureMode) => this.setModelState({ guidedFailureMode })} title="Default Failure Mode" modelType={DeploymentModelType.Deployment} />
            </FormPaperLayout>
        );
    }

    private editPermission(): PermissionCheckProps {
        return {
            permission: Permission.ProjectEdit,
            project: this.state.project?.Id,
            tenant: "*",
        };
    }

    buildModel(project: ProjectResource): DeploymentProcessSettingsModel {
        if (!project.VersioningStrategy) {
            project.VersioningStrategy = { Template: defaultReleaseVersioningTemplate, DonorPackage: undefined };
        }

        if (!project.ProjectConnectivityPolicy) {
            project.ProjectConnectivityPolicy = {
                SkipMachineBehavior: "None",
                TargetRoles: [],
                AllowDeploymentsToNoTargets: false,
                ExcludeUnhealthyTargets: false,
            };
        }

        return {
            versionFromPackage: !!project.VersioningStrategy.DonorPackage,
            versioningStrategyTemplate: project.VersioningStrategy.Template,
            versioningStrategyPackage: project.VersioningStrategy.DonorPackage,
            skipIfAlreadyInstalled: project.DefaultToSkipIfAlreadyInstalled,
            allowDeploymentsToNoTargets: project.ProjectConnectivityPolicy.AllowDeploymentsToNoTargets,
            skipMachines: project.ProjectConnectivityPolicy.SkipMachineBehavior,
            skipMachinesRoles: project.ProjectConnectivityPolicy.TargetRoles,
            guidedFailureMode: project.DefaultGuidedFailureMode,
            excludeUnhealthyTargets: project.ProjectConnectivityPolicy.ExcludeUnhealthyTargets,
            releaseNotesTemplate: project.ReleaseNotesTemplate,
            deploymentChangesTemplate: project.DeploymentChangesTemplate,
        };
    }

    handleSaveClick = async () => {
        const model = this.state.model;

        if (!model || !this.state.project) {
            throw "no model loaded";
        }

        const project: ProjectResource = {
            ...this.state.project,
            DefaultToSkipIfAlreadyInstalled: model.skipIfAlreadyInstalled,
            VersioningStrategy: {
                DonorPackage: model.versionFromPackage ? model.versioningStrategyPackage : undefined,
                Template: model.versionFromPackage ? "" : model.versioningStrategyTemplate,
            },
            ProjectConnectivityPolicy: {
                AllowDeploymentsToNoTargets: model.allowDeploymentsToNoTargets,
                SkipMachineBehavior: model.skipMachines,
                TargetRoles: model.skipMachinesRoles,
                ExcludeUnhealthyTargets: model.excludeUnhealthyTargets,
            },
            DefaultGuidedFailureMode: model.guidedFailureMode,
            ReleaseNotesTemplate: model.releaseNotesTemplate,
            DeploymentChangesTemplate: model.deploymentChangesTemplate,
        };

        await this.doBusyTask(async () => {
            const result = await repository.Projects.save(project);
            await this.props.projectContext.actions.onProjectUpdated(result);

            this.setState(() => {
                return {
                    model: this.buildModel(result),
                    cleanModel: this.buildModel(result),
                    project: result,
                };
            });
        });
    };

    private handleSkipMachinesChanged = (skipMachines: string) => {
        this.setState((state) => {
            return {
                model: {
                    ...state.model,
                    skipMachines,
                    skipMachinesRoles: skipMachines === "None" ? [] : state.model?.skipMachinesRoles,
                },
            };
        });
    };

    private deploymentTargetsSummary(): SummaryNode {
        return this.state.model?.allowDeploymentsToNoTargets ? Summary.summary("Deployments with no target allowed") : Summary.default("Deployment target is required");
    }

    private skipMachinesSummary(): SummaryNode {
        if (this.state.model?.skipMachines !== "SkipUnavailableMachines") {
            return this.state.model?.excludeUnhealthyTargets ? Summary.summary("Deployment will exclude unhealthy targets, and fail if there is an unavailable target") : Summary.default("Deployment will fail if a deployment target is unavailable");
        }

        const roles = this.state.model.skipMachinesRoles;
        const summary = [this.state.model.excludeUnhealthyTargets ? <span key="skipMachines">Deployment will exclude unhealthy targets, and skip unavailable targets</span> : <span key="skipMachines">Deployment will skip unavailable targets</span>];

        if (roles.length > 0) {
            summary.push(this.state.model.skipMachinesRoles.length > 1 ? <span> in roles</span> : <span> in role</span>);

            roles.forEach((r) => {
                summary.push(<RoleChip role={r} key={"role-" + r} />);
            });
        }
        return Summary.summary(React.Children.toArray(summary));
    }

    private releaseVersioningSummary(): SummaryNode {
        if (this.state.model?.versionFromPackage) {
            const versioningPackage = this.state.model?.versioningStrategyPackage;
            return !!versioningPackage && !!versioningPackage.DeploymentAction
                ? Summary.summary(
                      <span>
                          Based on the package step <strong>{displayName(versioningPackage)}</strong>
                      </span>
                  )
                : Summary.summary("Based on the package in a step, please select a step");
        }
        const template = this.state.model?.versioningStrategyTemplate;
        return Summary.default(
            template ? (
                <span>Based on template {template}</span>
            ) : (
                <span>
                    Based on template, <strong>no template set</strong>
                </span>
            )
        );
    }

    private buildReleaseNotesTemplateHelpInfo(): string {
        return "Enter a template for the release notes that will be used for new releases.";
    }

    private buildDeploymentChangesTemplateHelpInfo() {
        return (
            <span>
                Enter a template for the markdown generated for each deployment's changes. The markdown can be accessed during a deployment using the <code>Octopus.Deployment.ChangesMarkdown</code> variable.{" "}
                <ExternalLink href="DeploymentChangesTemplate">Learn more</ExternalLink>
            </span>
        );
    }
}

class ReleaseVersioning extends React.Component<{
    setModelProp: <K extends keyof DeploymentProcessSettingsModel>(model: Pick<DeploymentProcessSettingsModel, K>) => void;
    versioningStrategyTemplate: string;
    versionFromPackage: boolean;
    versioningStrategyPackage: DeploymentActionPackageResource | undefined;
    versionPackageActions: DeploymentActionPackageResource[];
    deploymentProcess: DeploymentProcessResource | null;
}> {
    render() {
        return (
            <>
                <BooleanRadioButtonGroup accessibleName="release versioning strategy" value={this.props.versionFromPackage} onChange={(versionFromPackage) => this.props.setModelProp({ versionFromPackage })}>
                    <RadioButton value={false} label="Generate version numbers using a template" isDefault={true} />
                    <RadioButton value={true} label="Use the version number from an included package" />
                </BooleanRadioButtonGroup>
                <Note>
                    This setting controls the default version given to new releases.
                    <br />
                    The version can be generated from a template containing variable-expressions, or using the version from a package used by the deployment process.
                    <br />
                    See the <ExternalLink href="ReleaseVersioning">documentation</ExternalLink> for more information.
                </Note>
                {this.props.versionFromPackage && (
                    <Select
                        value={String(
                            this.props.versionPackageActions.findIndex((pa) => {
                                return pa.DeploymentAction === this.props.versioningStrategyPackage?.DeploymentAction && pa.PackageReference === this.props.versioningStrategyPackage.PackageReference;
                            })
                        )}
                        onChange={(packageActionIndex) => this.props.setModelProp({ versioningStrategyPackage: this.props.versionPackageActions[Number(packageActionIndex)] })}
                        items={this.props.versionPackageActions.map((pa, index) => ({
                            value: String(index),
                            text: displayName(pa),
                            disabled: this.stepIsDisabled(pa.DeploymentAction),
                        }))}
                        label="Versioning package step"
                    />
                )}
                {!this.props.versionFromPackage && (
                    <>
                        <Text value={this.props.versioningStrategyTemplate} onChange={(versioningStrategyTemplate) => this.props.setModelProp({ versioningStrategyTemplate })} label="Version template" />
                        {this.props.versioningStrategyTemplate !== defaultReleaseVersioningTemplate && (
                            <Note>
                                <a role="button" href="#" onClick={this.resetVersionTemplate}>
                                    Reset to default template
                                </a>
                            </Note>
                        )}
                        <MoreInfo showLabel="Show template variable information" hideLabel="Hide template variable information">
                            <div>
                                <p>You can use variables from the project (un-scoped or scoped only to a channel). In addition, some special variables are provided - example:</p>
                                <pre>
                                    <code>
                                        1.2.#{"{"}Octopus.Version.NextPatch{"}"}-pre
                                    </code>
                                </pre>
                                <p>These special variables take the form:</p>
                                <pre>
                                    <code>Octopus.Version.(Last|Next)(Major|Minor|Patch|Build|Revision|Suffix)</code>
                                </pre>
                                <p>If you are using channels, channel-specific special variables are also available: </p>
                                <pre>
                                    <code>Octopus.Version.Channel.(Last|Next)(Major|Minor|Patch|Build|Revision|Suffix)</code>
                                </pre>
                                <p>Version components from other channels in the project can be referenced using the channel name as the index:</p>
                                <pre>
                                    <code>Octopus.Version.Channel[ChannelName].(Last|Next)(Major|Minor|Patch|Build|Revision|Suffix)</code>
                                </pre>
                                <p>The channel name can also be used (generally as part of the suffix):</p>
                                <pre>
                                    <code>Octopus.Release.Channel.Name</code>
                                </pre>
                                <p>
                                    The version can also include Octopus <em>semantic version mask</em> characters
                                    <code>i</code> and <code>c</code> referring to the <strong>i</strong>ncremented and <strong>c</strong>urrent values of the version, respectively. For example:
                                </p>
                                <pre>2.1.c.i</pre>
                                <p>Finally, date fields can be also be used, for example: </p>
                                <pre>
                                    #{"{"}Octopus.Date.Year{"}"}.#{"{"}Octopus.Date.Month{"}"}.#{"{"}Octopus.Date.Day{"}"}
                                </pre>
                                <p>These take the form:</p>
                                <pre>
                                    <code>Octopus.Date.(Day|Month|Year|DayOfYear)</code>
                                    <br />
                                    <code>Octopus.Time.(Hour|Minute|Second)</code>
                                </pre>
                            </div>
                        </MoreInfo>
                    </>
                )}
            </>
        );
    }

    private stepIsDisabled = (deploymentAction: string) => {
        const action = _.chain(this.props.deploymentProcess?.Steps)
            .flatMap((step) => step.Actions)
            .find((x) => x.Name === deploymentAction)
            .value();
        return action?.IsDisabled ?? false;
    };

    private resetVersionTemplate = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.setModelProp({
            versioningStrategyTemplate: defaultReleaseVersioningTemplate,
        });
    };
}

const InvalidConfigurationCallout: React.FC<{ deploymentProcess: DeploymentProcessResource; project: ProjectResource }> = (props: { deploymentProcess: DeploymentProcessResource; project: ProjectResource }) => {
    const action = flatten(props.deploymentProcess.Steps.map((step) => step.Actions)).filter((a) => a.Name === props.project.VersioningStrategy.DonorPackage?.DeploymentAction);
    if (action && action.length > 0 && action[0].IsDisabled) {
        return (
            <UnstructuredFormSection stretchContent={true}>
                <Callout type={CalloutType.Warning} title="Invalid Configuration">
                    Step <InternalLink to={routeLinks.project(props.project).deployments.process.step(action[0].Id)}>{action[0].Name}</InternalLink> is currently used for release versioning, but it has been disabled.
                    <br />
                    Please re-enable the step, change the step used for release versioning, or change to using a version template.
                </Callout>
            </UnstructuredFormSection>
        );
    }

    return <></>;
};

const DeploymentProcessSettings: React.FC = () => {
    const match = useRouteMatch() ?? undefined;
    const projectContext = useProjectContext();
    const isConfigAsCodeEnabled = useConfigurationAsCodeFeatureFlag();

    return <DeploymentProcessSettingsInternal match={match} projectContext={projectContext} isConfigAsCodeEnabled={isConfigAsCodeEnabled} />;
};

export default DeploymentProcessSettings;
