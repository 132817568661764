import * as React from "react";
import { EnvironmentsSummaryResource } from "client/resources";
import { useEffect, useState } from "react";
import { OverviewCardLoading } from "../OverviewCardLoading";
import { EnvironmentsCardContent } from "./EnvironmentsCardContent";
import { EnvironmentSummaryCardProps } from "../DashboardOverviewProps";
import { RenderDashboardOverviewCard } from "../renderLogic";

export const EnvironmentsCard = ({ title, environmentsSummaryResourcePromise, showBeforeLoad, showIfEmpty, linkfunc }: EnvironmentSummaryCardProps) => {
    const [filterText, setFilterText] = useState<string>("");
    const [environmentsSummaryResource, setEnvironmentsSummaryResource] = useState<EnvironmentsSummaryResource>();
    const [cardIsEmpty, setCardIsEmpty] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        (async () => {
            const resource = await environmentsSummaryResourcePromise;
            if (resource !== undefined) {
                setEnvironmentsSummaryResource(resource);
                setCardIsEmpty(resource.EnvironmentSummaries.length === 0);
            }
        })();
    }, [environmentsSummaryResourcePromise]);

    const LoadingComponent = <OverviewCardLoading linkTo={linkfunc({})} title={title} />;
    const LoadedComponent = environmentsSummaryResource ? <EnvironmentsCardContent title={title} filterText={filterText} setFilterText={setFilterText} environmentsSummaryResource={environmentsSummaryResource} /> : <>/</>;
    return RenderDashboardOverviewCard(environmentsSummaryResource !== undefined, LoadingComponent, LoadedComponent, showIfEmpty, showBeforeLoad, cardIsEmpty);
};
