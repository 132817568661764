import React from "react";
import { TypedStepPackageEditor } from "components/StepPackageEditor/TypedStepPackageEditor";
import { AsStepResourceProperties } from "components/StepPackageEditor/Properties/stepPropertiesMappedTypes";
import { asStepResourceProperties, asStepUIProperties } from "components/StepPackageEditor/Properties/PropertiesConverters";
import { StepPackage } from "components/StepPackageEditor/StepPackage/StepPackage";

interface StepPackageEditorPropertyAdapterProps<StepUIProperties> {
    stepPackage: StepPackage<StepUIProperties>;
    properties: AsStepResourceProperties<StepUIProperties>;
    setProperties(properties: AsStepResourceProperties<StepUIProperties>): void;
    isExpandedByDefault: boolean;
}

export function StepPackageEditorPropertyAdapter<StepUIProperties>(props: StepPackageEditorPropertyAdapterProps<StepUIProperties>) {
    return (
        <TypedStepPackageEditor<StepUIProperties>
            stepUI={props.stepPackage.stepUI}
            properties={asStepUIProperties(props.stepPackage.inputSchema.properties, props.properties)}
            setProperties={(properties) => props.setProperties(asStepResourceProperties(props.stepPackage.inputSchema.properties, properties))}
            isExpandedByDefault={props.isExpandedByDefault}
        />
    );
}
