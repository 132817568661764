import { EnvironmentSummaryResource } from "client/resources";
import InternalLink from "components/Navigation/InternalLink";
import React from "react";
import routeLinks from "routeLinks";
import { OctopusTheme, withTheme } from "components/Theme";
import { OctopusIcon, OctopusIconType } from "primitiveComponents/dataDisplay/Icon";

const styles = require("../style.less");

interface EnvironmentOverviewRowsProps {
    environmentSummaries: EnvironmentSummaryResource[];
}

export const EnvironmentsCardContentRows = ({ environmentSummaries }: EnvironmentOverviewRowsProps) => {
    return (
        <>
            {environmentSummaries.map((environmentSummary: EnvironmentSummaryResource, index: number) => {
                const totalMachines = environmentSummary.TotalMachines || 0;
                return withTheme((theme: OctopusTheme) => (
                    <div className={styles.environmentRowsContainer} key={environmentSummary.Environment.Id}>
                        <div className={styles.environmentIcon} key={[environmentSummary.Environment.Id, index].join("-")}>
                            <OctopusIcon iconType={OctopusIconType.Environment} color={theme.iconDark} />
                        </div>
                        <div className={styles.environmentName} key={[environmentSummary.Environment.Id, index, index].join("-")}>
                            <InternalLink to={routeLinks.infrastructure.machines.filtered({ environmentIds: environmentSummary.Environment.Id })}>{environmentSummary.Environment.Name}</InternalLink>
                        </div>
                        <div className={styles.environmentMachinesCount} key={[environmentSummary.Environment.Id, index, index, index].join("-")}>
                            {totalMachines.toLocaleString()}
                        </div>
                    </div>
                ));
            })}
        </>
    );
};
