/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { useParams, useLocation } from "react-router";
import { repository } from "clientInstance";
import { AccountResource, AccountType, AzureServicePrincipalAccountResource, AzureSubscriptionAccountResource, SshKeyPairAccountResource, UsernamePasswordAccountResource, AmazonWebServicesAccountResource } from "client/resources/accountResource";
import URI from "urijs";
import SshKeyPairAccountEdit from "./SshKeyPairAccountEdit";
import UsernamePasswordAccountEdit from "./UsernamePasswordAccountEdit";
import TokenAccountEdit from "./TokenAccountEdit";
import AzureAccountEdit from "./AzureAccountEdit";
import AmazonWebServicesAccountEdit from "./AmazonWebServicesAccountEdit";
import { TokenAccountResource, AzureEnvironment, TenantResource, EnvironmentResource } from "../../../../client/resources";
import FormPage from "components/FormPage/FormPage";
import BaseComponent from "components/BaseComponent";
import { ErrorPanel } from "components/form";
import InfrastructureLayout, { InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";

interface AccountRouteParams {
    accountId: string | undefined;
}

interface AccountEditProps {
    initialData: InitialData;
}

interface NewAccountData {
    accountType: AccountType;
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    azureEnvironments: AzureEnvironment[];
}

interface AccountData extends NewAccountData {
    account: AccountResource;
}

type InitialData = NewAccountData | AccountData;

const Title = "Accounts";
const AccountEditFormPage = FormPage<InitialData>();

const IsNew = "IsNew";

interface NewAccountEditProps {
    createNew: true;
    accountType: AccountType;
}

interface ExistingAccountEditProps {
    createNew: false;
    accountId: string;
}

const AccountEdit: React.FC<NewAccountEditProps | ExistingAccountEditProps> = (props) => {
    return (
        <AccountEditFormPage
            title={Title}
            load={async () => {
                const environments = repository.Environments.all();
                const tenants = repository.Tenants.all();
                const azureEnvironments = repository.Accounts.getIsolatedAzureEnvironments();

                if (!props.createNew) {
                    const account = await repository.Accounts.get(props.accountId);
                    return {
                        environments: await environments,
                        tenants: await tenants,
                        azureEnvironments: await azureEnvironments,
                        account,
                        accountType: account.AccountType,
                    };
                } else {
                    return {
                        environments: await environments,
                        tenants: await tenants,
                        azureEnvironments: await azureEnvironments,
                        accountType: props.accountType,
                    };
                }
            }}
            reloadOnPropChanges={true} // Our accountType prop needs to force a reload.
            renderWhenLoaded={(data) => <AccountEditInternal initialData={data} />}
            renderAlternate={(args) => <InfrastructureLayoutBusy title={Title} {...args} />}
        />
    );
};

class AccountEditInternal extends BaseComponent<AccountEditProps> {
    constructor(props: AccountEditProps) {
        super(props);
    }

    hasAccount(check: object): check is AccountData {
        return (check as AccountData).account !== undefined;
    }

    render() {
        const data = this.props.initialData;
        const account = this.hasAccount(data) ? data.account : IsNew;

        switch (this.props.initialData.accountType) {
            case AccountType.SshKeyPair:
                return <SshKeyPairAccountEdit account={account as SshKeyPairAccountResource} environments={data.environments} tenants={data.tenants} azureEnvironments={data.azureEnvironments} />;
            case AccountType.Token:
                return <TokenAccountEdit account={account as TokenAccountResource} environments={data.environments} tenants={data.tenants} azureEnvironments={data.azureEnvironments} />;
            case AccountType.UsernamePassword:
                return <UsernamePasswordAccountEdit account={account as UsernamePasswordAccountResource} environments={data.environments} tenants={data.tenants} azureEnvironments={data.azureEnvironments} />;
            case AccountType.AzureSubscription:
            case AccountType.AzureServicePrincipal:
                return <AzureAccountEdit account={account as AzureServicePrincipalAccountResource | AzureSubscriptionAccountResource} environments={data.environments} tenants={data.tenants} azureEnvironments={data.azureEnvironments} />;
            case AccountType.AmazonWebServicesAccount:
                return <AmazonWebServicesAccountEdit account={account as AmazonWebServicesAccountResource} environments={data.environments} tenants={data.tenants} azureEnvironments={data.azureEnvironments} />;
            default:
                return <ErrorPanel message="Invalid path" />;
        }
    }
}

export default AccountEdit;
