import * as React from "react";
import { enforceNewActionFeatures } from "components/ActionPropertiesEditor/enforceNewActionFeatures";
import { ActionPlugin, ActionScope } from "components/Actions/pluginRegistry";
import { ActionPropertiesEditor } from "components/ActionPropertiesEditor/ActionPropertiesEditor";
import ActionProperties from "client/resources/actionProperties";
import { PackageReference } from "client/resources/packageReference";
import { Errors } from "components/DataBaseComponent";
import { ActionTemplateParameterResource } from "client/resources";

interface ActionTemplateEditorInternalProps {
    localNames: string[];
    plugin: ActionPlugin;
    properties: ActionProperties;
    packages: Array<PackageReference>;
    errors: Errors | undefined; // only used for shouldComponentUpdate
    busy: Promise<unknown> | boolean | undefined;
    expandedByDefault: boolean;
    parameters: ActionTemplateParameterResource[];
    getFieldError(field: string): string;
    setProperties(properties: Partial<ActionProperties>, initialise?: boolean, callback?: () => void): void;
    setPackages(packages: Array<PackageReference>, initialise?: boolean): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    isNew: boolean;
    scope: ActionScope;
}

export class ActionTemplatePropertiesEditor extends React.Component<ActionTemplateEditorInternalProps, never> {
    componentDidMount() {
        const properties = { ...this.props.properties };

        const enabledFeatures = enforceNewActionFeatures(this.props.plugin, this.props.scope, this.props.properties, this.props.isNew);
        if (!!enabledFeatures) {
            // Server will strip empty properties, so only set this if we need to, otherwise this triggers our save-changes dialog unnecessarily.
            properties["Octopus.Action.EnabledFeatures"] = enforceNewActionFeatures(this.props.plugin, this.props.scope, this.props.properties, this.props.isNew);
        }

        this.props.setProperties(properties, true);
    }

    render() {
        if (!this.props.plugin.edit) {
            throw new Error(`Plugin ${this.props.plugin.actionType} is missing 'Edit' property`);
        }
        return (
            <ActionPropertiesEditor
                plugin={this.props.plugin}
                localNames={this.props.localNames}
                properties={this.props.properties}
                packages={this.props.packages}
                doBusyTask={this.props.doBusyTask}
                busy={this.props.busy}
                setProperties={this.props.setProperties}
                setPackages={this.props.setPackages}
                getFieldError={this.props.getFieldError}
                errors={this.props.errors}
                expandedByDefault={this.props.expandedByDefault}
                parameters={this.props.parameters}
            />
        );
    }
}
