//TODO: #config-as-code: Re-introduce this only when we need branch support for runbook processes.

import * as React from "react";
import { RedirectAs404 } from "components/NotFound/NotFound";
import { BranchAwareRedirect } from "../ProjectsRoutes/BranchAwareRedirect";
import { RouteComponentProps, Switch, withRouter } from "react-router-dom";
import { runbookLinks } from "../OperationsRoute";
import ReloadableRoute from "../../../../components/ReloadableRoute";
import RunbookProcessRoute from "./RunbookProcessRoute";
import RunbookSettingsLayout from "./RunbookSettingsLayout";
import { withPage } from "../../../../components/Page/Page";
import pageIds from "../../../../pageIds";

export const RunbookSettingsPage = withPage({ page: pageIds.project().runbook.settings })(RunbookSettingsLayout);

class RunbookSettingsAndProcessRoutes extends React.Component<{ path: string }> {
    render() {
        const links = runbookLinks(this.props.path);
        return (
            <Switch>
                <ReloadableRoute path={links.settings} render={(routeProps: RouteComponentProps<{ runbookId: string }>) => <RunbookSettingsPage runbookId={routeProps.match.params.runbookId} />} />
                <RunbookProcessRoute path={links.runbookProcess.runbookProcess(":processId").root} />
            </Switch>
        );
    }
}

//TODO: #config-as-code - Re-introduce only when versioning is supported for runbook processes.
// class RunbookRoutesWithBranching extends React.Component<RunbookRoutingProps> {
//     render() {
//         const links = runbookLinks(this.props.path);
//         return (
//             <BranchAwareRedirect>
//                 <Switch>
//                     <ReloadableRoute path={links.root} render={(props) => <RunbookContextLayout {...props}>{(context) => <RunbookSettingsAndProcessRoutes path={links.root} />}</RunbookContextLayout>} />
//                     <RedirectAs404 />
//                 </Switch>
//             </BranchAwareRedirect>
//         );
//     }
// }
//const EnhancedRunbookRoutesWithBranching = withRouter(RunbookRoutesWithBranching);

export { RunbookSettingsAndProcessRoutes };
