import Client from "../client";
import DeploymentProcessResource from "../resources/deploymentProcessResource";
import { ReleaseTemplateResource, ProjectResource, VcsBranchResource, ReleaseResource } from "client/resources";
import { CommitResource } from "client/resources/commitResource";
import { OctopusValidationResponse } from "client/resources/octopusValidationResponse";

export class DeploymentProcessRepository {
    readonly resourceLink = "DeploymentProcess";
    readonly collectionLink = "DeploymentProcesses";

    constructor(private readonly client: Client, private readonly project: ProjectResource, private readonly branch: VcsBranchResource | undefined) {
        this.client = client;
    }

    get(): Promise<DeploymentProcessResource> {
        if (this.project.IsVersionControlled && this.branch !== undefined) {
            return this.client.get(this.branch.Links[this.resourceLink]);
        }

        return this.client.get(this.project.Links[this.resourceLink]);
    }

    getForRelease(release: ReleaseResource): Promise<DeploymentProcessResource> {
        return this.client.get<DeploymentProcessResource>(this.client.getLink(this.collectionLink), { id: release.ProjectDeploymentProcessSnapshotId });
    }

    getTemplate(deploymentProcess: DeploymentProcessResource, channelId: string, releaseId: string) {
        return this.client.get<ReleaseTemplateResource>(deploymentProcess.Links["Template"], { channel: channelId, releaseId });
    }

    modify(deploymentProcess: DeploymentProcessResource, commitMessage?: string): Promise<DeploymentProcessResource> {
        let resource: DeploymentProcessResource | CommitResource<DeploymentProcessResource> = deploymentProcess;
        if (this.project.IsVersionControlled) {
            resource = { Resource: deploymentProcess, CommitMessage: commitMessage };
        }
        return this.client.update(deploymentProcess.Links.Self, resource);
    }

    validate(deploymentProcess: DeploymentProcessResource) {
        return this.client.post<OctopusValidationResponse>(deploymentProcess.Links["Validation"], { ...deploymentProcess });
    }
}

export default DeploymentProcessRepository;
