/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import React, { useState, useEffect } from "react";
import { ScopeValues } from "client/resources/variableSetResource";
import { ScopeSpecification } from "areas/variables/ReadonlyVariableResource";
import { AdvancedTenantTagsSelector } from "components/AdvancedTenantSelector/AdvancedTenantSelector";
import EnvironmentMultiSelect from "components/MultiSelect/EnvironmentMultiSelect";
import RoleMultiSelect from "components/MultiSelect/RoleMultiSelect";
import MachineMultiSelect from "components/MultiSelect/MachineMultiSelect";
import ChannelMultiSelect from "components/MultiSelect/ChannelMultiSelect";
import StepMultiSelect from "components/MultiSelect/StepMultiSelect";
import { DoBusyTask } from "components/DataBaseComponent/DataBaseComponent";
import { FocusableComponent } from "components/VirtualListWithKeyboard/FocusableComponent";
import { TagResource, TagSetResource } from "../../../client/resources";
import * as tenantTagsets from "../../../components/tenantTagsets";
import { keyBy } from "lodash";
import ProcessMultiSelect from "components/MultiSelect/ProcessMultiSelect";
import { VariableType } from "../../../client/resources/variableResource";
import { useOptionalProjectContext } from "areas/projects/context";
import { useVariableEditorScoping } from "../VariableEditor/VariableEditorScopingContext";
import { isProjectScopedOptions } from "../VariableEditor/types";

const styles = require("./style.less");

interface ScopeSelectorProps {
    value: ScopeSpecification;
    availableScopes: ScopeValues;
    allowTenantTagSelection: boolean;
    useCompactControls: boolean;
    variableType: VariableType;
    firstComponent?: FocusableComponent;
    firstInputRef?(component: FocusableComponent | null): void;
    doBusyTask: DoBusyTask;
    onScopeSelected: (scope: ScopeSpecification) => void;
}

interface TagSet {
    Tags: TagResource[];
    Description: string;
    SortOrder: number;
    Name: string;
    Id: string;
    Links: {
        [name: string]: string;
    };
}

const ScopeSelector: React.FC<ScopeSelectorProps> = (props) => {
    const handleEnvironmentsChanged = (environments: string[]) => {
        changeScope((s) => ({ ...s, Environment: environments }));
    };

    const handleRolesChanged = (roles: string[]) => {
        changeScope((s) => ({ ...s, Role: roles }));
    };

    const handleMachinesChanged = (machines: string[]) => {
        changeScope((s) => ({ ...s, Machine: machines }));
    };

    const handleStepsChanged = (steps: string[]) => {
        changeScope((s) => ({ ...s, Action: steps }));
    };

    const handleChannelsChanged = (channels: string[]) => {
        changeScope((s) => ({ ...s, Channel: channels }));
    };

    const handleTenantTagsChanged = (tenantTags: string[]) => {
        changeScope((s) => ({ ...s, TenantTag: tenantTags }));
    };

    const handleProcessChanged = (processes: string[]) => {
        changeScope((s) => ({ ...s, ProcessOwner: processes }));
    };

    const changeScope = (updateScope: (scope: ScopeSpecification) => ScopeSpecification) => {
        props.onScopeSelected(updateScope(props.value));
    };

    const [tagSets, setTagSets] = useState<TagSet[] | null>(null);

    useEffect(
        () => {
            async function allowTenantTagSelection() {
                if (props.allowTenantTagSelection) {
                    await props.doBusyTask(async () => {
                        const tenantTagMap = keyBy(props.availableScopes.TenantTags, (tag) => tag.Id);
                        const tagSets = await tenantTagsets.getAll();
                        setTagSets(tagSets.map((ts) => ({ ...ts, Tags: ts.Tags.filter((tag) => !!tenantTagMap[tag.CanonicalTagName]) })));
                    });
                }
            }

            allowTenantTagSelection();
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const scoping = useVariableEditorScoping();

    const getVersionControlledProjectHelperText = () => {
        if (isProjectScopedOptions(scoping) && scoping.isVersionControlled) {
            return "Not available for a version controlled project.";
        }
    };

    const showRoleSelect = props.variableType !== VariableType.WorkerPool || !!props.value.Role;
    const showMachineSelect = props.variableType !== VariableType.WorkerPool || !!props.value.Machine;

    return (
        <div className={styles.scopeContainer}>
            <div className={props.allowTenantTagSelection ? styles.firstColumn : styles.firstColumnFullWidth}>
                <EnvironmentMultiSelect
                    multiSelectRef={props.firstInputRef}
                    value={props.value.Environment ? [...(props.value.Environment as string[])] : []}
                    items={props.availableScopes.Environments}
                    onChange={(e) => handleEnvironmentsChanged(e)}
                    openOnFocus={false}
                    hideFloatingLabel={props.useCompactControls}
                />
                {showRoleSelect && (
                    <RoleMultiSelect
                        value={props.value.Role ? [...(props.value.Role as string[])] : []}
                        items={props.availableScopes.Roles.map((r) => r.Id)}
                        onChange={(e) => handleRolesChanged(e)}
                        openOnFocus={false}
                        canAdd={true}
                        hideFloatingLabel={props.useCompactControls}
                    />
                )}
                {showMachineSelect && (
                    <MachineMultiSelect
                        value={props.value.Machine ? [...(props.value.Machine as string[])] : []}
                        items={props.availableScopes.Machines}
                        onChange={(m: any) => handleMachinesChanged(m)}
                        openOnFocus={false}
                        hideFloatingLabel={props.useCompactControls}
                    />
                )}
                {isProjectScopedOptions(scoping) && (
                    <ProcessMultiSelect
                        value={props.value.ProcessOwner ? [...(props.value.ProcessOwner as string[])] : []}
                        items={props.availableScopes.Processes}
                        openOnFocus={false}
                        hideFloatingLabel={props.useCompactControls}
                        onChange={handleProcessChanged}
                        disabled={scoping.isVersionControlled}
                        helperText={getVersionControlledProjectHelperText()}
                    />
                )}
                {isProjectScopedOptions(scoping) && (
                    <StepMultiSelect
                        value={props.value.Action ? [...(props.value.Action as string[])] : []}
                        items={props.availableScopes.Actions}
                        onChange={(s: any) => handleStepsChanged(s)}
                        openOnFocus={false}
                        hideFloatingLabel={props.useCompactControls}
                        disabled={scoping.isVersionControlled}
                        helperText={getVersionControlledProjectHelperText()}
                    />
                )}
                {isProjectScopedOptions(scoping) && (
                    <ChannelMultiSelect
                        value={props.value.Channel ? [...(props.value.Channel as string[])] : []}
                        items={props.availableScopes.Channels}
                        onChange={(s: any) => handleChannelsChanged(s)}
                        openOnFocus={false}
                        hideFloatingLabel={props.useCompactControls}
                    />
                )}
            </div>
            {props.allowTenantTagSelection && tagSets && (
                <div className={styles.secondColumn}>
                    <AdvancedTenantTagsSelector
                        tagSets={tagSets}
                        selectedTenantTags={props.value.TenantTag ? [...(props.value.TenantTag as string[])] : []}
                        doBusyTask={props.doBusyTask}
                        onChange={(tenantTags) => handleTenantTagsChanged(tenantTags)}
                        showPreviewButton={true}
                    />
                </div>
            )}
        </div>
    );
};

export default ScopeSelector;
