import BasicRepository, { ListArgs } from "./basicRepository";
import Client from "../client";
import { ChannelResource, ResourceCollection, ReleaseResource, VersionRuleTestResponse } from "client/resources";
import { FeedType } from "client/resources/feedResource";

export type ReleasesListArgs = {
    searchByVersion?: string;
} & ListArgs;

export type SearchOptions = {
    version: string;
    versionRange: string;
    preReleaseTag: string;
    feedType: FeedType;
};

class ChannelRepository extends BasicRepository<ChannelResource, ChannelResource> {
    constructor(client: Client) {
        super("Channels", client);
    }
    ruleTest(searchOptions: SearchOptions) {
        return this.client.post<VersionRuleTestResponse>(this.client.getLink("VersionRuleTest"), searchOptions);
    }
    getReleases(channel: ChannelResource, options?: ReleasesListArgs): Promise<ResourceCollection<ReleaseResource>> {
        return this.client.get(channel.Links["Releases"], options);
    }
}

export default ChannelRepository;
