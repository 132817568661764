import Client from "../client";
import { NewVcsRunbookResource, ProjectResource, ResourceCollection, VcsBranchResource, VcsRunbookResource } from "client/resources";
import { CommitResource, OnlyCommitResource } from "client/resources/commitResource";

class VcsRunbookRepository {
    constructor(private readonly client: Client, private readonly project: ProjectResource, private readonly branch: VcsBranchResource | undefined) {
        this.client = client;
    }

    private getBranch() {
        if (!this.branch) throw new Error("Can't use VCS Runbook Repository unless there is a branch available in the VCS Project");
        return this.branch;
    }

    create(newVcsRunbook: NewVcsRunbookResource, commitMessage: string): Promise<VcsRunbookResource> {
        const resource: CommitResource<NewVcsRunbookResource> = { Resource: newVcsRunbook, CommitMessage: commitMessage };
        return this.client.create(this.getBranch().Links.Runbook, resource, {});
    }
    get(id: string): Promise<VcsRunbookResource> {
        return this.client.get(this.getBranch().Links.Runbook, { id });
    }
    list(args?: { skip?: number; take?: number }): Promise<ResourceCollection<VcsRunbookResource>> {
        return this.client.get<ResourceCollection<VcsRunbookResource>>(this.getBranch().Links.Runbook, args);
    }
    modify(vcsRunbook: VcsRunbookResource, commitMessage: string): Promise<VcsRunbookResource> {
        const resource: CommitResource<VcsRunbookResource> = { Resource: vcsRunbook, CommitMessage: commitMessage };
        return this.client.update(vcsRunbook.Links.Self, resource);
    }
    del(vcsRunbook: VcsRunbookResource, commitMessage: string) {
        const resource: OnlyCommitResource = { CommitMessage: commitMessage };
        return this.client.del(vcsRunbook.Links.Self, resource);
    }
}

export { VcsRunbookRepository };
