const SpecialVariables = {
    Action: {
        TargetRoles: "Octopus.Action.TargetRoles",
        RunOnServer: "Octopus.Action.RunOnServer",
        HealthCheck: {
            Type: "Octopus.Action.HealthCheck.Type",
        },
        Email: {
            To: "Octopus.Action.Email.To",
            ToTeamIds: "Octopus.Action.Email.ToTeamIds",
            CC: "Octopus.Action.Email.CC",
            CCTeamIds: "Octopus.Action.Email.CCTeamIds",
            Bcc: "Octopus.Action.Email.Bcc",
            BccTeamIds: "Octopus.Action.Email.BccTeamIds'",
        },
        MaxParallelism: "Octopus.Action.MaxParallelism",
        Certificate: {
            Variable: "Octopus.Action.Certificate.Variable",
            StoreLocation: "Octopus.Action.Certificate.StoreLocation",
        },
    },
};

export default SpecialVariables;
