import { OctopusTheme, withTheme } from "components/Theme";
import BusyIndicator from "components/BusyIndicator";
import InternalLink from "components/Navigation/InternalLink";
import Section from "components/Section";
import React from "react";

const styles = require("./style.less");

interface OverViewCardLoadingProps {
    linkTo: string;
    title: string;
}

export const OverviewCardLoading = ({ linkTo, title }: OverViewCardLoadingProps) => {
    return withTheme((theme: OctopusTheme) => (
        <div className={styles.card}>
            <div className={styles.cardTitleContainer}>
                <div className={styles.cardTitle}>
                    <InternalLink to={linkTo} color={theme.whiteConstant} accessibleName="LoadingCard">
                        {title}
                    </InternalLink>
                </div>
            </div>
            <Section>
                <BusyIndicator inline={true} show={true} />
            </Section>
        </div>
    ));
};
