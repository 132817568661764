/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-eq-null */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import FormBaseComponent, { OptionalFormBaseComponentState } from "../FormBaseComponent/FormBaseComponent";
import * as React from "react";
import Text, { DebounceText } from "primitiveComponents/form/Text/Text";
import OkDialogLayout from "components/DialogLayout/OkDialogLayout";
import { required, dropdownOptionsValidator } from "components/form/Validators";
import { Errors } from "components/DataBaseComponent/Errors";
import { ActionTemplateParameterResource } from "client/resources/actionTemplateParameterResource";
import { BoundSensitive } from "components/form/Sensitive/Sensitive";
import { PropertyValueResource, SensitiveValue } from "client/resources/propertyValueResource";
import Note from "primitiveComponents/form/Note/Note";
import { ControlType } from "client/resources";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout/Callout";
import MarkdownEditor from "components/form/MarkdownEditor/MarkdownEditor";
import { PermissionCheckProps } from "components/PermissionCheck/PermissionCheck";
import ControlTypeSelector from "../ControlType/ControlTypeSelector";

interface ActionTemplateParameterEditorDialogState extends OptionalFormBaseComponentState<ActionTemplateParameterResource> {
    model: ActionTemplateParameterResource;
    originalName?: string | null;
}

interface ActionTemplateParameterEditorDialogProps {
    parameter?: ActionTemplateParameterResource;
    editPermission: PermissionCheckProps;
    name: "template" | "parameter";
    excludedControlTypes?: ControlType[];
    onOk(parameter: ActionTemplateParameterResource): void;
}

const handleControlChange = (controlType: ControlType) => {
    return (state: ActionTemplateParameterEditorDialogState): ActionTemplateParameterEditorDialogState => {
        return {
            model: {
                ...state!.model,
                DisplaySettings: {
                    ...state!.model!.DisplaySettings,
                    ["Octopus.ControlType"]: controlType,
                },
            },
        };
    };
};

export default class ActionTemplateParameterEditorDialog extends FormBaseComponent<ActionTemplateParameterEditorDialogProps, ActionTemplateParameterEditorDialogState, ActionTemplateParameterResource> {
    public static defaultProps: Partial<ActionTemplateParameterEditorDialogProps> = {
        excludedControlTypes: [],
    };

    constructor(props: ActionTemplateParameterEditorDialogProps) {
        super(props);

        this.state = {
            model: props.parameter || ({ DisplaySettings: {} } as any),
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            this.setState({
                originalName: this.props.parameter ? this.props.parameter.Name : null,
            });
        });
    }

    componentWillReceiveProps(nextProps: ActionTemplateParameterEditorDialogProps) {
        this.setState({
            originalName: nextProps.parameter ? nextProps.parameter.Name : null,
        });
    }

    render() {
        const errors: Errors | null = null;
        return (
            <OkDialogLayout onOkClick={this.onOk} okButtonPermission={this.props.editPermission} busy={this.state.busy} errors={this.errors} title={this.humanize(this.props.name)} okButtonLabel={this.props.parameter ? "Update" : "Add"}>
                <div>
                    {this.showRenameWarning() && (
                        <Callout type={CalloutType.Warning} title={"Potential data loss"}>
                            Parameter name is used as a key so if you rename it you will lose data associated with it that is stored in existing steps.
                        </Callout>
                    )}

                    <DebounceText value={this.state.model.Name} onChange={(Name) => this.setModelState({ Name })} autoFocus label="Variable name" validate={required("Please enter a variable name")} />
                    <Note>
                        The name of the variable set by the {this.props.name}. The name can contain letters, digits, dashes and periods. Example: <code>ServerName</code>.
                    </Note>

                    <DebounceText value={this.state.model.Label} onChange={(Label) => this.setModelState({ Label })} label="Label" />
                    <Note>
                        The label shown beside the {this.props.name} when presented in the deployment process. Example: <em>Server name</em>.
                    </Note>

                    <MarkdownEditor value={this.state.model.HelpText} onChange={(HelpText) => this.setModelState({ HelpText })} label="Help text" />
                    <Note>The help presented alongside the {this.props.name} input.</Note>

                    <ControlTypeSelector
                        excludedControlTypeOptions={this.props.excludedControlTypes}
                        controlType={this.state.model.DisplaySettings["Octopus.ControlType"]}
                        selectOptions={this.state.model.DisplaySettings["Octopus.SelectOptions"]}
                        onControlTypeChange={(x) => this.setState(handleControlChange(x))}
                        onSelectOptionsChange={(x) =>
                            this.setState((state) => ({
                                model: {
                                    ...state!.model,
                                    DisplaySettings: {
                                        ...state!.model.DisplaySettings,
                                        ["Octopus.SelectOptions"]: x,
                                    },
                                },
                            }))
                        }
                    />

                    {this.state.model.DisplaySettings["Octopus.ControlType"] !== ControlType.Sensitive && this.state.model.DisplaySettings["Octopus.ControlType"] !== ControlType.Package && (
                        <DebounceText
                            multiline={this.state.model.DisplaySettings["Octopus.ControlType"] === ControlType.MultiLineText}
                            label="Default value"
                            value={this.state.model.DefaultValue as string}
                            onChange={(DefaultValue) => this.setModelState({ DefaultValue })}
                        />
                    )}

                    {this.state.model.DisplaySettings["Octopus.ControlType"] === ControlType.Sensitive && <BoundSensitive onChange={this.handleSensitiveDefaultChanged} value={this.state.model.DefaultValue!} label="Default value" resetValue="" />}

                    {this.state.model.DisplaySettings["Octopus.ControlType"] !== ControlType.Package && <Note>A default value for the {this.props.name}, if applicable. This can be a hard-coded value or a variable reference.</Note>}
                </div>
            </OkDialogLayout>
        );
    }

    private humanize(name: string) {
        return name[0].toUpperCase() + name.slice(1);
    }

    private handleSensitiveDefaultChanged = (defaultValue: PropertyValueResource) => {
        const value = typeof defaultValue === "string" ? defaultValue : this.processSensitiveValue(defaultValue!);
        this.setModelState({ DefaultValue: value });
    };

    private showRenameWarning() {
        return this.state.originalName && this.state.originalName !== this.state.model.Name;
    }

    private onOk = () => {
        if (!this.state.model.Name) {
            this.setValidationErrors(`Please provide a variable name for the ${this.props.name}.`);
            return false;
        }
        if (!this.state.model.DisplaySettings["Octopus.ControlType"]) {
            this.setValidationErrors(`Please provide a control type for the ${this.props.name}.`);
            return false;
        }
        const selectOptionsError = dropdownOptionsValidator()(this.state.model.DisplaySettings["Octopus.SelectOptions"]!);
        if (this.state.model.DisplaySettings["Octopus.ControlType"] === ControlType.Select && selectOptionsError !== "") {
            this.setValidationErrors(`Drop down options are not formatted correctly. ${selectOptionsError}.`);
            return false;
        }

        this.props.onOk(this.state.model);
        return true;
    };

    private processSensitiveValue(defaultValue: SensitiveValue) {
        if (defaultValue == null || (!defaultValue.HasValue && (defaultValue.NewValue === null || defaultValue.NewValue === ""))) {
            return null;
        } else {
            if (defaultValue.HasValue === undefined) {
                defaultValue.HasValue = true;
            }
        }

        return defaultValue;
    }
}
