/* eslint-disable @typescript-eslint/init-declarations,@typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { repository } from "clientInstance";
import { AzureWebSite } from "client/resources";
import Note from "primitiveComponents/form/Note/Note";
import ParseHelper from "utils/ParseHelper/ParseHelper";
import { Select } from "components/form";
import BusyRefreshContainer from "components/BusyRefreshContainer";
import { DoBusyTask } from "components/DataBaseComponent/DataBaseComponent";
import { Icon, default as IconButton } from "components/IconButton/IconButton";
import InputWithActions from "components/InputWithActions";

interface AzureWebAppAndResourceGroupSelectorProps {
    webAppName: string | undefined;
    accountId: string;
    webAppNameError: string;
    doBusyTask: DoBusyTask;
    onResourceGroupChanged(value: string | undefined): void;
    onWebAppNameChanged(value: string | undefined): void;
}

interface AzureWebAppAndResourceGroupSelectorState {
    reset: {
        name: string | undefined;
    };
    sites: AzureWebSite[];
    siteItems: Array<{ value: string; text: string }>;
    selectedSiteIndex: string | undefined;
    busy: boolean; //TODO: move busy back out into props and use a HOC/Render prop component to manage this state
}

const toggleBusy = (value?: boolean) => (prev: AzureWebAppAndResourceGroupSelectorState, props: AzureWebAppAndResourceGroupSelectorProps) => ({ ...prev, busy: value ? value : !prev.busy });

class AzureWebAppAndResourceGroupSelector extends BaseComponent<AzureWebAppAndResourceGroupSelectorProps, AzureWebAppAndResourceGroupSelectorState> {
    constructor(props: AzureWebAppAndResourceGroupSelectorProps) {
        super(props);
        this.state = {
            reset: {
                name: undefined,
            },
            sites: [],
            siteItems: [],
            selectedSiteIndex: undefined,
            busy: false,
        };
    }

    async componentDidMount() {
        await this.getWebSites(this.props.accountId);
    }

    async componentWillReceiveProps(newprops: AzureWebAppAndResourceGroupSelectorProps) {
        if (newprops.accountId !== this.props.accountId) {
            await this.getWebSites(newprops.accountId);
        }
    }

    render() {
        return (
            <div>
                <BusyRefreshContainer busy={this.state.busy}>
                    <InputWithActions
                        input={<Select value={this.state.selectedSiteIndex} onChange={this.handleSelectedSiteChanged} items={this.state.siteItems} label="Web app" />}
                        actions={<IconButton disabled={this.state.busy} onClick={() => this.getWebSites(this.props.accountId)} toolTipContent="Refresh" icon={Icon.Refresh} />}
                    />
                </BusyRefreshContainer>
                <Note>The name of your Azure Web App.</Note>
            </div>
        );
    }

    private handleSelectedSiteChanged = (value: string | undefined) => {
        const index = ParseHelper.safeParseInt(value as string);
        const selectedSite = index !== null ? this.state.sites[index] : null;
        this.props.onResourceGroupChanged(selectedSite ? selectedSite.ResourceGroup : undefined);
        this.props.onWebAppNameChanged(selectedSite ? selectedSite.Name : undefined);
        this.setState({ selectedSiteIndex: index !== null ? index.toString() : undefined });
    };

    private async getWebSites(accountId: string) {
        if (!accountId) {
            return;
        }

        this.setState(toggleBusy(true));

        try {
            await this.props.doBusyTask(async () => {
                if (!accountId) {
                    this.setState({
                        sites: [],
                        siteItems: [],
                        selectedSiteIndex: undefined,
                    });
                    return;
                }
                const account = await repository.Accounts.get(accountId);
                const sites = await repository.Accounts.getWebSites(account);

                let selectedSiteIndex: string | undefined;
                if (this.props.webAppName) {
                    const selectedSite = sites.find((s) => s.Name === this.props.webAppName);
                    if (selectedSite) {
                        const index = sites.indexOf(selectedSite);
                        selectedSiteIndex = index < 0 ? undefined : index.toString();
                    }
                }

                const siteItems = sites.map((site, index) => {
                    const details = site.ResourceGroup ? site.ResourceGroup + " " + site.Region : site.Region;
                    return {
                        value: index.toString(),
                        text: site.Name + " " + details,
                    };
                });

                this.setState({
                    sites,
                    siteItems,
                    selectedSiteIndex,
                });
            });
        } finally {
            this.setState(toggleBusy(false));
        }
    }
}

export default AzureWebAppAndResourceGroupSelector;
