import { EnvironmentSummaryResource } from "client/resources";
import FilterSearchBox from "components/FilterSearchBox";
import InternalLink from "components/Navigation/InternalLink";
import React, { useState } from "react";
import routeLinks from "routeLinks";
import { withTheme } from "components/Theme";
import { EnvironmentOverviewSummaryLoadedProps, FilterProps, TitleProps } from "../DashboardOverviewProps";
import { EnvironmentsCardContentRows } from "./EnvironmentsCardContentRows";
import ActionButton, { ActionButtonType } from "components/Button";

const styles = require("../style.less");

type EnvironmentsLoadedProps = EnvironmentOverviewSummaryLoadedProps & FilterProps & TitleProps;

const onFilter = (filter: string, resource: EnvironmentSummaryResource) => {
    return !filter || filter.length === 0 || !resource || resource.Environment.Name.toLowerCase().includes(filter.toLowerCase());
};

const pageSize: number = 20;

export const EnvironmentsCardContent = ({ environmentsSummaryResource, title, filterText, setFilterText }: EnvironmentsLoadedProps) => {
    const [numberOfEnvironmentsToRender, setNumberOfEnvironmentsToRender] = useState<number>(pageSize);

    const environmentSummaries = environmentsSummaryResource.EnvironmentSummaries.filter((item: EnvironmentSummaryResource) => onFilter(filterText, item));
    const limitedEnvironmentSummaries = environmentSummaries.slice(0, numberOfEnvironmentsToRender);

    return withTheme((theme) => (
        <div className={styles.card}>
            <div className={styles.cardTitleContainer}>
                <div className={styles.cardTitle}>
                    <InternalLink to={routeLinks.infrastructure.environments.root} color={theme.whiteConstant}>
                        {title} <span className={styles.cardCount}>({environmentSummaries && environmentSummaries.length.toLocaleString()})</span>
                    </InternalLink>
                </div>
            </div>
            <div className={styles.cardContentContainer}>
                {(environmentSummaries.length > 1 || filterText) && (
                    <FilterSearchBox
                        placeholder="Filter..."
                        onChange={(value) => {
                            setNumberOfEnvironmentsToRender(pageSize);
                            return setFilterText(value.toLowerCase());
                        }}
                        inputClassName={styles.dashboardFilterInputClassName}
                        iconColor={theme.secondaryText}
                        iconStyle={{ width: "20px", height: "20px", top: "14px" }}
                    />
                )}
                <EnvironmentsCardContentRows environmentSummaries={limitedEnvironmentSummaries} />
                {numberOfEnvironmentsToRender <= environmentSummaries.length && <ActionButton type={ActionButtonType.Ternary} onClick={() => setNumberOfEnvironmentsToRender(numberOfEnvironmentsToRender + pageSize)} label="Load more" />}
            </div>
        </div>
    ));
};
