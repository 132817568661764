import * as React from "react";
import { enforceNewActionFeatures } from "components/ActionPropertiesEditor/enforceNewActionFeatures";
import { ActionPlugin, ActionScope, AdditionalActions } from "components/Actions/pluginRegistry";
import { withProjectContext, WithProjectContextInjectedProps } from "areas/projects/context";
import { ActionPropertiesEditor } from "components/ActionPropertiesEditor/ActionPropertiesEditor";
import ActionProperties from "client/resources/actionProperties";
import { PackageReference } from "client/resources/packageReference";
import { RunOn } from "areas/projects/components/Process/types";
import { Errors } from "components/DataBaseComponent";
import { IProcessResource } from "client/resources";

interface ProjectActionEditorProps extends WithProjectContextInjectedProps {
    projectId: string;
    plugin: ActionPlugin;
    properties: ActionProperties;
    packages: Array<PackageReference>;
    runOn: RunOn;
    additionalActions: AdditionalActions;
    errors: Errors | undefined; // only used for shouldComponentUpdate
    busy: Promise<unknown> | boolean | undefined;
    expandedByDefault: boolean;
    getFieldError(field: string): string;
    setProperties(properties: Partial<ActionProperties>, initialise?: boolean, callback?: () => void): void;
    setPackages(packages: Array<PackageReference>, initialise?: boolean): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    refreshRunOn(): void;
    getProcessResource(): Readonly<IProcessResource>;
    scope: ActionScope;
    isNew: boolean;
}

class ProjectActionPropertiesEditorInternal extends React.Component<ProjectActionEditorProps, never> {
    componentDidMount() {
        const properties = { ...this.props.properties };

        const newActionFeatures = enforceNewActionFeatures(this.props.plugin, this.props.scope, properties, this.props.isNew, false);
        if (newActionFeatures.length > 0) {
            // We don't assign empty features, or this causes existing steps to trigger the "unsaved changes" dialog.
            properties["Octopus.Action.EnabledFeatures"] = newActionFeatures;
        }

        this.props.setProperties(properties, true);
    }

    render() {
        if (!this.props.plugin.edit) {
            throw new Error(`Plugin ${this.props.plugin.actionType} is missing 'Edit' property`);
        }
        return (
            <ActionPropertiesEditor
                plugin={this.props.plugin}
                projectId={this.props.projectContext.state.model.Id}
                properties={this.props.properties}
                packages={this.props.packages}
                doBusyTask={this.props.doBusyTask}
                busy={this.props.busy}
                runOn={this.props.runOn}
                setProperties={this.props.setProperties}
                setPackages={this.props.setPackages}
                additionalActions={this.props.additionalActions}
                getFieldError={this.props.getFieldError}
                errors={this.props.errors}
                expandedByDefault={this.props.expandedByDefault}
                refreshRunOn={this.props.refreshRunOn}
                getProcessResource={this.props.getProcessResource}
            />
        );
    }
}

export const ProjectActionPropertiesEditor = withProjectContext(ProjectActionPropertiesEditorInternal);
