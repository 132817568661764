/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import * as _ from "lodash";
import pluginRegistry, { ActionEditProps } from "../pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { ActionSummaryProps } from "../actionSummaryProps";
import { ActionExecutionLocation, AzureServiceFabricSecurityMode, GetPrimaryPackageReference, InitialisePrimaryPackageReference, SetPrimaryPackageReference } from "client/resources";
import ExpanderSectionHeading from "components/form/Sections/FormSectionHeading";
import { FeedType } from "client/resources/feedResource";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import CommonSummaryHelper from "utils/CommonSummaryHelper/CommonSummaryHelper";
import PackageDownloadOptions from "components/PackageDownloadOptions/PackageDownloadOptions";
import { VariableLookupText } from "components/form/VariableLookupText";
import Note from "primitiveComponents/form/Note/Note";
import Summary from "components/form/Sections/Summary";
import { BoundStringCheckbox } from "primitiveComponents/form/Checkbox/StringCheckbox";
import { DisplayFeedName } from "../DisplayFeedName";
import Roles from "../Roles";
import { TargetRoles } from "areas/projects/components/Process/types";
import { Callout, CalloutType } from "primitiveComponents/dataDisplay/Callout/Callout";
import DeferredPackageSelector from "components/PackageSelector/DeferredPackageSelector";
import { getChangesToPackageReference } from "../getChangesToPackageReference";
import { PackageSelectionMode } from "client/resources/packageReference";
import { ActionWithFeeds } from "../commonActionHelpers";
import RadioButton, { BoundStringRadioButtonGroup } from "primitiveComponents/form/RadioButton";
import ExternalLink from "../../Navigation/ExternalLink";
import { ProcessFeedLookup, useFeedsFromContext, useRefreshFeedsFromContext } from "areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import { KeyedItemProps } from "../../KeyAccessProvider/types";
import { useKeyedItemAccess } from "../../KeyAccessProvider/KeyedItemAccessProvider";

interface DeployJavaPackageSummaryState {
    feedName: string;
}

class DeployJavaPackageActionSummary extends BaseComponent<ActionSummaryProps, DeployJavaPackageSummaryState> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        return !pkg /* there was a validation bug that allowed saving of steps to not pick a package */ ? (
            <Callout type={CalloutType.Warning} title="Misconfigured step">
                Package was not selected or cannot be found. Please review this step and ensure a valid package is selected.
            </Callout>
        ) : (
            <div>
                {"Deploy a Java package"} <strong> {pkg.PackageId} </strong> {"from"} <DisplayFeedName pkg={pkg} />
                {this.props.targetRolesAsCSV && (
                    <span>
                        {" "}
                        to deployment targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} />
                    </span>
                )}
            </div>
        );
    }
}

interface DeployJavaPackageProperties {
    "Octopus.Action.Package.TransferPath": string;
    "Octopus.Action.JavaArchive.DeployExploded": string;
    "Octopus.Action.Package.UseCustomInstallationDirectory": string;
    "Octopus.Action.Package.CustomInstallationDirectory": string;
    "Octopus.Action.Package.CustomPackageFileName": string;
    "Octopus.Action.Package.JavaArchiveCompression": string;
    "Octopus.Action.Package.CustomInstallationDirectoryShouldBePurgedBeforeDeployment": string;
    "Octopus.Action.Package.CustomInstallationDirectoryPurgeExclusions": string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
type DeployJavaPackageActionEditState = {};

type DeployJavaPackageActionEditProps = ActionEditProps<DeployJavaPackageProperties>;
type Props = DeployJavaPackageActionEditProps & ActionWithFeeds & KeyedItemProps;

class DeployJavaPackageActionEditInternal extends BaseComponent<Props, DeployJavaPackageActionEditState> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {
        this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, this.props.feeds, this.props.itemKey), true);

        await this.props.doBusyTask(async () => {
            const properties: any = {};

            if (this.props.properties["Octopus.Action.Package.CustomInstallationDirectory"]) {
                properties["Octopus.Action.Package.UseCustomInstallationDirectory"] = "True";
            } else if (this.props.properties["Octopus.Action.Package.UseCustomInstallationDirectory"] !== "True" && this.props.properties["Octopus.Action.Package.UseCustomInstallationDirectory"] !== "False") {
                properties["Octopus.Action.Package.UseCustomInstallationDirectory"] = "False";
            }

            if (
                this.props.properties["Octopus.Action.Package.CustomInstallationDirectoryShouldBePurgedBeforeDeployment"] !== "True" &&
                this.props.properties["Octopus.Action.Package.CustomInstallationDirectoryShouldBePurgedBeforeDeployment"] !== "False"
            ) {
                properties["Octopus.Action.Package.CustomInstallationDirectoryShouldBePurgedBeforeDeployment"] = "False";
            }

            if (!this.props.properties["Octopus.Action.JavaArchive.DeployExploded"]) {
                properties["Octopus.Action.JavaArchive.DeployExploded"] = "False";
            }

            if (!this.props.properties["Octopus.Action.Package.JavaArchiveCompression"]) {
                properties["Octopus.Action.Package.JavaArchiveCompression"] = "True";
            }

            this.props.setProperties(properties, true);
        });
    }

    deploymentSummary() {
        const properties = this.props.properties;
        return Summary.summary(
            <span>
                Deploying the
                {properties["Octopus.Action.JavaArchive.DeployExploded"] === "True" && <span>&nbsp;exploded</span>}
                &nbsp;package
                {properties["Octopus.Action.Package.CustomPackageFileName"] && (
                    <span>
                        &nbsp;named <strong>{properties["Octopus.Action.Package.CustomPackageFileName"]}</strong>
                    </span>
                )}
                {properties["Octopus.Action.Package.UseCustomInstallationDirectory"] === "True" && (
                    <span>
                        &nbsp;to <strong>{properties["Octopus.Action.Package.CustomInstallationDirectory"]}</strong>
                    </span>
                )}
                {properties["Octopus.Action.Package.UseCustomInstallationDirectory"] === "False" && <span>&nbsp;to the default location</span>}
                {properties["Octopus.Action.Package.CustomInstallationDirectoryShouldBePurgedBeforeDeployment"] === "True" && properties["Octopus.Action.Package.UseCustomInstallationDirectory"] === "True" && (
                    <span>
                        &nbsp;purging the destination
                        {properties["Octopus.Action.Package.CustomInstallationDirectoryPurgeExclusions"] && <span>&nbsp;with exclusions</span>}
                    </span>
                )}
            </span>
        );
    }

    render() {
        // The package is initialized in componentDidMount, but render gets called before the update is propagated
        if (!this.props.packages || this.props.packages.length === 0) {
            return null;
        }

        const properties = this.props.properties;
        const pkg = GetPrimaryPackageReference(this.props.packages);

        const help = <span> Select the Java archive (.jar, .ear, .war) containing your application. </span>;
        const deploymentErrorKey =
            "Octopus.Action.JavaArchive.DeployExploded|" +
            "Octopus.Action.Package.CustomPackageFileName|" +
            "Octopus.Action.Package.UseCustomInstallationDirectory|" +
            "Octopus.Action.Package.CustomInstallationDirectory|" +
            "Octopus.Action.Package.CustomInstallationDirectoryShouldBePurgedBeforeDeployment|" +
            "Octopus.Action.Package.CustomInstallationDirectoryPurgeExclusions";

        return (
            <div>
                <ExpanderSectionHeading title="Package Details" />
                <ExpandableFormSection
                    errorKey="Octopus.Action.Package.PackageId|Octopus.Action.Package.FeedId"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Package"
                    summary={CommonSummaryHelper.deferredPackageSummary(pkg, this.props.feeds, this.props.itemKey)}
                    help={help}
                >
                    <DeferredPackageSelector
                        packageId={pkg.PackageId}
                        feedIdOrName={pkg.FeedId}
                        onPackageIdChange={(packageId) => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))}
                        onFeedIdChange={(feedId) => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))}
                        packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")}
                        feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")}
                        projectId={this.props.projectId}
                        feeds={this.props.feeds}
                        localNames={this.props.localNames}
                        feedType={[FeedType.Nuget, FeedType.BuiltIn, FeedType.Maven]}
                        refreshFeeds={this.loadFeeds}
                        parameters={this.props.parameters}
                        packageSelectionMode={pkg.Properties["SelectionMode"]}
                        packageSelectionModeError={this.props.getFieldError("SelectionMode")}
                        onPackageSelectionModeChange={(value) => this.props.setPackages(SetPrimaryPackageReference(getChangesToPackageReference(value), this.props.packages))}
                        packageParameterName={pkg.Properties["PackageParameterName"]}
                        packageParameterError={this.props.getFieldError("PackageParameterName")}
                        onPackageParameterChange={(packageParameter) => this.props.setPackages(SetPrimaryPackageReference({ Properties: { ...pkg.Properties, PackageParameterName: packageParameter } }, this.props.packages))}
                    />
                    {pkg.Properties["SelectionMode"] === PackageSelectionMode.Immediate && (
                        <ProcessFeedLookup feedNameOrId={pkg.FeedId}>
                            {(feed) => (
                                <PackageDownloadOptions
                                    packageAcquisitionLocation={pkg.AcquisitionLocation}
                                    onPackageAcquisitionLocationChanged={(acquisitionLocation) => this.props.setPackages(SetPrimaryPackageReference({ AcquisitionLocation: acquisitionLocation }, this.props.packages))}
                                    feed={feed}
                                    projectId={this.props.projectId}
                                    localNames={this.props.localNames}
                                />
                            )}
                        </ProcessFeedLookup>
                    )}
                </ExpandableFormSection>
                <ExpandableFormSection errorKey={deploymentErrorKey} isExpandedByDefault={this.props.expandedByDefault} title="Deployment" summary={this.deploymentSummary()} help={<span>Choose how and where the package will be deployed.</span>}>
                    <BoundStringCheckbox
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        resetValue={"False"}
                        value={properties["Octopus.Action.JavaArchive.DeployExploded"]}
                        onChange={(x) => this.props.setProperties({ ["Octopus.Action.JavaArchive.DeployExploded"]: x })}
                        label="Deploy the extracted package"
                        note={<span> If selected, the package will be deployed extracted.</span>}
                    />
                    <Note>
                        <p>
                            Note: The package is always extracted as part of the deployment process, to allow features such as substituting variables in files. By default the package is re-created before deploying to the destination. If the option
                            above is selected it remains extracted.
                        </p>
                    </Note>
                    {properties["Octopus.Action.JavaArchive.DeployExploded"] === "False" && (
                        <div>
                            <VariableLookupText
                                localNames={this.props.localNames}
                                value={properties["Octopus.Action.Package.CustomPackageFileName"]}
                                onChange={(x) => this.props.setProperties({ ["Octopus.Action.Package.CustomPackageFileName"]: x })}
                                label="Deployed package file name"
                            />
                            <Note>The optional filename of the copied package. If left blank, the original filename from the feed will be retained.</Note>
                            <BoundStringRadioButtonGroup
                                resetValue={AzureServiceFabricSecurityMode.Unsecure}
                                value={properties["Octopus.Action.Package.JavaArchiveCompression"]}
                                onChange={(x) => {
                                    this.props.setProperties({ ["Octopus.Action.Package.JavaArchiveCompression"]: x });
                                }}
                            >
                                <RadioButton value={"True"} label="Compress the repacked archive" isDefault />
                                <Note>
                                    The <code>jar</code> command will compress the items in the repackaged archive.
                                </Note>
                                <RadioButton value={"False"} label="Do not compress the repacked archive" />
                                <Note>
                                    The <code>jar</code> command will not compress the items in the repackaged archive by passing the <code>-0</code> argument. This is useful for some packages, like Spring boot archives, that expect library files to
                                    be uncompressed in the resulting JAR file. <ExternalLink href={"SpringBootJarRestrictions"}>More information</ExternalLink>.
                                </Note>
                            </BoundStringRadioButtonGroup>
                        </div>
                    )}
                    <BoundStringCheckbox
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        resetValue={"False"}
                        value={properties["Octopus.Action.Package.UseCustomInstallationDirectory"]}
                        onChange={(x) => {
                            this.props.setProperties({ ["Octopus.Action.Package.UseCustomInstallationDirectory"]: x });
                            if (properties["Octopus.Action.Package.UseCustomInstallationDirectory"] !== "True") {
                                this.props.setProperties({ ["Octopus.Action.Package.CustomInstallationDirectory"]: "" });
                            }
                        }}
                        label="Use custom deployment directory"
                        note={<span>By default the package will be deployed to the target's application directory. This options allows setting a custom deployment directory.</span>}
                    />
                    {properties["Octopus.Action.Package.UseCustomInstallationDirectory"] === "True" && (
                        <div>
                            <VariableLookupText
                                localNames={this.props.localNames}
                                value={properties["Octopus.Action.Package.CustomInstallationDirectory"]}
                                onChange={(x) => this.props.setProperties({ ["Octopus.Action.Package.CustomInstallationDirectory"]: x })}
                                label="Deploy Directory"
                            />
                            <Note>The installed package will be copied to this location on the remote machine.</Note>
                            <BoundStringCheckbox
                                variableLookup={{
                                    localNames: this.props.localNames,
                                }}
                                resetValue={"False"}
                                value={properties["Octopus.Action.Package.CustomInstallationDirectoryShouldBePurgedBeforeDeployment"]}
                                onChange={(x) => this.props.setProperties({ ["Octopus.Action.Package.CustomInstallationDirectoryShouldBePurgedBeforeDeployment"]: x })}
                                label="Purge"
                            />
                            <Note>Before the installed package is copied, all files in this location will be removed.</Note>
                            {properties["Octopus.Action.Package.CustomInstallationDirectoryShouldBePurgedBeforeDeployment"] === "True" && (
                                <div>
                                    <VariableLookupText
                                        localNames={this.props.localNames}
                                        value={properties["Octopus.Action.Package.CustomInstallationDirectoryPurgeExclusions"]}
                                        onChange={(x) => this.props.setProperties({ ["Octopus.Action.Package.CustomInstallationDirectoryPurgeExclusions"]: x })}
                                        label="Exclude from purge"
                                        multiline={true}
                                        rows={5}
                                    />
                                    <Note>
                                        A newline-separated list of file or directory names, relative to the installation directory, to leave when it is purged. To exclude a whole directory, specify it by name without a wildcard. Extended wildcard
                                        syntax is supported. E.g., <em>appsettings.config</em>, <em>Config</em>,<em>Config\*.config</em>, <em>**\*.config</em>
                                    </Note>
                                </div>
                            )}
                        </div>
                    )}
                </ExpandableFormSection>
            </div>
        );
    }

    private loadFeeds = async () => {
        await this.props.refreshFeeds();
    };
}

export function DeployJavaPackageActionEdit(props: React.PropsWithChildren<DeployJavaPackageActionEditProps>) {
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    const itemKey = useKeyedItemAccess();

    return <DeployJavaPackageActionEditInternal {...props} feeds={feeds} refreshFeeds={refreshFeeds} itemKey={itemKey} />;
}

pluginRegistry.registerActionForAllScopes({
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    actionType: "Octopus.JavaArchive",
    summary: (properties, targetRolesAsCSV, packages) => <DeployJavaPackageActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} packages={packages} />,
    edit: DeployJavaPackageActionEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => true,
    features: {
        optional: ["Octopus.Features.CustomScripts", "Octopus.Features.JsonConfigurationVariables", "Octopus.Features.SubstituteInFiles", "Octopus.Features.SelectPowerShellEditionForWindows"],
        initial: ["Octopus.Features.SubstituteInFiles"],
    },
});
